/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Document on Azure Functions
 * This is the OpenAPI Document on Azure Functions
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'color'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    'alphabeticalSort'?: boolean;
    /**
     * 
     * @type {Array<IdTextSort>}
     * @memberof Category
     */
    'products'?: Array<IdTextSort>;
}
/**
 * 
 * @export
 * @interface CustomerTab
 */
export interface CustomerTab {
    /**
     * 
     * @type {string}
     * @memberof CustomerTab
     */
    'guid': string;
    /**
     * 
     * @type {IdText}
     * @memberof CustomerTab
     */
    'table'?: IdText;
    /**
     * 
     * @type {number}
     * @memberof CustomerTab
     */
    'pickupNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerTab
     */
    'openTabNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerTab
     */
    'paid'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTab
     */
    'closed'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTab
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTab
     */
    'deleted'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerTab
     */
    'alert': boolean;
    /**
     * 
     * @type {Array<TabProduct>}
     * @memberof CustomerTab
     */
    'products'?: Array<TabProduct>;
}
/**
 * 
 * @export
 * @interface IdText
 */
export interface IdText {
    /**
     * 
     * @type {number}
     * @memberof IdText
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof IdText
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface IdTextSort
 */
export interface IdTextSort {
    /**
     * 
     * @type {number}
     * @memberof IdTextSort
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof IdTextSort
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof IdTextSort
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface LogRequest
 */
export interface LogRequest {
    /**
     * 
     * @type {number}
     * @memberof LogRequest
     */
    'storeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LogRequest
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'plu'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'price'?: number;
    /**
     * 
     * @type {Array<IdTextSort>}
     * @memberof Product
     */
    'categories'?: Array<IdTextSort>;
    /**
     * 
     * @type {Array<ProductOptions>}
     * @memberof Product
     */
    'productOptions'?: Array<ProductOptions>;
    /**
     * 
     * @type {Array<IdText>}
     * @memberof Product
     */
    'productGroups'?: Array<IdText>;
    /**
     * 
     * @type {IdText}
     * @memberof Product
     */
    'station'?: IdText;
}
/**
 * 
 * @export
 * @interface ProductGroup
 */
export interface ProductGroup {
    /**
     * 
     * @type {number}
     * @memberof ProductGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductGroup
     */
    'text'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductGroup
     */
    'price'?: number;
    /**
     * 
     * @type {Array<Product>}
     * @memberof ProductGroup
     */
    'products'?: Array<Product>;
    /**
     * 
     * @type {Array<IdText>}
     * @memberof ProductGroup
     */
    'categories'?: Array<IdText>;
}
/**
 * 
 * @export
 * @interface ProductOptions
 */
export interface ProductOptions {
    /**
     * 
     * @type {number}
     * @memberof ProductOptions
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductOptions
     */
    'text'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductOptions
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface ProductionProduct
 */
export interface ProductionProduct {
    /**
     * 
     * @type {number}
     * @memberof ProductionProduct
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductionProduct
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductionProduct
     */
    'plu'?: string;
}
/**
 * 
 * @export
 * @interface ProductionStation
 */
export interface ProductionStation {
    /**
     * 
     * @type {number}
     * @memberof ProductionStation
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductionStation
     */
    'text'?: string;
    /**
     * 
     * @type {Array<ProductionProduct>}
     * @memberof ProductionStation
     */
    'products'?: Array<ProductionProduct>;
    /**
     * 
     * @type {IdText}
     * @memberof ProductionStation
     */
    'station'?: IdText;
}
/**
 * 
 * @export
 * @interface ProductionStationOrder
 */
export interface ProductionStationOrder {
    /**
     * 
     * @type {string}
     * @memberof ProductionStationOrder
     */
    'guid': string;
    /**
     * 
     * @type {number}
     * @memberof ProductionStationOrder
     */
    'productionStationId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionStationOrder
     */
    'targetStationId': number;
    /**
     * 
     * @type {number}
     * @memberof ProductionStationOrder
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof ProductionStationOrder
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductionStationOrder
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionStationOrder
     */
    'sent'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductionStationOrder
     */
    'produced'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductionStationOrder
     */
    'pickedUp'?: string;
}
/**
 * 
 * @export
 * @interface Settings
 */
export interface Settings {
    /**
     * 
     * @type {boolean}
     * @memberof Settings
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface SignalRConnectionInfo
 */
export interface SignalRConnectionInfo {
    /**
     * 
     * @type {string}
     * @memberof SignalRConnectionInfo
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignalRConnectionInfo
     */
    'accessToken'?: string;
}
/**
 * 
 * @export
 * @interface Station
 */
export interface Station {
    /**
     * 
     * @type {number}
     * @memberof Station
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Station
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface TabProduct
 */
export interface TabProduct {
    /**
     * 
     * @type {string}
     * @memberof TabProduct
     */
    'guid': string;
    /**
     * 
     * @type {number}
     * @memberof TabProduct
     */
    'productId': number;
    /**
     * 
     * @type {string}
     * @memberof TabProduct
     */
    'customerTabGuid': string;
    /**
     * 
     * @type {string}
     * @memberof TabProduct
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof TabProduct
     */
    'plu'?: string;
    /**
     * 
     * @type {number}
     * @memberof TabProduct
     */
    'stationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TabProduct
     */
    'details'?: string;
    /**
     * 
     * @type {number}
     * @memberof TabProduct
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof TabProduct
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof TabProduct
     */
    'bonged'?: string;
    /**
     * 
     * @type {string}
     * @memberof TabProduct
     */
    'delivered'?: string;
    /**
     * 
     * @type {string}
     * @memberof TabProduct
     */
    'done'?: string;
    /**
     * 
     * @type {string}
     * @memberof TabProduct
     */
    'prioritized'?: string;
    /**
     * 
     * @type {string}
     * @memberof TabProduct
     */
    'created': string;
    /**
     * 
     * @type {boolean}
     * @memberof TabProduct
     */
    'alert': boolean;
    /**
     * 
     * @type {IdText}
     * @memberof TabProduct
     */
    'table'?: IdText;
    /**
     * 
     * @type {number}
     * @memberof TabProduct
     */
    'pickupNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof TabProduct
     */
    'openTabNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof TabProduct
     */
    'closedForStation'?: string;
    /**
     * 
     * @type {string}
     * @memberof TabProduct
     */
    'paid'?: string;
    /**
     * 
     * @type {string}
     * @memberof TabProduct
     */
    'closed'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TabProduct
     */
    'supplement': boolean;
    /**
     * 
     * @type {string}
     * @memberof TabProduct
     */
    'deleted'?: string;
}
/**
 * 
 * @export
 * @interface Table
 */
export interface Table {
    /**
     * 
     * @type {number}
     * @memberof Table
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof Table
     */
    'column': number;
    /**
     * 
     * @type {number}
     * @memberof Table
     */
    'row': number;
    /**
     * 
     * @type {number}
     * @memberof Table
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof Table
     */
    'height': number;
    /**
     * 
     * @type {Array<TableCustomerTab>}
     * @memberof Table
     */
    'customerTabs'?: Array<TableCustomerTab>;
}
/**
 * 
 * @export
 * @interface TableCustomerTab
 */
export interface TableCustomerTab {
    /**
     * 
     * @type {string}
     * @memberof TableCustomerTab
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof TableCustomerTab
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof TableCustomerTab
     */
    'created': string;
}

/**
 * AppApi - axios parameter creator
 * @export
 */
export const AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} storeId Store Id
         * @param {string} customerTabGuid The guid of the tab product to remove
         * @param {string} clientTimestamp The time on the client when save was initiated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppCustomerTab: async (storeId: number, customerTabGuid: string, clientTimestamp: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('deleteAppCustomerTab', 'storeId', storeId)
            // verify required parameter 'customerTabGuid' is not null or undefined
            assertParamExists('deleteAppCustomerTab', 'customerTabGuid', customerTabGuid)
            // verify required parameter 'clientTimestamp' is not null or undefined
            assertParamExists('deleteAppCustomerTab', 'clientTimestamp', clientTimestamp)
            const localVarPath = `/delete-app-customer-tab`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (customerTabGuid !== undefined) {
                localVarQueryParameter['customerTabGuid'] = customerTabGuid;
            }

            if (clientTimestamp !== undefined) {
                localVarQueryParameter['clientTimestamp'] = (clientTimestamp as any instanceof Date) ?
                    (clientTimestamp as any).toISOString() :
                    clientTimestamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId Store Id
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppProductionStationOrders: async (storeId: number, body?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('deleteAppProductionStationOrders', 'storeId', storeId)
            const localVarPath = `/delete-app-production-station-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'customerTab';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tabProductGuid The guid of the tab product to remove
         * @param {string} clientTimestamp The time on the client when save was initiated
         * @param {number} storeId Store Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppTabProduct: async (tabProductGuid: string, clientTimestamp: string, storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tabProductGuid' is not null or undefined
            assertParamExists('deleteAppTabProduct', 'tabProductGuid', tabProductGuid)
            // verify required parameter 'clientTimestamp' is not null or undefined
            assertParamExists('deleteAppTabProduct', 'clientTimestamp', clientTimestamp)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('deleteAppTabProduct', 'storeId', storeId)
            const localVarPath = `/delete-app-tab-product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tabProductGuid !== undefined) {
                localVarQueryParameter['tabProductGuid'] = tabProductGuid;
            }

            if (clientTimestamp !== undefined) {
                localVarQueryParameter['clientTimestamp'] = (clientTimestamp as any instanceof Date) ?
                    (clientTimestamp as any).toISOString() :
                    clientTimestamp;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId The store to fetch categories for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCategories: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getAppCategories', 'storeId', storeId)
            const localVarPath = `/get-app-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customertabId The customertab to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCustomertab: async (customertabId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customertabId' is not null or undefined
            assertParamExists('getAppCustomertab', 'customertabId', customertabId)
            const localVarPath = `/get-app-customertab`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customertabId !== undefined) {
                localVarQueryParameter['customertabId'] = customertabId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId The store to fetch products for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCustomertabs: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getAppCustomertabs', 'storeId', storeId)
            const localVarPath = `/get-app-customertabs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId The store to fetch tabs for
         * @param {string} from The period to fetch from
         * @param {string} to The period to fetch to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCustomertabsHistory: async (storeId: number, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getAppCustomertabsHistory', 'storeId', storeId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getAppCustomertabsHistory', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getAppCustomertabsHistory', 'to', to)
            const localVarPath = `/get-app-customertabs-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId The store to fetch production stations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppProductionStationOrders: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getAppProductionStationOrders', 'storeId', storeId)
            const localVarPath = `/get-app-production-station-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId The store to fetch production stations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppProductionStations: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getAppProductionStations', 'storeId', storeId)
            const localVarPath = `/get-app-production-stations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId The store to fetch products for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppProducts: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getAppProducts', 'storeId', storeId)
            const localVarPath = `/get-app-products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId The store to fetch stations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppStations: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getAppStations', 'storeId', storeId)
            const localVarPath = `/get-app-stations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId The store to fetch tab products for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppTabProducts: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getAppTabProducts', 'storeId', storeId)
            const localVarPath = `/get-app-tab-products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId The store to fetch products for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppTables: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getAppTables', 'storeId', storeId)
            const localVarPath = `/get-app-tables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignalrinfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/signalr/negotiate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeid Store id)
         * @param {string} userid Guid of the session (user)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joingroup: async (storeid: number, userid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeid' is not null or undefined
            assertParamExists('joingroup', 'storeid', storeid)
            // verify required parameter 'userid' is not null or undefined
            assertParamExists('joingroup', 'userid', userid)
            const localVarPath = `/signalr/joingroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeid !== undefined) {
                localVarQueryParameter['storeid'] = storeid;
            }

            if (userid !== undefined) {
                localVarQueryParameter['userid'] = userid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId The store to fetch products for
         * @param {string} clientTimestamp The time on the client when save was initiated
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppCombineTabs: async (storeId: number, clientTimestamp: string, body?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('postAppCombineTabs', 'storeId', storeId)
            // verify required parameter 'clientTimestamp' is not null or undefined
            assertParamExists('postAppCombineTabs', 'clientTimestamp', clientTimestamp)
            const localVarPath = `/post-app-combine-tabs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (clientTimestamp !== undefined) {
                localVarQueryParameter['clientTimestamp'] = (clientTimestamp as any instanceof Date) ?
                    (clientTimestamp as any).toISOString() :
                    clientTimestamp;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientTimestamp The time on the client when save was initiated
         * @param {number} storeId Store Id
         * @param {CustomerTab} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppCustomerTab: async (clientTimestamp: string, storeId: number, body?: CustomerTab, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientTimestamp' is not null or undefined
            assertParamExists('postAppCustomerTab', 'clientTimestamp', clientTimestamp)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('postAppCustomerTab', 'storeId', storeId)
            const localVarPath = `/post-app-customer-tab`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientTimestamp !== undefined) {
                localVarQueryParameter['clientTimestamp'] = (clientTimestamp as any instanceof Date) ?
                    (clientTimestamp as any).toISOString() :
                    clientTimestamp;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LogRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppLog: async (body: LogRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postAppLog', 'body', body)
            const localVarPath = `/post-app-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId Store Id
         * @param {Array<ProductionStationOrder>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppProductionStationOrders: async (storeId: number, body?: Array<ProductionStationOrder>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('postAppProductionStationOrders', 'storeId', storeId)
            const localVarPath = `/post-app-production-station-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId The store to fetch products for
         * @param {string} clientTimestamp The time on the client when save was initiated
         * @param {Array<TabProduct>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppTabProducts: async (storeId: number, clientTimestamp: string, body?: Array<TabProduct>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('postAppTabProducts', 'storeId', storeId)
            // verify required parameter 'clientTimestamp' is not null or undefined
            assertParamExists('postAppTabProducts', 'clientTimestamp', clientTimestamp)
            const localVarPath = `/post-app-tab-products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (clientTimestamp !== undefined) {
                localVarQueryParameter['clientTimestamp'] = (clientTimestamp as any instanceof Date) ?
                    (clientTimestamp as any).toISOString() :
                    clientTimestamp;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} storeId Store Id
         * @param {string} customerTabGuid The guid of the tab product to remove
         * @param {string} clientTimestamp The time on the client when save was initiated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAppCustomerTab(storeId: number, customerTabGuid: string, clientTimestamp: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAppCustomerTab(storeId, customerTabGuid, clientTimestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId Store Id
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAppProductionStationOrders(storeId: number, body?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAppProductionStationOrders(storeId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tabProductGuid The guid of the tab product to remove
         * @param {string} clientTimestamp The time on the client when save was initiated
         * @param {number} storeId Store Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAppTabProduct(tabProductGuid: string, clientTimestamp: string, storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAppTabProduct(tabProductGuid, clientTimestamp, storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId The store to fetch categories for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppCategories(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppCategories(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customertabId The customertab to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppCustomertab(customertabId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerTab>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppCustomertab(customertabId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId The store to fetch products for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppCustomertabs(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerTab>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppCustomertabs(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId The store to fetch tabs for
         * @param {string} from The period to fetch from
         * @param {string} to The period to fetch to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppCustomertabsHistory(storeId: number, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerTab>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppCustomertabsHistory(storeId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId The store to fetch production stations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppProductionStationOrders(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductionStationOrder>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppProductionStationOrders(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId The store to fetch production stations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppProductionStations(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductionStation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppProductionStations(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId The store to fetch products for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppProducts(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppProducts(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId The store to fetch stations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppStations(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdText>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppStations(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId The store to fetch tab products for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppTabProducts(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TabProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppTabProducts(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId The store to fetch products for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppTables(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Table>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppTables(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignalrinfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignalRConnectionInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignalrinfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeid Store id)
         * @param {string} userid Guid of the session (user)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joingroup(storeid: number, userid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joingroup(storeid, userid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId The store to fetch products for
         * @param {string} clientTimestamp The time on the client when save was initiated
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAppCombineTabs(storeId: number, clientTimestamp: string, body?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAppCombineTabs(storeId, clientTimestamp, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientTimestamp The time on the client when save was initiated
         * @param {number} storeId Store Id
         * @param {CustomerTab} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAppCustomerTab(clientTimestamp: string, storeId: number, body?: CustomerTab, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAppCustomerTab(clientTimestamp, storeId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LogRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAppLog(body: LogRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAppLog(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId Store Id
         * @param {Array<ProductionStationOrder>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAppProductionStationOrders(storeId: number, body?: Array<ProductionStationOrder>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAppProductionStationOrders(storeId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId The store to fetch products for
         * @param {string} clientTimestamp The time on the client when save was initiated
         * @param {Array<TabProduct>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAppTabProducts(storeId: number, clientTimestamp: string, body?: Array<TabProduct>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAppTabProducts(storeId, clientTimestamp, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppApiFp(configuration)
    return {
        /**
         * 
         * @param {number} storeId Store Id
         * @param {string} customerTabGuid The guid of the tab product to remove
         * @param {string} clientTimestamp The time on the client when save was initiated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppCustomerTab(storeId: number, customerTabGuid: string, clientTimestamp: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAppCustomerTab(storeId, customerTabGuid, clientTimestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId Store Id
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppProductionStationOrders(storeId: number, body?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAppProductionStationOrders(storeId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tabProductGuid The guid of the tab product to remove
         * @param {string} clientTimestamp The time on the client when save was initiated
         * @param {number} storeId Store Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppTabProduct(tabProductGuid: string, clientTimestamp: string, storeId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAppTabProduct(tabProductGuid, clientTimestamp, storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId The store to fetch categories for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCategories(storeId: number, options?: any): AxiosPromise<Array<Category>> {
            return localVarFp.getAppCategories(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customertabId The customertab to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCustomertab(customertabId: string, options?: any): AxiosPromise<CustomerTab> {
            return localVarFp.getAppCustomertab(customertabId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId The store to fetch products for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCustomertabs(storeId: number, options?: any): AxiosPromise<Array<CustomerTab>> {
            return localVarFp.getAppCustomertabs(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId The store to fetch tabs for
         * @param {string} from The period to fetch from
         * @param {string} to The period to fetch to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppCustomertabsHistory(storeId: number, from: string, to: string, options?: any): AxiosPromise<Array<CustomerTab>> {
            return localVarFp.getAppCustomertabsHistory(storeId, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId The store to fetch production stations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppProductionStationOrders(storeId: number, options?: any): AxiosPromise<Array<ProductionStationOrder>> {
            return localVarFp.getAppProductionStationOrders(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId The store to fetch production stations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppProductionStations(storeId: number, options?: any): AxiosPromise<Array<ProductionStation>> {
            return localVarFp.getAppProductionStations(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId The store to fetch products for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppProducts(storeId: number, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.getAppProducts(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId The store to fetch stations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppStations(storeId: number, options?: any): AxiosPromise<Array<IdText>> {
            return localVarFp.getAppStations(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId The store to fetch tab products for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppTabProducts(storeId: number, options?: any): AxiosPromise<Array<TabProduct>> {
            return localVarFp.getAppTabProducts(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId The store to fetch products for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppTables(storeId: number, options?: any): AxiosPromise<Array<Table>> {
            return localVarFp.getAppTables(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignalrinfo(options?: any): AxiosPromise<SignalRConnectionInfo> {
            return localVarFp.getSignalrinfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeid Store id)
         * @param {string} userid Guid of the session (user)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joingroup(storeid: number, userid: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.joingroup(storeid, userid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId The store to fetch products for
         * @param {string} clientTimestamp The time on the client when save was initiated
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppCombineTabs(storeId: number, clientTimestamp: string, body?: Array<string>, options?: any): AxiosPromise<boolean> {
            return localVarFp.postAppCombineTabs(storeId, clientTimestamp, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientTimestamp The time on the client when save was initiated
         * @param {number} storeId Store Id
         * @param {CustomerTab} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppCustomerTab(clientTimestamp: string, storeId: number, body?: CustomerTab, options?: any): AxiosPromise<void> {
            return localVarFp.postAppCustomerTab(clientTimestamp, storeId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LogRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppLog(body: LogRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.postAppLog(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId Store Id
         * @param {Array<ProductionStationOrder>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppProductionStationOrders(storeId: number, body?: Array<ProductionStationOrder>, options?: any): AxiosPromise<void> {
            return localVarFp.postAppProductionStationOrders(storeId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId The store to fetch products for
         * @param {string} clientTimestamp The time on the client when save was initiated
         * @param {Array<TabProduct>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppTabProducts(storeId: number, clientTimestamp: string, body?: Array<TabProduct>, options?: any): AxiosPromise<boolean> {
            return localVarFp.postAppTabProducts(storeId, clientTimestamp, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI {
    /**
     * 
     * @param {number} storeId Store Id
     * @param {string} customerTabGuid The guid of the tab product to remove
     * @param {string} clientTimestamp The time on the client when save was initiated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public deleteAppCustomerTab(storeId: number, customerTabGuid: string, clientTimestamp: string, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).deleteAppCustomerTab(storeId, customerTabGuid, clientTimestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId Store Id
     * @param {Array<string>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public deleteAppProductionStationOrders(storeId: number, body?: Array<string>, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).deleteAppProductionStationOrders(storeId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tabProductGuid The guid of the tab product to remove
     * @param {string} clientTimestamp The time on the client when save was initiated
     * @param {number} storeId Store Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public deleteAppTabProduct(tabProductGuid: string, clientTimestamp: string, storeId: number, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).deleteAppTabProduct(tabProductGuid, clientTimestamp, storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId The store to fetch categories for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getAppCategories(storeId: number, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getAppCategories(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customertabId The customertab to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getAppCustomertab(customertabId: string, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getAppCustomertab(customertabId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId The store to fetch products for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getAppCustomertabs(storeId: number, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getAppCustomertabs(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId The store to fetch tabs for
     * @param {string} from The period to fetch from
     * @param {string} to The period to fetch to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getAppCustomertabsHistory(storeId: number, from: string, to: string, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getAppCustomertabsHistory(storeId, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId The store to fetch production stations for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getAppProductionStationOrders(storeId: number, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getAppProductionStationOrders(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId The store to fetch production stations for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getAppProductionStations(storeId: number, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getAppProductionStations(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId The store to fetch products for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getAppProducts(storeId: number, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getAppProducts(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId The store to fetch stations for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getAppStations(storeId: number, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getAppStations(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId The store to fetch tab products for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getAppTabProducts(storeId: number, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getAppTabProducts(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId The store to fetch products for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getAppTables(storeId: number, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getAppTables(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getSignalrinfo(options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getSignalrinfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeid Store id)
     * @param {string} userid Guid of the session (user)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public joingroup(storeid: number, userid: string, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).joingroup(storeid, userid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId The store to fetch products for
     * @param {string} clientTimestamp The time on the client when save was initiated
     * @param {Array<string>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public postAppCombineTabs(storeId: number, clientTimestamp: string, body?: Array<string>, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).postAppCombineTabs(storeId, clientTimestamp, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientTimestamp The time on the client when save was initiated
     * @param {number} storeId Store Id
     * @param {CustomerTab} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public postAppCustomerTab(clientTimestamp: string, storeId: number, body?: CustomerTab, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).postAppCustomerTab(clientTimestamp, storeId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LogRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public postAppLog(body: LogRequest, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).postAppLog(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId Store Id
     * @param {Array<ProductionStationOrder>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public postAppProductionStationOrders(storeId: number, body?: Array<ProductionStationOrder>, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).postAppProductionStationOrders(storeId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId The store to fetch products for
     * @param {string} clientTimestamp The time on the client when save was initiated
     * @param {Array<TabProduct>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public postAppTabProducts(storeId: number, clientTimestamp: string, body?: Array<TabProduct>, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).postAppTabProducts(storeId, clientTimestamp, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/delete-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'the id of the category to remove';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/delete-product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'the id of the product to remove';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductGroup: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/delete-product-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'the id of the group to remove';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductionStation: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/delete-production-station`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'the id of the production station to remove';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStation: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/delete-station`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'the id of the product to remove';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeid The store to fetch categories for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppStationTabs: async (storeid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeid' is not null or undefined
            assertParamExists('getAppStationTabs', 'storeid', storeid)
            const localVarPath = `/get-app-station-tabs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeid !== undefined) {
                localVarQueryParameter['storeid'] = storeid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/get-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCategory', 'id', id)
            const localVarPath = `/get-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id product id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProduct', 'id', id)
            const localVarPath = `/get-product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/get-product-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id production station id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionStation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProductionStation', 'id', id)
            const localVarPath = `/get-production-station`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionStations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/get-production-stations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/get-products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/get-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/get-stations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Category} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategory: async (body?: Category, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/post-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'category';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Product} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProduct: async (body?: Product, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/post-product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'product';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductGroup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductGroup: async (body?: ProductGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/post-product-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'productgroup';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductionStation} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductionStation: async (body?: ProductionStation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/post-production-station`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'production-station';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Station} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStation: async (body?: Station, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/post-station`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'station';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCategory(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCategory(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductGroup(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductGroup(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductionStation(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductionStation(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStation(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStation(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeid The store to fetch categories for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppStationTabs(storeid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerTab>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppStationTabs(storeid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategory(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id product id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProduct(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProduct(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id production station id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductionStation(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Station>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductionStation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductionStations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductionStation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductionStations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Settings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Station>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Category} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCategory(body?: Category, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCategory(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Product} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProduct(body?: Product, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProduct(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductGroup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProductGroup(body?: ProductGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProductGroup(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductionStation} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProductionStation(body?: ProductionStation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionStation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProductionStation(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Station} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStation(body?: Station, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Station>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStation(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(body?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCategory(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(body?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProduct(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductGroup(body?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProductGroup(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductionStation(body?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProductionStation(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStation(body?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteStation(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeid The store to fetch categories for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppStationTabs(storeid: number, options?: any): AxiosPromise<Array<CustomerTab>> {
            return localVarFp.getAppStationTabs(storeid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(options?: any): AxiosPromise<Array<Category>> {
            return localVarFp.getCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id category id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory(id: number, options?: any): AxiosPromise<Category> {
            return localVarFp.getCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id product id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct(id: number, options?: any): AxiosPromise<Product> {
            return localVarFp.getProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductGroups(options?: any): AxiosPromise<Array<ProductGroup>> {
            return localVarFp.getProductGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id production station id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionStation(id: number, options?: any): AxiosPromise<Station> {
            return localVarFp.getProductionStation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionStations(options?: any): AxiosPromise<Array<ProductionStation>> {
            return localVarFp.getProductionStations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.getProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(options?: any): AxiosPromise<Settings> {
            return localVarFp.getSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStations(options?: any): AxiosPromise<Array<Station>> {
            return localVarFp.getStations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Category} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategory(body?: Category, options?: any): AxiosPromise<Category> {
            return localVarFp.postCategory(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Product} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProduct(body?: Product, options?: any): AxiosPromise<Product> {
            return localVarFp.postProduct(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductGroup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductGroup(body?: ProductGroup, options?: any): AxiosPromise<ProductGroup> {
            return localVarFp.postProductGroup(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductionStation} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductionStation(body?: ProductionStation, options?: any): AxiosPromise<ProductionStation> {
            return localVarFp.postProductionStation(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Station} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStation(body?: Station, options?: any): AxiosPromise<Station> {
            return localVarFp.postStation(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCategory(body?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteCategory(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProduct(body?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteProduct(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProductGroup(body?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteProductGroup(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProductionStation(body?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteProductionStation(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteStation(body?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteStation(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeid The store to fetch categories for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAppStationTabs(storeid: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAppStationTabs(storeid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCategories(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id category id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCategory(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id product id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProduct(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProductGroups(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProductGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id production station id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProductionStation(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProductionStation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProductionStations(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProductionStations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProducts(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSettings(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStations(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Category} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postCategory(body?: Category, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postCategory(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Product} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postProduct(body?: Product, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postProduct(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductGroup} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postProductGroup(body?: ProductGroup, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postProductGroup(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductionStation} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postProductionStation(body?: ProductionStation, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postProductionStation(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Station} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postStation(body?: Station, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postStation(body, options).then((request) => request(this.axios, this.basePath));
    }
}


