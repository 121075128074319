
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({ components: {  } })
export default class ScheduleContent extends Vue {
 async Mounted()
 {
 }
}

