
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { DisplayMode, IEditData, DataBinding, BindingMode } from "./edit-data";
import { IsNewRow } from "./new-row.vue";

@Component
export default class EditWrapper extends Vue {
  $refs!: {
    theinput: any;
  };
  @Prop()
  displayMode!: DisplayMode;
  @Prop()
  rowInEdit!: boolean;
  @Prop()
  newRow!: boolean;
  @Prop()
  visible!: boolean;
  @Prop()
  editable!: boolean;
  @Prop()
  edit!: any;
  @Prop()
  editProps!: any;
  @Prop()
  updateEmitter!: string;
  @Prop()
  editvalue!: any;
  @Prop()
  immediate!: boolean;
  @Prop()
  info!: string | undefined;
  @Prop()
  binding!: DataBinding;
  @Prop()
  record!: any;

  inEdit: boolean = false;
  originalValue: any;

  mounted() {
    this.originalValue = this.GetValue();
  }

  cancel() {
    this.inEdit = false;
    if (this.originalValue) this.SetValue(this.originalValue);
  }

  accept() {
    this.$emit("accept", this.record);
    this.originalValue = this.GetValue();
    this.inEdit = false;
  }
  GetValue(): any {
    switch (this.binding.mode) {
      case BindingMode.Bound:
      case undefined: {
        return this.record[this.binding.propertyName];
      }
      case BindingMode.Unbound: {
        if (this.binding.customUnboundDataGet)
          return this.binding.customUnboundDataGet();
      }
    }
    return null;
  }
  SetValue(value: any) {
    switch (this.binding.mode) {
      case BindingMode.Bound:
      case undefined: {
        this.record[this.binding.propertyName] = value;
        break;
      }
      case BindingMode.Unbound: {
        if (this.binding.customUnboundDataSet)
          this.binding.customUnboundDataSet(value);
        break;
      }
    }
  }
  get show() {
    return this.visible && (!this.newRow || this.rowInEdit);
  }
  get showEditor() {
    return this.inEdit || this.rowInEdit || this.immediate;
  }
  get showInfo() {
    return !this.inEdit && this.info;
  }
  get showActions() {
    return this.inEdit && !this.immediate;
  }
  get editMode() {
    return this.inEdit;
  }
  get allDisplayValues(): any[] {
    let result = new Array<any>();
    let x = 0;
    let formattedValue = this.binding.formatDisplayValue
      ? this.binding.formatDisplayValue(this.GetValue())
      : this.GetValue();

    if (Array.isArray(formattedValue)) {
      formattedValue.forEach((element: any) => {
        result.push({
          id: x++,
          value: element,
        });
      });
    } else {
      result.push({
        id: x++,
        value: formattedValue,
      });
    }
    return result;
  }
  get on() {
    var on: { [k: string]: any } = {};
    on[this.updateEmitter] = (e: any) => {
      this.SetValue(
        this.binding.parseEditValue ? this.binding.parseEditValue(e) : e
      ),
        // (this.record[this.binding.propertyName] = this.binding.parseEditValue
        //   ? this.binding.parseEditValue(e)
        //   : e),
        this.immediate && !IsNewRow(this.record) && this.accept();
    };
    return on;
  }
  editToggle() {
    this.inEdit = !this.inEdit;
  }
}
