
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ProductRecord from "./ProductRecord";

@Component
export default class EditableCellCategory extends Vue {
  $refs!: {
    theinput: any;
  };

  @Prop()
  record!: ProductRecord;
  @Prop()
  categories!: any[];
  valueChanged: number = 0;
  newValue!: category[];

  editmode: boolean = false;

  get NewRow() {
    if (this.record) {
      return this.record.NewRowRecord;
    }
    return false;
  }

  getColor(kategori: category) {
    let target = this.categories.find((c) => c.id == kategori.id);
    if (target) {
      return target.farg;
    }
    return "#000000";
  }

  getvalue() {
    this.valueChanged;
    if (this.newValue) {
      return this.newValue;
    } else if (this.record) {
      return this.record.Product.categories;
    }
    return null;
  }
  getdefault() {
    this.valueChanged;
    let result = new Array();
    if (this.newValue) {
      this.newValue.forEach((element) => {
        result.push(element.id);
      });
    } else if (this.record.Product.categories) {
      this.record.Product.categories.forEach((element) => {
        result.push(element.id);
      });
    }
    return result;
  }
  handleChange(value: number[]) {
    let result = new Array();
    value.forEach((c) => {
      let target = this.categories.find((d) => d.id == c);
      result.push(new category(target.id, target.name, target.nummer));
    });
    this.newValue = result;
    this.valueChanged++;
    if (this.record.NewRowRecord) {
      this.$emit("update", this.newValue);
    }
  }
  editToggle() {
    this.editmode = !this.editmode;
  }
  cancelEdit() {
    delete this.newValue;
    this.editmode = !this.editmode;
    this.valueChanged++;
  }
  acceptEdit() {
    this.editmode = !this.editmode;
    this.valueChanged++;
    if (this.newValue) {
      this.$emit("update", this.newValue);
    }
  }
}
class category {
  id!: number;
  text!: string;
  nummer!: number;
  constructor(id: number, text: string, nummer: number) {
    this.id = id;
    this.text = text;
    this.nummer = nummer;
  }
}
