
import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";
import { Task } from "@/api/api";

@Component
export default class TaskLastDone extends Vue {
  @Prop()
  record!: Task;

  get NewRow() {
    return false;
  }

  get Value() {
    if (this.record.lastdone != null){
      return this.GetDate(new Date(this.record.lastdone!));
    }
    return "";
  }
  get isRed(): boolean {
    if (this.record?.lastdone != null && this.record.visibledays > 0) {
      var now = new Date();
      var done = new Date(this.record.lastdone!);
      for (var i = 0; i < 7; i++) {
        var dateToBeDone = new Date();
        dateToBeDone.setDate(now.getDate() - i);
        var flag = this.GetFlagFromDate(dateToBeDone);
        if ((this.record.visibledays & flag) != 0) {
          return this.GetDate(done) != this.GetDate(dateToBeDone);
        }
      }
    }
    return true;
  }

  private GetFlagFromDate(date: Date): number {
    switch (date.getDay()) {
      case 1: //Monday
        return 1;
      case 2:
        return 2;
      case 3:
        return 4;
      case 4:
        return 8;
      case 5:
        return 16;
      case 6:
        return 32;
      default: //Sunday 0
        return 64
    }
  }

  private GetDate(date: Date): string {
    return date.getDate() + "/" + (date.getMonth() + 1);
  }
}
