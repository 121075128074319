
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Header from "./views/Header.vue";
import Footer from "./views/Footer.vue";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";

@Component({
  components: { Header, Footer },
})
export default class App extends Vue {
  public name: string = "app";
}
