
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {storeApi} from "@/services/ApiService";
import {Product, Category, CategoryProduct, ProductUserField, CategoryTableField} from "@/api/api";
import {AvailableTableFieldColumn} from "@/components/store/category/entities/AvailableTableFieldColumn";
import {PickedTableFieldColumn} from "@/components/store/category/entities/PickedTableFieldColumn";


@Component
export default class TableContent extends Vue {
    @Prop()
    category?: Category;
    categoryId: number = 0;
    loading: boolean = true;
    loadingUserFields: boolean = false;
    completeUrval: Product[] = [];
    categoryProduts: CategoryProduct[] = [];
    userFieldsUrval: AvailableTableFieldColumn[] = [];
    fieldsPicked: PickedTableFieldColumn[] = [];
    userFields: ProductUserField[] = [];
    urval: Product[] = [];
    urvalUpdated: number = 0;
    valdaUpdated: number = 0;
    forceUpdate: number = 0;
    pager: any = {pageSize: 50};
    checked: boolean = true;
    modalOpen: boolean = false;
    error: string = "Komponenten är inte initierad än, vänta tills den är klar";

    filterUrval: boolean = true;
    filterUrvalText: string = "";

    onChange(e: any) {
        this.checked = e.target.checked;
    }

    columnsUserFields = [
        {
            dataIndex: "name",
            colSpan: 2,
            slots: {
                title: "fieldTitle",
            },
        },
        {
            width: "40px",
            colSpan: 0,
            slots: {
                title: "fieldOperationsTitle",
            },
            scopedSlots: {customRender: "fieldOperations"},
        },
    ];
    columnsValdaFields = [
        {
            dataIndex: "name",
            colSpan: 2,
            slots: {
                title: "fieldTitle",
            },
        },
        {
            width: "72px",
            colSpan: 0,
            slots: {
                title: "fieldOperationsTitle",
            },
            scopedSlots: {customRender: "fieldOperations"},
        },
    ];


    columnsUrval = [
        {
            dataIndex: "title",
            colSpan: 2,
            slots: {
                title: "varaTitle",
            },
        },
        {
            width: "40px",
            colSpan: 0,
            slots: {
                title: "operationsTitle",
            },
            scopedSlots: {customRender: "operations"},
        },
    ];
    columnsValda = [
        {
            dataIndex: "text",
            colSpan: 2,
            slots: {
                title: "varaTitle",
            },
        },
        {
            colSpan: 0,
            width: "72px",
            scopedSlots: {customRender: "operations"},
        },
    ];

    checkResultStatus(status: number): boolean {
        return status >= 200 && status < 300;
    }

    async mounted() {
        this.categoryId = Number.parseInt(this.$route.params.id);
        var result = await storeApi.getProducts();
        var categoryProdutsResult = await storeApi.getCategoryProducts(this.categoryId);
        this.userFields = (await storeApi.getProductUserFields()).data;
        //Fixed values
        this.userFieldsUrval.push(new AvailableTableFieldColumn("PLU", undefined, undefined));
        this.userFieldsUrval.push(new AvailableTableFieldColumn("Vara", undefined, undefined));
        for (let i = 0; i < this.userFields.length; i++) {
            const currentField = this.userFields[i];
            this.userFieldsUrval.push(new AvailableTableFieldColumn(undefined, currentField.id, currentField.name));
        }


        this.completeUrval = result.data;

        let filtered = this.completeUrval.filter(
            (c: Product) => !c.categories || c.categories.length == 0
        );

        this.pager = {pageSize: 20, current: 1, total: filtered.length};

        this.urval = filtered.slice(0, 20);

        this.categoryProduts = categoryProdutsResult.data.sort(
            (a: any, b: any) => a.sortOrder - b.sortOrder
        );
        if (
            !this.checkResultStatus(result.status) ||
            !this.checkResultStatus(categoryProdutsResult.status)
        ) {
            this.error =
                "Varor kunde inte hämtas. Öppna kategorin på nytt och försök igen";
        } else {
            this.error = "";
        }
        this.valdaUpdated++;
        this.loading = false;
    }

    get valda() {
        this.valdaUpdated;
        if (this.categoryProduts) {
            if (this.filterValdaText) {
                return this.categoryProduts.filter((c: any) =>
                    c.text.toLowerCase().includes(this.filterValdaText.toLowerCase())
                );
            }

            return this.categoryProduts;
        }
        return [];
    }

    get sorteraBokstavsordning() {
        this.forceUpdate;
        if (this.category) {
            return this.category.defaultAlphabeticalOrder;
        }
        return false;
    }

    get alfabetiskKategorisering() {
        this.forceUpdate;
        if (this.category) {
            return this.category.defaultAlphabeticalGrouping;
        }
        return false;
    }

    get exkluderaInaktiva() {
        this.forceUpdate;
        if (this.category) {
            return this.category.defaultExcludeInactive;
        }
        return false;
    }

    public async openDesign() {
        this.fieldsPicked = [];
        var pickedFields = (await storeApi.getCategoryTable(this.categoryId)).data;
        for (let i = 0; i < pickedFields.length; i++) {
            const currentField = pickedFields[i];
            let foundFieldName = undefined;
            if (currentField.productUserDefinedFieldId != null) {
                foundFieldName = this.userFields.find(field => field.id === currentField.productUserDefinedFieldId)?.name;
            }
            this.fieldsPicked.push(new PickedTableFieldColumn(currentField.sortOrder ?? 0, currentField.productField, currentField.productUserDefinedFieldId, foundFieldName));
        }
        this.modalOpen = true;
    }

    public chkChangedSort(e: any) {
        if (this.category) {
            this.category.defaultAlphabeticalOrder = e.target.checked;
            this.forceUpdate++;
        }
    }

    public chkChangedAlf(e: any) {
        if (this.category) {
            this.category.defaultAlphabeticalGrouping = e.target.checked;
            this.forceUpdate++;
        }
    }

    public chkChangedExc(e: any) {
        if (this.category) {
            this.category.defaultExcludeInactive = e.target.checked;
            this.forceUpdate++;
        }
    }

    public Up(record: any) {
        var x = Array.from(document.getElementsByClassName("ant-tooltip"));
        x.forEach((c: any) => (c.style.display = "none"));
        var curIndex = this.categoryProduts.indexOf(record);
        if (curIndex == 0) return;
        var prev = this.categoryProduts[curIndex - 1];
        var prevSort = prev.sortOrder;
        prev.sortOrder = record.sortOrder;
        record.sortOrder = prevSort;
        this.categoryProduts = this.categoryProduts.sort(
            (a: any, b: any) => a.sortOrder - b.sortOrder
        );
        this.valdaUpdated++;
    }

    public UpField(record: PickedTableFieldColumn) {
        console.log("UP");
        var curIndex = this.fieldsPicked.indexOf(record);
        if (curIndex == 0) return;
        var prev = this.fieldsPicked[curIndex - 1];
        var prevSort = prev.sortOrder;
        prev.sortOrder = record.sortOrder;
        record.sortOrder = prevSort;
        this.fieldsPicked = this.fieldsPicked.sort(
            (a: any, b: any) => a.sortOrder - b.sortOrder
        );
        this.valdaUpdated++;
    }

    public Down(record: any) {
        var x = Array.from(document.getElementsByClassName("ant-tooltip"));
        x.forEach((c: any) => (c.style.display = "none"));
        var curIndex = this.categoryProduts.indexOf(record);
        if (curIndex >= this.categoryProduts.length - 1) return;
        var next = this.categoryProduts[curIndex + 1];
        var nextSort = next.sortOrder;
        next.sortOrder = record.sortOrder;
        record.sortOrder = nextSort;
        this.categoryProduts = this.categoryProduts.sort(
            (a: any, b: any) => a.sortOrder - b.sortOrder
        );
        this.valdaUpdated++;
    }

    public DownField(record: PickedTableFieldColumn) {
        var curIndex = this.fieldsPicked.indexOf(record);
        if (curIndex >= this.fieldsPicked.length - 1) return;
        var next = this.fieldsPicked[curIndex + 1];
        var nextSort = next.sortOrder;
        next.sortOrder = record.sortOrder;
        record.sortOrder = nextSort;
        this.fieldsPicked = this.fieldsPicked.sort(
            (a: any, b: any) => a.sortOrder - b.sortOrder
        );
        this.valdaUpdated++;
    }

    public Add(record: any) {
        if (
            this.categoryProduts.filter((c: any) => c.id == record.id).length == 0
        ) {
            if (this.category) {
                this.categoryProduts.push({
                    id: record.id,
                    text: record.title,
                    sortOrder: this.categoryProduts.length,
                });
            }
        }
        this.handleChangeUrval(this.pager);
        this.valdaUpdated++;
    }

    public AddField(record: AvailableTableFieldColumn) {

        if (this.fieldsPicked.some(field => field.key === record.key)) {
            return;
        }
        const maxSortOrder = this.fieldsPicked.length > 0 ? Math.max(...this.fieldsPicked.map(field => field.sortOrder)) : 0;

        var newItem = new PickedTableFieldColumn(maxSortOrder + 1, record.productField, record.productUserFieldId, record.productUserFieldName)
        this.fieldsPicked.push(newItem);
    }

    public Remove(record: any) {
        let index = this.categoryProduts.indexOf(record);
        this.categoryProduts.splice(index, 1);
        this.handleChangeUrval(this.pager);
        this.valdaUpdated++;
        this.urvalUpdated++;
    }

    public RemoveField(record: PickedTableFieldColumn) {
        let index = this.fieldsPicked.indexOf(record);
        this.fieldsPicked.splice(index, 1);
        this.handleChangeUserFields(this.pager);
        this.valdaUpdated++;
        this.urvalUpdated++;
    }

    filterValdaText: string = "";

    public onSearchValdaChanged(e: any) {
        if (!e.data && e.srcElement._value.length == 1) {
            this.filterValdaText = e.data;
            this.valdaUpdated++;
        }
    }

    public onSearchValda(e: any) {
        this.filterValdaText = e;
        this.valdaUpdated++;
    }

    public onSearchChanged(e: any) {
        if (!e.data && e.srcElement._value.length == 1) {
            this.filterUrvalText = e.data;
            this.handleChangeUrval(this.pager);
        }
    }

    public onSearchUrval(e: any) {
        this.filterUrvalText = e;
        this.handleChangeUrval(this.pager);
    }

    public async modalOk() {

        const mappedArray: CategoryTableField[] = this.fieldsPicked.map(item => ({
            id: undefined,  // Assuming `id` should be optional or can be undefined initially.
            productField: item.productField,
            productUserDefinedFieldId: item.productUserFieldId,
            sortOrder: item.sortOrder
        }));
        await storeApi.postCategoryTable(this.categoryId, mappedArray);
        this.modalOpen = false;
    }

    public modalCancel() {
        this.modalOpen = false;
    }

    checkErrors(): string | undefined {
        return this.error;
    }

    async save(): Promise<any> {
        return storeApi.postCategoryProducts(
            this.category!.id,
            this.categoryProduts
        );
    }

    public handleChangeUrval(pagination: any) {
        let filtered: Product[];
        if (this.filterUrval) {
            filtered = this.completeUrval.filter(
                (c: Product) => c.categories!.length == 0
            );
        } else {
            filtered = this.completeUrval;
        }
        if (this.filterUrvalText) {
            filtered = filtered.filter((c) =>
                c.title!.toLowerCase().includes(this.filterUrvalText.toLowerCase())
            );
        }
        filtered = filtered.filter(
            (c: Product) => !this.categoryProduts.some((d) => d.id == c.id)
        );

        let skip = pagination.pageSize * (pagination.current - 1);
        this.urval = filtered.slice(skip, skip + 20);
        pagination.total = filtered.length;
        this.pager = pagination;
    }

    public handleChangeUserFields(pagination: any) {

    }
}
