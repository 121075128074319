
import Vue from "vue";
import { Component, Prop,  } from "vue-property-decorator";
import { CategoryTypeEnum } from "@/api/api";

@Component
export default class SpecialEdit extends Vue {
  @Prop()
  departmentCategoryProperties!: number;
  @Prop()
  categoryType!: CategoryTypeEnum;
  @Prop()
  displayType!: number;

  currentValue: number = 0

  mounted(){
    this.currentValue = this.departmentCategoryProperties;
  }

  getDefault() {
     if (this.departmentCategoryProperties)
      return this.departmentCategoryProperties;
     return 0;
  }

get barcodeVisible(){
  return this.categoryType == 0 && (this.displayType == 4 || this.displayType == 2); //varor med (streckkod eller bild och streckkod)
}

get barcodeActive(){
  return (this.currentValue & 1) != 0;
}

set barcodeActive(value: boolean)
{
  if(value)
    this.currentValue |= 1;
  else
    this.currentValue &= ~1;
}

barcodeActiveClicked(){
  this.barcodeActive = !this.barcodeActive;
  this.$emit("valueChanged", this.currentValue)
}

}
