import { Contact } from "@/api/api";
import IRecord from "@/components/controls/grid/IRecord";

export default class ContactRecord implements IRecord {
  public Contact: Contact;
  public NewRow: boolean;

  public RowEdit: boolean;
  public Key: number;
  constructor(contact: Contact, newRow: boolean) {
    this.Contact = contact;
    this.Key = contact.id;
    this.NewRow = newRow;
    this.RowEdit = false;
  }

  get Name() {
    return this.Contact.name;
  }
  set Name(value: undefined | string) {
    this.Contact.name = value;
  }
  get Text() {
    return this.Contact.text;
  }
  set Text(value: undefined | string) {
    this.Contact.text = value;
  }
  get Number() {
    return this.Contact.number;
  }
  set Number(value: undefined | string) {
    this.Contact.number = value;
  }
  get Role() {
    return this.Contact.role;
  }
  set Role(value: undefined | string) {
    this.Contact.role = value;
  }
}
