
export class PickedTableFieldColumn {
    productField?: string;
    productUserFieldId?: number;
    productUserFieldName?: string;
    sortOrder: number;
    
    constructor(sortOrder: number, productField?: string, productUserFieldId?: number, productUserFieldName?: string) {
        this.productField = productField;
        this.productUserFieldId = productUserFieldId;
        this.productUserFieldName = productUserFieldName;
        this.sortOrder = sortOrder;
    }
    get key(): string {
        if (this.productField) {
            return `field_${this.productField}`;
        } else if (this.productUserFieldId !== undefined) {
            return `userfield_${this.productUserFieldId}`;
        } else {
            return 'unknown';
        }   
    }
    
    get name(): string {
        return this.productField ?? this.productUserFieldName ?? "";
    }
}