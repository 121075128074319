var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"clearfix"},[_c('a-upload',{attrs:{"listType":"picture-card","fileList":_vm.fileList,"beforeUpload":_vm.handleBeforeUpload,"remove":_vm.handleRemove,"showUploadList":{ showRemoveIcon: false }},on:{"preview":_vm.handlePreview}},[(_vm.fileList.length == 0)?_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'laddar' : 'plus'}})],1):_vm._e()]),(_vm.previewVisible)?_c('a-modal',{attrs:{"title":_vm.Title,"closable":false,"visible":_vm.previewVisible,"maskClosable":false},on:{"cancel":_vm.handleCropCancel,"ok":_vm.handleCropOk}},[_c('template',{slot:"footer"},[_c('footer',[_c('input',{ref:"fileinput",staticStyle:{"display":"none"},attrs:{"id":"file-input","type":"file","name":"name"}}),_c('a-button',{key:"a",staticStyle:{"float":"left"},attrs:{"icon":"upload"},on:{"click":_vm.openFileDialog}},[_vm._v("Byt")]),_c('a-button',{key:"b",staticStyle:{"float":"left"},attrs:{"icon":"delete"},on:{"click":_vm.handleRemove}},[_vm._v("Ta bort")]),_c('a-button',{key:"c",on:{"click":_vm.handleCropCancel}},[_vm._v("Avbryt")]),_c('a-button',{key:"d",attrs:{"type":"primary"},on:{"click":_vm.handleCropOk}},[_vm._v("Ok")])],1)]),_c('Cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.getPreviewImage(),"wheelResize":false,"stencil-props":{
        minAspectRatio: 1/1,
        maxAspectRatio: 1/1,
      },"default-size":(e) => {
          return {
            width: e.imageWidth,
            height: e.imageHeight
          };
        }}})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }