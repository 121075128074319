
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { storeApi } from "@/services/ApiService";
import { Contact } from "@/api/api";
import NewContactRow from "@/components/admin/editStore/contacts/NewContactRow.vue";
import ContactRecord from "@/components/admin/editStore/contacts/ContactRecord";
import EditableCellText from "@/components/controls/grid/editableCellText.vue";
import CellOperations from "@/components/admin/editStore/contacts/CellOperations.vue";
import IRecord from "@/components/controls/grid/IRecord";

@Component({
  components: {
    NewContactRow,
    EditableCellText,
    CellOperations,
  },
})
export default class Contacts extends Vue {
  columns = [
    {
      title: "Namn",
      customRender: (record: ContactRecord) => {
        if (record.NewRow && !record.RowEdit) {
          return {
            children: this.$createElement(NewContactRow, {
              on: {
                click: (e: ContactRecord) => {
                  this.InitNewRow(record);
                },
              },
            }),
            attrs: {
              colSpan: 5,
              style: "padding: 0px;",
            },
          };
        } else {
          return {
            children: this.$createElement(EditableCellText, {
              props: {
                record: record,
                value: record.Name,
              },
              on: {
                update: (name: string) => this.updateName(record, name),
              },
            }),
          };
        }
      },
    },
    {
      title: "Nummer",
      customRender: (record: ContactRecord) => {
        if (record.NewRow && !record.RowEdit) {
          return {
            attrs: {
              colSpan: 0,
            },
          };
        } else {
          return {
            children: this.$createElement(EditableCellText, {
              props: {
                record: record,
                value: record.Number,
              },
              on: {
                update: (number: string) => this.updateNumber(record, number),
              },
            }),
          };
        }
      },
    },
    {
      title: "Roll",
      customRender: (record: ContactRecord) => {
        if (record.NewRow && !record.RowEdit) {
          return {
            attrs: {
              colSpan: 0,
            },
          };
        } else {
          return {
            children: this.$createElement(EditableCellText, {
              props: {
                record: record,
                value: record.Role,
              },
              on: {
                update: (role: string) => this.updateRole(record, role),
              },
            }),
          };
        }
      },
    },
    {
      title: "E-mail",
      customRender: (record: ContactRecord) => {
        if (record.NewRow && !record.RowEdit) {
          return {
            attrs: {
              colSpan: 0,
            },
          };
        } else {
          return {
            children: this.$createElement(EditableCellText, {
              props: {
                record: record,
                value: record.Text,
              },
              on: {
                update: (text: string) => this.updateText(record, text),
              },
            }),
          };
        }
      },
    },
    {
      width: "80px",
      customRender: (record: ContactRecord) => {
        if (record.NewRow && !record.RowEdit) {
          return {
            attrs: {
              colSpan: 0,
            },
          };
        } else {
          return {
            children: this.$createElement(CellOperations, {
              props: {
                record: record,
              },
              on: {
                accept: () => this.saveNewRow(record),
                cancel: () => this.cancelNewRow(record),
                delete: () => this.deleteContact(record),
              },
            }),
          };
        }
      },
    },
  ];

  newRow: ContactRecord = new ContactRecord({ id: -1 }, true);
  data: ContactRecord[] = [];
  dataupdated: number = 0;

  async mounted() {
    let result = await storeApi.getContacts(this.CurrentStore());
    result.data.forEach((element) => {
      this.data.push(new ContactRecord(element, false));
    });
    this.dataupdated++;
  }
  public CurrentStore(): number {
    return Number(this.$router.currentRoute.params["id"]);
  }
  get getData() {
    this.dataupdated;
    let result = [];
    result.push(this.newRow);
    this.data.forEach((element) => {
      result.push(element);
    });
    return result;
  }

  updateRole(record: ContactRecord, role: string) {
    record.Role = role;
    console.log(record);
    if (record.Contact.id > 0) {
      this.save(record.Contact);
    }
  }

  updateName(record: ContactRecord, name: string) {
    record.Name = name;
    if (record.Contact.id > 0) {
      this.save(record.Contact);
    }
  }

  updateText(record: ContactRecord, text: string) {
    record.Text = text;
    if (record.Contact.id > 0) {
      this.save(record.Contact);
    }
  }

  updateNumber(record: ContactRecord, number: string) {
    record.Number = number;
    if (record.Contact.id > 0) {
      this.save(record.Contact);
    }
  }

  async saveNewRow(record: ContactRecord) {
    let result = await storeApi.postContact(record.Contact);
    record.Contact.id = result.data.id;
  }

  cancelNewRow(record: ContactRecord) {
    this.newRow = new ContactRecord({ id: -1 }, true);
  }

  async save(contact: Contact) {
    let result = await storeApi.postContact(contact);
    this.notifySave(result.data.name!);
  }

  async deleteContact(record: ContactRecord) {
    await storeApi.deleteContact(record.Key);
    let index = this.data.indexOf(record, 0);
    if (index > -1) {
      this.data.splice(index, 1);
    }
  }

  public notifySave(name: string) {
    this.$notification.open({
      message: "Sparade kontakt",
      description: name,
      placement: "bottomRight",
      duration: 4,
    });
  }

  InitNewRow(record: ContactRecord) {
    record.RowEdit = true;
    // this.filterUpdated++;
  }
}
