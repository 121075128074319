
import Vue from "vue";
import { Component } from "vue-property-decorator";
import * as ST from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import TextEdit from "@/components/controls/text-edit.vue";
import BlogItem from "@/components/store/dev-blog/blog-item";

@Component({ components: { STable } })
export default class DevBlog extends Vue {
  dataSource: BlogItem[] = [];
  existingDialogVisible = false;

  public mounted() {
    this.dataSource.push(...this.fillBlog());
  }

  tableSettings: ST.Settings<BlogItem> = {
    dataSource: this.dataSource,
    pagination: false,
    showNewRow: false,
    newRowText: "Skapa ny kategori",
    loading: false,
    columns: [
        {
            title: "Beskrivning",
            edit: TextEdit,
            width: 100,
            editData: (record) => ({
                dataBinding: {
                    propertyName: "text",
                },
                editable: false,
                immediate: false,
            }),
        },
        {
            title: "Utvecklat",
            edit: TextEdit,
            width: 100,
            editData: (record) => ({
                editable: false,
                immediate: false,
                dataBinding: {
                    propertyName: "implemented",
                },
                editValue: record.implemented,
            }),
        },
    ],
};
public fillBlog(): Array<BlogItem> {
    let result = new Array<BlogItem>();

    result.push({
        id: 1,
        text: "Sortering på avdelning",
        implemented: "2020-11-10",
    });
    result.push({
        id: 2,
        text: "Uppdaterat och modifierat bildverktyget",
        implemented: "2021-01-21",
    });
    result.push({
        id: 3,
        text: "Modifierat bildverktyget ytterligare. Storlek korrigerad & Zoom-in Zoom-out knappar implementerade",
        implemented: "2021-01-23",
    });
    result.push({
        id: 4,
        text: "Kontaktpersoner fixat",
        implemented: "2021-01-23",
    });
    result.push({
        id: 5,
        text: "Sortering är ändrad, lite otestad men prova gärna",
        implemented: "2021-01-23",
    });
    result.push({
        id: 6,
        text: "Taggar bytt namn till Egenskaper, vi vill kanske lägga fler egenskaper på varor i framtiden",
        implemented: "2021-01-23",
    });
    result.push({
        id: 7,
        text: "Sökningar bryr sig inte om versaler/gemener.",
        implemented: "2021-01-23",
    });
    result.push({
        id: 8,
        text: "Lagt till en hjälp knapp på avdelningar-vyn",
        implemented: "2021-01-23",
    });
    result.push({
        id: 9,
        text: "Fixat till grupper så sparning går igenom. Något felaktigt på plattan kvarstår.",
        implemented: "2021-01-25",
    });
    result.push({
        id: 10,
        text: "Test med webbläsare och cache.",
        implemented: "2021-02-24",
    });
    result.push({
        id: 11,
        text: "Man kan nu lägga upp kategorier av typen hemsidor. Här kan man fritt länka vilken hemsida man vill",
        implemented: "2021-06-21",
    });
    result.push({
        id: 12,
        text: "Q-manager är flyttat till typen App och man kan nu välja mellan Q-manager & ipool",
        implemented: "2021-06-21",
    });
    result.push({
        id: 13,
        text: "På typer med streckkoder kan man nu välja om varor utan streckkod skall visas/döljas(special kolumnen)",
        implemented: "2021-06-21",
    });
    result.push({
        id: 14,
        text: "Ny tabell-vy implementerad",
        implemented: "2024-04-27",
    });
    return result;
}
}
