
import Vue from "vue";
import {Component} from "vue-property-decorator";
import * as ST from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import TextEdit from "@/components/controls/text-edit.vue";
import {storeApi} from "@/services/ApiService";
import {ProductUserField} from "@/api";

@Component({components: {STable}})
export default class UserFields extends Vue {
    dataSource: ProductUserField[] = [];
    existingDialogVisible = false;

    public async mounted() {
        this.dataSource.push(...await this.fillUserFields());
    }

    tableSettings: ST.Settings<ProductUserField> = {
        dataSource: this.dataSource,
        pagination: false,
        showNewRow: true,
        newRowText: "Skapa nytt varufält",
        recordsUpdated: async (records) => this.postUserFields(records),
        sort: {
            sortProperty: "name",
        },
        actions: {
            remove: {
                icon: "delete",
                tooltip: (record => "Borttag av varufält stödjs ej för tillfället"),
                action: (record) => {
                },
            },
        },
        loading: true,
        columns: [
            {
                title: "Namn",
                edit: TextEdit,
                width: 100,
                editData: (record) => ({
                    dataBinding: {
                        propertyName: "name",
                    },
                    props: {
                        defaultValue: record.name,
                    },
                    editable: true,
                    updateEmitter: "valueChanged",
                    immediate: false,
                }),
            }],
    };

    public async fillUserFields(): Promise<Array<ProductUserField>> {
        this.tableSettings.loading = true;
        let result = (await storeApi.getProductUserFields()).data;
        this.tableSettings.loading = false;
        return result;
    }

    async postUserFields(records: Array<ProductUserField>) {

        let res = await storeApi.postProductUserFields(records);
        for (let i = 0; i < records.length; i++) {
            records[i].id = res.data[i].id;
        }
        this.notify("Ändringarna har blivit sparade", "Sparat");
    }

    public notify(description: string, message: string) {
        this.$notification.open({
            message: message,
            description: description,
            placement: "bottomRight",
            duration: 4,
        });
    }
}
