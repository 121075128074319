
import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import {Department, IdText, Taskcategory} from "@/api/api";
import {storeApi} from "@/services/ApiService";
import {restaurantApi} from "@/services/RApiService"

@Component
export default class Menu extends Vue {
    departments: Department[] = [];
    checklists: Taskcategory[] = [];
    checklistorExists: boolean = false;
    enableRestaurant: boolean = false;

    public async mounted() {
        var getDepartmentsTask = storeApi.getDepartmentsWeb();
        var getChecklistsTask = storeApi.getTaskCategories();
        var restaurantSettingsTask = restaurantApi.getSettings();
        var depResult = await getDepartmentsTask;
        var checkResult = await getChecklistsTask;
        if (checkResult.data.length >= 1) this.checklistorExists = true;
        else this.checklistorExists = false;
        var sortedChecklists = checkResult.data.sort((c1, c2) => {
            var c1Text = c1.text?.toLowerCase() ?? "";
            var c2Text = c2.text?.toLowerCase() ?? "";
            if (c1Text > c2Text) return 1;
            if (c1Text < c2Text) return -1;
            return 0;
        });
        depResult.data.forEach((dep) => this.departments.push(dep));
        sortedChecklists.forEach((dep) => this.checklists.push(dep));

        var restaurantSettingsResult = await restaurantSettingsTask;
        this.enableRestaurant = restaurantSettingsResult.data.enabled ?? false;
    }

    @Watch("$route", {immediate: true, deep: true})
    onUrlChange(newVal: any) {
        if (this.$route.params.page === "Installningar" && this.$el) {
            let elements = this.$el.getElementsByClassName("ant-menu-item-selected");
            if (elements.length == 1) {
                elements[0].classList.remove("ant-menu-item-selected");
            }
        }
    }

    public getDepartmentKey(id: string): string {
        return `department:${id}`;
    }

    public getChecklistKey(id: string): string {
        return `checklist:${id}`;
    }

    public handleClick(e: any) {
        var key = e.key.split(":");
        switch (key[0]) {
            case "categories": {
                if (this.$router.currentRoute.name != "storeCategories")
                    this.$router.push({name: "storeCategories"});
                break;
            }
            case "products": {
                if (this.$router.currentRoute.name != "storeProductsV2")
                    this.$router.push({name: "storeProductsV2"});
                break;
            }
            case "videos": {
                if (this.$router.currentRoute.name != "videos")
                    this.$router.push({name: "storeVideos"});
                break;
            }
            case "userfields": {
                if (this.$router.currentRoute.name != "storeUserFields")
                    this.$router.push({name: "storeUserFields"});
                break;
            }
            case "rproducts": {
                if (this.$router.currentRoute.name != "restaurantProducts")
                    this.$router.push({name: "restaurantProducts"});
                break;
            }
            // case "rproductgroups":{
            //   if(this.$router.currentRoute.name != "restaurantProductGroups")
            //     this.$router.push({name: "restaurantProductGroups"});
            //   break;
            // }
            case "rcategories": {
                if (this.$router.currentRoute.name != "restaurantCategories")
                    this.$router.push({name: "restaurantCategories"});
                break;
            }
            case "rstations": {
                if (this.$router.currentRoute.name != "restaurantStations")
                    this.$router.push({name: "restaurantStations"});
                break;
            }
            case "rproductionstations": {
                if (this.$router.currentRoute.name != "restaurantProductionStations")
                    this.$router.push({name: "restaurantProductionStations"});
                break;
            }
            case "productsV2": {
                if (this.$router.currentRoute.name != "storeProductsV2")
                    this.$router.push({name: "storeProductsV2"});
                break;
            }
            case "department": {
                var id = key[1];
                if (
                    this.$router.currentRoute.name != "storeDepartmentCategory" ||
                    this.$router.currentRoute.params.id != id
                )
                    this.$router.push({
                        name: "storeDepartmentCategory",
                        params: {id},
                    });
                break;
            }
            case "checklist": {
                console.log(key[1]);
                var checklistId = key[1];
                if (
                    this.$router.currentRoute.name != "StoreChecklist" ||
                    this.$router.currentRoute.params.id != checklistId
                )
                    this.$router.push({
                        name: "StoreChecklist",
                        params: {id: checklistId},
                    });
                break;
            }
            case "categoryGroup": {
                if (this.$router.currentRoute.name != "storeCategoryGroups")
                    this.$router.push({name: "storeCategoryGroups"});
                break;
            }
            case "develop-news": {
                if (this.$router.currentRoute.name != "storeDevBlog")
                    this.$router.push({name: "storeDevBlog"});
                break;
            }
            case "manual-web": {
                window.open(
                    "https://kassaregisterprod.blob.core.windows.net/files/Manual_2021_1.pdf?sp=r&st=2021-04-07T20:42:08Z&se=2031-04-08T04:42:08Z&spr=https&sv=2020-02-10&sr=b&sig=ISbh7iPPE274%2BXBF%2BJkabfSAyjjMZqAnmWGKZtgEfCc%3D"
                );
                break;
            }
        }
    }
}
