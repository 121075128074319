
import Vue from "vue";
import { Component, Prop, Ref } from "vue-property-decorator";

@Component({
  components: {},
})
export default class CreateTabletDialog extends Vue {
  @Ref("input") readonly input!: HTMLInputElement;
  @Prop()
  visible: boolean = false;
  acceptedValue?: string;
  confirmLoading = false;
  loading = true;

  public async mounted() {}
  public ModalOk() {
    this.input.value = "";
    if (this.acceptedValue) this.$emit("update", this.acceptedValue);
  }

  public ModalCancel() {
    this.input.value = "";
    this.$emit("cancel");
  }
  change(e: any) {
    this.acceptedValue = e.srcElement.value;
  }
}
