import ProductRecord from "./ProductRecord";

export default class ProductRecordFilterer {
  private filterText: string;
  private categories: number[];
  public constructor(text: string, categories: number[]) {
    this.filterText = text;
    this.categories = categories;
  }

  public Filter(c: ProductRecord): boolean {
    if (c.NewRowRecord || c.NewlyAdded) {
      return true;
    }

    if (this.categories && this.categories.length > 0 && c.Product.categories) {
      if (
        c.Product.categories.filter((d: any) =>
          this.categories.includes(d.id.toString())
        ).length == 0
      )
        return false;
    }

    if (!this.filterText) {
      return true;
    }

    if (
      c.Product.title &&
      c.Product.title.toLowerCase().includes(this.filterText.toLowerCase())
    ) {
      return true;
    }

    if (
      c.Product.plu &&
      c.Product.plu
        .toString()
        .toLowerCase()
        .includes(this.filterText.toLowerCase())
    ) {
      return true;
    }
    return false;
  }
}
