
import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class TableActions extends Vue {
  @Prop()
  actions?: Array<ITableActionInternal>;
  @Prop()
  record!: any;
  tooltipClicked = false;
  tooltipHovered = false;

  overlayStyle = {
    width: "82px",
  };

  get showActions() {
    return this.actions && this.actions.length > 0;
  }
  get showAny() {
    return this.showActions;
  }
  confirm(action: ITableActionInternal) {
    return action.confirm;
  }
  tooltip(action: ITableActionInternal) {
    return action.tooltip;
  }
  multipleActions(action: ITableActionInternal) {
    return action.multipleActions || action.multipleActionsFunc;
  }
  getMultipleActions(
    action: ITableActionInternal
  ): (string | [string, Function])[] | undefined {
    if (action.multipleActions) return action.multipleActions;
    else if (action.multipleActionsFunc) return action.multipleActionsFunc();
    return undefined;
  }
  get source() {
    let dataSource = new Array<ITableActionInternal>();

    if (this.actions) {
      this.actions.forEach((c) => {
        if (c.visible) dataSource.push(c);
      });
    }
    return dataSource;
  }
  clicked(e: ITableActionInternal) {
    if (e.action) e.action();
  }
  clickedSort() {
    this.record.sTableSorting = true;
  }
  clickedSortUp() {
    this.$emit("sortingChanged");
  }
  clickedSortDown() {
    this.$emit("sortingChanged");
  }
  clickedMultiAction(action: Function): any {
    this.hide();
    action(this.record);
  }
  popoverVisibleChanged(visible: boolean) {
    if (!visible) this.record.sTableSorting = false;
  }

  hide() {
    this.tooltipClicked = false;
    this.tooltipHovered = false;
  }
  handleHoverChange(visible: boolean) {
    this.tooltipClicked = false;
    this.tooltipHovered = visible;
  }
  handleClickChange(visible: boolean) {
    this.tooltipClicked = visible;
    this.tooltipHovered = false;
  }
}
export interface ITableAction<RecordType> {
  icon: string;
  action?: (record: RecordType) => any;
  visible?: (record: RecordType) => boolean;
  confirmation?: (record: RecordType) => string;
  tooltip?: (record: RecordType) => string;
  rightAlign?: boolean | undefined;
  multiAction?: MultipleActions<RecordType>;
}
export interface ITableActionInternal {
  icon: string;
  visible: boolean;
  action: Function | undefined;
  confirm: string | undefined;
  tooltip?: string | undefined;
  multipleActionsTitle?: string | [string, Function];
  multipleActions?: [string, Function][] | undefined;
  multipleActionsFunc?: () => [string, Function][] | undefined;
  multipleActionsEndGroup?: boolean | undefined;
}
export class MultipleActions<RecordType> {
  title?: string | [string, (record: RecordType) => any];
  actions?: [string, (record: RecordType) => any][];
  actionsFunc?: () => [string, (record: RecordType) => any][];
}
