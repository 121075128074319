import DepartmentRecord from "./DepartmentRecord";

export default class ProductRecordComparer {
  private ascending: boolean;
  constructor(ascending: boolean) {
    this.ascending = ascending;
  }

  public Compare(a: DepartmentRecord, b: DepartmentRecord): number {
    if (a.newRowRecord) return -1;
    if (b.newRowRecord) return 1;
    if(a.department.standard) return -1;
    if(b.department.standard) return 1;

    if (a.newlyAdded && !b.newlyAdded) return -1;
    if (b.newlyAdded && !a.newlyAdded) return 1;

    let result = 1;
    if (b.department.title.toLowerCase() > a.department.title.toLowerCase()) {
      result = -1;
    }

    if (!this.ascending) {
      result *= -1;
    }

    return result;
  }
}
