
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { storeApi } from "@/services/ApiService";
import { Department } from "@/api/api";
import EditableCellTitle from "@/components/admin/editStore/departments/EditableCellTitle.vue";
import CellOperations from "@/components/admin/editStore/departments/CellOperations.vue";
import NewRow from "@/components/admin/editStore/departments/NewRow.vue";
import DepartmentRecordCompare from "@/components/admin/editStore/departments/DepartmentRecordCompare";
import DepartmentRecordFilter from "@/components/admin/editStore/departments/DepartmentRecordFilter";
import DepartmentRecord from "@/components/admin/editStore/departments/DepartmentRecord";

@Component({
  components: {
    EditableCellTitle,
    NewRow,
    CellOperations,
  },
})
export default class AdminDepartments extends Vue {
  loading: boolean = true;
  data: DepartmentRecord[] = [];
  filter: any;
  filterText!: string;
  filterUpdated: number = 0;
  pager: any = { pageSize: 50 };
  sorter: any;
  newRow: DepartmentRecord = this.getNewRow();

  columns = [
    {
      title: "Avdelning",
      sorter: true,
      defaultSortOrder: "ascend",
      customRender: (record: DepartmentRecord) => {
        if (record.newRowRecord && !record.rowEdit) {
          return {
            children: this.$createElement(NewRow, {
              on: {
                click: (e: any) => {
                  this.InitNewRow(record);
                },
              },
            }),
            attrs: {
              colSpan: 2,
              style: "padding: 0px;",
            },
          };
        } else {
          return {
            children: this.$createElement(EditableCellTitle, {
              props: {
                record: record,
              },
              on: {
                update: (title: string) => this.updateTitle(record, title),
              },
            }),
          };
        }
      },
    },
    {
      width: "80px",
      customRender: (record: DepartmentRecord) => {
        if (record.newRowRecord && !record.rowEdit) {
          return {
            attrs: {
              colSpan: 0,
            },
          };
        } else if (record.department.standard) {
          return {
            attrs: {
              colSpan: 1,
            },
          };
        } else {
          return {
            children: this.$createElement(CellOperations, {
              props: {
                record: record,
              },
              on: {
                accept: () => this.saveNewRow(record),
                cancel: () => this.cancelNewRow(record),
                delete: () => this.deleteDepartment(record),
              },
            }),
          };
        }
      },
    },
  ];

  public async mounted() {
    var result = await storeApi.getDepartmentsWeb(this.CurrentStore());
    let data: DepartmentRecord[] = new Array();
    result.data.forEach((c) => data.push(new DepartmentRecord(c, false)));
    this.data = data;
    this.loading = false;
    this.pager = { pageSize: 20, current: 1, total: this.data.length };
  }

  private getNewRow(): DepartmentRecord {
    return new DepartmentRecord({ id: -1, title: "", standard: false }, true);
  }

  public async saveNewRow(record: DepartmentRecord) {
    let saveTask = this.saveDepartment(record.department);
    record.rowEdit = false;
    record.newRowRecord = false;
    await saveTask;
    this.data.push(record);
    this.newRow = this.getNewRow();
  }

  public cancelNewRow(record: DepartmentRecord) {
    this.newRow = this.getNewRow();
    this.filterUpdated++;
  }
  public InitNewRow(record: DepartmentRecord) {
    record.rowEdit = true;
    this.filterUpdated++;
  }
  public CurrentStore(): number {
    return Number(this.$router.currentRoute.params["id"]);
  }

  public GetData() {
    let skip = this.pager.pageSize * (this.pager.current - 1);

    let filtered: DepartmentRecord[];

    let filterer = new DepartmentRecordFilter(this.filterText);
    filtered = [...this.data].filter((c) => filterer.Filter(c));

    let ascending = true;
    if (this.sorter && this.sorter.order == "descend") {
      ascending = false;
    }
    let comprarer = new DepartmentRecordCompare(ascending);
    filtered.sort((a: DepartmentRecord, b: DepartmentRecord) =>
      comprarer.Compare(a, b)
    );

    this.pager.total = filtered.length;
    var result = filtered.slice(skip, skip + this.pager.pageSize);

    result.splice(0, 0, this.newRow);
    if (result.length > this.pager.pageSize) {
      result.pop();
    }
    return result;
  }
  public handleAdd() {
    this.newRow.rowEdit = true;
    this.filterUpdated++;
  }
  public async updateTitle(record: DepartmentRecord, title: string) {
    record.department.title = title;
    if (record.department.id > 0) {
      await this.saveDepartment(record.department);
    }
  }
  private async saveDepartment(department: Department) {
    let result = (await storeApi.postDepartmentWeb(department,this.CurrentStore())).data;
    department.id = result.id;
    this.notifySave(result.title);
    this.$emit("updateRequired");
  }

  public onSearchChanged(e: any) {
    if (!e.data && e.srcElement._value.length == 1) {
      this.filterText = e.data;
      this.filterUpdated++;
    }
  }
  public onSearch(e: any) {
    this.filterText = e;
    this.filterUpdated++;
  }

  public onChange(pagination: any, filter: any, sorter: any) {
    this.sorter = sorter;
    this.pager = pagination;
    this.filter = filter;
    this.filterUpdated++;
  }

  public popupScroll() {}

  public notifySave(name: string) {
    this.$notification.open({
      message: "Sparade avdelning",
      description: name,
      placement: "bottomRight",
      duration: 4,
    });
  }

  public async deleteDepartment(record: DepartmentRecord) {
    if (record.department.standard) return;
    let index = this.data.findIndex(
      (c) => c.department.id == record.department.id
    );
    this.data.splice(index, 1);
    this.filterUpdated++;
    let result = await storeApi.deleteDepartmentWeb(record.department.id);
    this.$notification.open({
      message: "Avdelningen är borttagen",
      description: record.department.title,
      placement: "bottomRight",
      duration: 4,
    });
  }
}
