import { CategoryTypeEnum, DepartmentCategory, DepartmentCategoryGroup } from "@/api/api";
export default class DepartmentCategoryRecord {
  category: DepartmentCategory | undefined;
  group: DepartmentCategoryGroup | undefined;
  isGroup: boolean = false;

  constructor(category: DepartmentCategory | undefined, group: DepartmentCategoryGroup | undefined) {
    this.category = category;
    this.group = group;
    if (this.group)
      this.isGroup = true;
  }

  get id() {
    if (this.group)
      return this.group.id;
    else if (this.category)
      return this.category.id;
    return -1;
  }
  set id(id: number) {
    if (this.group)
      this.group.id = id;
    else if (this.category)
      this.category.id = id;
  }
  get sortOrder() {
    if (this.group)
      return this.group.sortOrder;
    else if (this.category)
      return this.category.sortOrder;
    return -1;
  }
  set sortOrder(sortOrder: number) {
    if (this.group)
      this.group.sortOrder = sortOrder;
    else if (this.category)
      this.category.sortOrder = sortOrder;
  }
  get name() {
    if (this.group)
      return this.group.name;
    else if (this.category)
      return this.category.name;
    return "Error";
  }
  set name(name: string) {
    if (this.group)
      this.group.name = name;
    else if (this.category)
      this.category.name = name;
  }
  get color(): string | undefined {
    if (this.group)
      return this.group.color;
    else if (this.category)
      return this.category.color;
  }
  set color(color: string | undefined) {
    if (this.group)
      this.group.color = color;
    else if (this.category)
      this.category.color = color;
  }
  get alias(): string | undefined {
    if (this.group)
      return this.group.name;
    else if (this.category)
      return this.category.alias;
  }
  get properties(): number{
    if(this.category)
      return this.category.properties;
    return 0;
  }
  set alias(alias: string | undefined) {
    if (this.group && alias)
      this.group.name = alias;
    else if (this.category)
      this.category.alias = alias;
  }
  get existsOnMultipleDepartments(): boolean {
    if (this.category)
      return this.category.existsOnMultipleDepartments;
    return false;
  }
  get contentCount(): number | undefined {
    if (this.category)
      return this.category.contentCount;
    return undefined;
  }
  get type(): CategoryTypeEnum {
    if (this.category)
      return this.category.type;
    return CategoryTypeEnum.NUMBER_0;
  }
  set type(type: CategoryTypeEnum) {
    if (this.category)
      this.category.type = type;
  }
  get displayType(): number {
    if (this.category)
      return this.category.displayType;
    return 0;
  }
  set displayType(type: number) {
    if (this.category)
      this.category.displayType = type;
  }
  set properties(value: number){
    if(this.category)
      this.category.properties = value;
  }
}
