
import Editor from "@tinymce/tinymce-vue";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { storeApi } from "@/services/ApiService";
// import category from "@/entities/Category";
import { Category } from "@/api/api";

@Component({ components: { Editor } })
export default class FreeTextContent extends Vue {
  editorContent = "";
  @Prop()
  category?: Category;

  async mounted() {
    let result = await storeApi.getCategoryHtml(this.category!.id);
    this.editorContent = result.data;
  }

  save() {
    return storeApi.postCategoryHtml(this.category!.id, this.editorContent);
  }
}
