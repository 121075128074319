/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Document on Azure Functions
 * This is the OpenAPI Document on Azure Functions
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Appcategorytaskscontract
 */
export interface Appcategorytaskscontract {
    /**
     * 
     * @type {number}
     * @memberof Appcategorytaskscontract
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Appcategorytaskscontract
     */
    'version'?: number;
    /**
     * 
     * @type {Array<Apptaskcontract>}
     * @memberof Appcategorytaskscontract
     */
    'tasks'?: Array<Apptaskcontract>;
}
/**
 * 
 * @export
 * @interface Apptaskcontract
 */
export interface Apptaskcontract {
    /**
     * 
     * @type {number}
     * @memberof Apptaskcontract
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Apptaskcontract
     */
    'text'?: string;
    /**
     * 
     * @type {number}
     * @memberof Apptaskcontract
     */
    'sort'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Apptaskcontract
     */
    'done'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Apptaskcontract
     */
    'visibledays'?: number;
    /**
     * 
     * @type {number}
     * @memberof Apptaskcontract
     */
    'properties'?: number;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    'type': CategoryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'defaultColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'selectedColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'pin'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    'defaultAlphabeticalGrouping'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    'defaultAlphabeticalOrder'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    'defaultExcludeInactive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    'defaultDisplayType'?: number;
    /**
     * 
     * @type {Array<IdText>}
     * @memberof Category
     */
    'departments'?: Array<IdText>;
}

export const CategoryTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9
} as const;

export type CategoryTypeEnum = typeof CategoryTypeEnum[keyof typeof CategoryTypeEnum];

/**
 * 
 * @export
 * @interface CategoryProduct
 */
export interface CategoryProduct {
    /**
     * 
     * @type {number}
     * @memberof CategoryProduct
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryProduct
     */
    'text'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryProduct
     */
    'sortOrder'?: number;
}
/**
 * 
 * @export
 * @interface CategoryTableField
 */
export interface CategoryTableField {
    /**
     * 
     * @type {number}
     * @memberof CategoryTableField
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryTableField
     */
    'productField'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryTableField
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoryTableField
     */
    'productUserDefinedFieldId'?: number;
}
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {number}
     * @memberof Contact
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface Creditcustomer
 */
export interface Creditcustomer {
    /**
     * 
     * @type {number}
     * @memberof Creditcustomer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Creditcustomer
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Creditcustomer
     */
    'number'?: string;
    /**
     * 
     * @type {number}
     * @memberof Creditcustomer
     */
    'sort': number;
    /**
     * 
     * @type {number}
     * @memberof Creditcustomer
     */
    'properties': number;
}
/**
 * 
 * @export
 * @interface DeletePdfRequest
 */
export interface DeletePdfRequest {
    /**
     * 
     * @type {number}
     * @memberof DeletePdfRequest
     */
    'categoryId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeletePdfRequest
     */
    'deletePdf'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeletePdfRequest
     */
    'deleteFuture'?: boolean;
}
/**
 * 
 * @export
 * @interface Department
 */
export interface Department {
    /**
     * 
     * @type {number}
     * @memberof Department
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof Department
     */
    'standard'?: boolean;
}
/**
 * 
 * @export
 * @interface DepartmentCategory
 */
export interface DepartmentCategory {
    /**
     * 
     * @type {number}
     * @memberof DepartmentCategory
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentCategory
     */
    'categoryId': number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCategory
     */
    'alias'?: string;
    /**
     * 
     * @type {number}
     * @memberof DepartmentCategory
     */
    'type': DepartmentCategoryTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DepartmentCategory
     */
    'displayType': number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCategory
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCategory
     */
    'selectedColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCategory
     */
    'pin'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentCategory
     */
    'alphabeticalGrouping'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentCategory
     */
    'alphabeticalOrder'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentCategory
     */
    'excludeInactive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentCategory
     */
    'existsOnMultipleDepartments': boolean;
    /**
     * 
     * @type {number}
     * @memberof DepartmentCategory
     */
    'parentDepartmentCategoryGroupId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentCategory
     */
    'departmentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentCategory
     */
    'sortOrder': number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentCategory
     */
    'contentCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentCategory
     */
    'properties': number;
}

export const DepartmentCategoryTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9
} as const;

export type DepartmentCategoryTypeEnum = typeof DepartmentCategoryTypeEnum[keyof typeof DepartmentCategoryTypeEnum];

/**
 * 
 * @export
 * @interface DepartmentCategoryGroup
 */
export interface DepartmentCategoryGroup {
    /**
     * 
     * @type {number}
     * @memberof DepartmentCategoryGroup
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCategoryGroup
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCategoryGroup
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof DepartmentCategoryGroup
     */
    'departmentId': number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentCategoryGroup
     */
    'sortOrder': number;
}
/**
 * 
 * @export
 * @interface DepartmentData
 */
export interface DepartmentData {
    /**
     * 
     * @type {Array<DepartmentCategory>}
     * @memberof DepartmentData
     */
    'categories': Array<DepartmentCategory>;
    /**
     * 
     * @type {Array<DepartmentCategoryGroup>}
     * @memberof DepartmentData
     */
    'categoryGroups': Array<DepartmentCategoryGroup>;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentData
     */
    'hasMultipleDepartments': boolean;
}
/**
 * 
 * @export
 * @interface DevTask
 */
export interface DevTask {
    /**
     * 
     * @type {number}
     * @memberof DevTask
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DevTask
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DevTask
     */
    'inProgress'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DevTask
     */
    'completed'?: string;
    /**
     * 
     * @type {number}
     * @memberof DevTask
     */
    'actualHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof DevTask
     */
    'estimatedHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof DevTask
     */
    'sortOrder'?: number;
}
/**
 * 
 * @export
 * @interface GetDevConfigurationsResponse
 */
export interface GetDevConfigurationsResponse {
    /**
     * 
     * @type {Array<MdmDevConfiguration>}
     * @memberof GetDevConfigurationsResponse
     */
    'configurations'?: Array<MdmDevConfiguration>;
}
/**
 * 
 * @export
 * @interface GetMdmStoresResponse
 */
export interface GetMdmStoresResponse {
    /**
     * 
     * @type {Array<IdText>}
     * @memberof GetMdmStoresResponse
     */
    'stores'?: Array<IdText>;
}
/**
 * 
 * @export
 * @interface GetPdfPreViewResponse
 */
export interface GetPdfPreViewResponse {
    /**
     * 
     * @type {number}
     * @memberof GetPdfPreViewResponse
     */
    'categoryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPdfPreViewResponse
     */
    'pdf'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfPreViewResponse
     */
    'unpublishedPdf'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfPreViewResponse
     */
    'publicationDate'?: string;
}
/**
 * 
 * @export
 * @interface GetStoresResponse
 */
export interface GetStoresResponse {
    /**
     * 
     * @type {Array<ResponseStore>}
     * @memberof GetStoresResponse
     */
    'stores'?: Array<ResponseStore>;
}
/**
 * 
 * @export
 * @interface GetTabletsResponse
 */
export interface GetTabletsResponse {
    /**
     * 
     * @type {Array<MdmTablet>}
     * @memberof GetTabletsResponse
     */
    'tablets'?: Array<MdmTablet>;
}
/**
 * 
 * @export
 * @interface IdText
 */
export interface IdText {
    /**
     * 
     * @type {number}
     * @memberof IdText
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdText
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'token'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResponse
     */
    'store'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'storetext'?: string;
}
/**
 * 
 * @export
 * @interface MdmDevConfiguration
 */
export interface MdmDevConfiguration {
    /**
     * 
     * @type {string}
     * @memberof MdmDevConfiguration
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MdmDevConfiguration
     */
    'id'?: number;
    /**
     * 
     * @type {IdText}
     * @memberof MdmDevConfiguration
     */
    'store'?: IdText;
}
/**
 * 
 * @export
 * @interface MdmTablet
 */
export interface MdmTablet {
    /**
     * 
     * @type {string}
     * @memberof MdmTablet
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MdmTablet
     */
    'online'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MdmTablet
     */
    'lastOnline'?: string;
    /**
     * 
     * @type {string}
     * @memberof MdmTablet
     */
    'power'?: string;
}
/**
 * 
 * @export
 * @interface MultiPartFormDataModel
 */
export interface MultiPartFormDataModel {
    /**
     * 
     * @type {File}
     * @memberof MultiPartFormDataModel
     */
    'fileUpload'?: File;
}
/**
 * 
 * @export
 * @interface PloginRequest
 */
export interface PloginRequest {
    /**
     * 
     * @type {string}
     * @memberof PloginRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof PloginRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface PloginResponse
 */
export interface PloginResponse {
    /**
     * 
     * @type {number}
     * @memberof PloginResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof PloginResponse
     */
    'redirect'?: string;
}
/**
 * 
 * @export
 * @interface PostDevConfigurationRequest
 */
export interface PostDevConfigurationRequest {
    /**
     * 
     * @type {number}
     * @memberof PostDevConfigurationRequest
     */
    'configurationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostDevConfigurationRequest
     */
    'storeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostDevConfigurationRequest
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface PostImageResponse
 */
export interface PostImageResponse {
    /**
     * 
     * @type {number}
     * @memberof PostImageResponse
     */
    'imageid'?: number;
}
/**
 * 
 * @export
 * @interface PostPdfRequest
 */
export interface PostPdfRequest {
    /**
     * 
     * @type {number}
     * @memberof PostPdfRequest
     */
    'categoryId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostPdfRequest
     */
    'pdf'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostPdfRequest
     */
    'futurePdf'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PostPdfRequest
     */
    'publishDate'?: string;
}
/**
 * 
 * @export
 * @interface PostStoreRequest
 */
export interface PostStoreRequest {
    /**
     * 
     * @type {number}
     * @memberof PostStoreRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostStoreRequest
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostStoreRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostStoreRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface PostStoreResponse
 */
export interface PostStoreResponse {
    /**
     * 
     * @type {number}
     * @memberof PostStoreResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostStoreResponse
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostStoreResponse
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostStoreResponse
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface PostTabletRequest
 */
export interface PostTabletRequest {
    /**
     * 
     * @type {number}
     * @memberof PostTabletRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostTabletRequest
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface ProcessImageResponse
 */
export interface ProcessImageResponse {
    /**
     * 
     * @type {number}
     * @memberof ProcessImageResponse
     */
    'imageid'?: number;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'barcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'plu'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'eco'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'inactive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'imageid'?: number;
    /**
     * 
     * @type {Array<IdText>}
     * @memberof Product
     */
    'categories'?: Array<IdText>;
    /**
     * 
     * @type {Array<ProductUserFieldData>}
     * @memberof Product
     */
    'userFields'?: Array<ProductUserFieldData>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'backgroundColor'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'bold'?: boolean;
}
/**
 * 
 * @export
 * @interface ProductUserField
 */
export interface ProductUserField {
    /**
     * 
     * @type {number}
     * @memberof ProductUserField
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductUserField
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ProductUserFieldData
 */
export interface ProductUserFieldData {
    /**
     * 
     * @type {number}
     * @memberof ProductUserFieldData
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductUserFieldData
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUserFieldData
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {number}
     * @memberof Resource
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'text'?: string;
    /**
     * 
     * @type {number}
     * @memberof Resource
     */
    'sort'?: number;
}
/**
 * 
 * @export
 * @interface ResponseStore
 */
export interface ResponseStore {
    /**
     * 
     * @type {number}
     * @memberof ResponseStore
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseStore
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseStore
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseStore
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface SignalRConnectionInfo
 */
export interface SignalRConnectionInfo {
    /**
     * 
     * @type {string}
     * @memberof SignalRConnectionInfo
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignalRConnectionInfo
     */
    'accessToken'?: string;
}
/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'sort': number;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'visibledays': number;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'resetdays': number;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'properties': number;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'lastdone'?: string;
}
/**
 * 
 * @export
 * @interface Taskcategory
 */
export interface Taskcategory {
    /**
     * 
     * @type {number}
     * @memberof Taskcategory
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Taskcategory
     */
    'text'?: string;
}

/**
 * AppApi - axios parameter creator
 * @export
 */
export const AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} categoryid The store to fetch stations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryTasks: async (categoryid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryid' is not null or undefined
            assertParamExists('getCategoryTasks', 'categoryid', categoryid)
            const localVarPath = `/client/GetCategoryTasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryid !== undefined) {
                localVarQueryParameter['categoryid'] = categoryid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignalrinfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/signalr/negotiate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskid 
         * @param {boolean} completed 
         * @param {string} sign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskStatus: async (taskid: number, completed: boolean, sign: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskid' is not null or undefined
            assertParamExists('updateTaskStatus', 'taskid', taskid)
            // verify required parameter 'completed' is not null or undefined
            assertParamExists('updateTaskStatus', 'completed', completed)
            // verify required parameter 'sign' is not null or undefined
            assertParamExists('updateTaskStatus', 'sign', sign)
            const localVarPath = `/client/tasks/updatetaskstatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (taskid !== undefined) {
                localVarQueryParameter['taskid'] = taskid;
            }

            if (completed !== undefined) {
                localVarQueryParameter['completed'] = completed;
            }

            if (sign !== undefined) {
                localVarQueryParameter['sign'] = sign;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} categoryid The store to fetch stations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryTasks(categoryid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Appcategorytaskscontract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryTasks(categoryid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignalrinfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignalRConnectionInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignalrinfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskid 
         * @param {boolean} completed 
         * @param {string} sign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaskStatus(taskid: number, completed: boolean, sign: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaskStatus(taskid, completed, sign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppApiFp(configuration)
    return {
        /**
         * 
         * @param {number} categoryid The store to fetch stations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryTasks(categoryid: number, options?: any): AxiosPromise<Appcategorytaskscontract> {
            return localVarFp.getCategoryTasks(categoryid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignalrinfo(options?: any): AxiosPromise<SignalRConnectionInfo> {
            return localVarFp.getSignalrinfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskid 
         * @param {boolean} completed 
         * @param {string} sign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskStatus(taskid: number, completed: boolean, sign: string, options?: any): AxiosPromise<string> {
            return localVarFp.updateTaskStatus(taskid, completed, sign, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI {
    /**
     * 
     * @param {number} categoryid The store to fetch stations for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getCategoryTasks(categoryid: number, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getCategoryTasks(categoryid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getSignalrinfo(options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).getSignalrinfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} taskid 
     * @param {boolean} completed 
     * @param {string} sign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public updateTaskStatus(taskid: number, completed: boolean, sign: string, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).updateTaskStatus(taskid, completed, sign, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (body: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('login', 'body', body)
            const localVarPath = `/web/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PloginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pLogin: async (body: PloginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('pLogin', 'body', body)
            const localVarPath = `/loginportal/plogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(body: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PloginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pLogin(body: PloginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PloginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pLogin(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * 
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: LoginRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.login(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PloginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pLogin(body: PloginRequest, options?: any): AxiosPromise<PloginResponse> {
            return localVarFp.pLogin(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * 
     * @param {LoginRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public login(body: LoginRequest, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).login(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PloginRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public pLogin(body: PloginRequest, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).pLogin(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoreApi - axios parameter creator
 * @export
 */
export const StoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCategory', 'id', id)
            const localVarPath = `/web/deletecategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} contactid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact: async (contactid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactid' is not null or undefined
            assertParamExists('deleteContact', 'contactid', contactid)
            const localVarPath = `/web/deletecontact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contactid !== undefined) {
                localVarQueryParameter['contactid'] = contactid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} categoryid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreditCustomer: async (id: number, categoryid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCreditCustomer', 'id', id)
            // verify required parameter 'categoryid' is not null or undefined
            assertParamExists('deleteCreditCustomer', 'categoryid', categoryid)
            const localVarPath = `/web/deletecreditcustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (categoryid !== undefined) {
                localVarQueryParameter['categoryid'] = categoryid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentCategoryWeb: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDepartmentCategoryWeb', 'id', id)
            const localVarPath = `/web/deletedepartmentcategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentGroupWeb: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDepartmentGroupWeb', 'id', id)
            const localVarPath = `/web/deletedepartmentgroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentWeb: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDepartmentWeb', 'id', id)
            const localVarPath = `/web/deletedepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} imageid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage: async (imageid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageid' is not null or undefined
            assertParamExists('deleteImage', 'imageid', imageid)
            const localVarPath = `/web/removeimage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (imageid !== undefined) {
                localVarQueryParameter['imageid'] = imageid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePdfRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePdf: async (body: DeletePdfRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('deletePdf', 'body', body)
            const localVarPath = `/web/deletepdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProduct', 'id', id)
            const localVarPath = `/web/deleteproduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} categoryid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTask: async (id: number, categoryid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTask', 'id', id)
            // verify required parameter 'categoryid' is not null or undefined
            assertParamExists('deleteTask', 'categoryid', categoryid)
            const localVarPath = `/web/deletetask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (categoryid !== undefined) {
                localVarQueryParameter['categoryid'] = categoryid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web/getcategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web/getcategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryHtml: async (categoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getCategoryHtml', 'categoryId', categoryId)
            const localVarPath = `/web/getcategoryhtml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryProducts: async (categoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getCategoryProducts', 'categoryId', categoryId)
            const localVarPath = `/web/getcategoryproducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryTable: async (categoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getCategoryTable', 'categoryId', categoryId)
            const localVarPath = `/web/getcategorytable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryWebPage: async (categoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getCategoryWebPage', 'categoryId', categoryId)
            const localVarPath = `/web/getcategorywebpage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [storeid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts: async (storeid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web/getcontacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeid !== undefined) {
                localVarQueryParameter['storeid'] = storeid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditCustomers: async (categoryid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryid' is not null or undefined
            assertParamExists('getCreditCustomers', 'categoryid', categoryid)
            const localVarPath = `/web/getcreditcustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryid !== undefined) {
                localVarQueryParameter['categoryid'] = categoryid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentDataWeb: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDepartmentDataWeb', 'id', id)
            const localVarPath = `/web/department-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [storeid] The store to fetch stations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentsWeb: async (storeid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web/getdepartments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeid !== undefined) {
                localVarQueryParameter['storeid'] = storeid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} imageid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage: async (imageid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageid' is not null or undefined
            assertParamExists('getImage', 'imageid', imageid)
            const localVarPath = `/web/getimage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (imageid !== undefined) {
                localVarQueryParameter['imageid'] = imageid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfPreview: async (categoryid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryid' is not null or undefined
            assertParamExists('getPdfPreview', 'categoryid', categoryid)
            const localVarPath = `/web/getpdfpreview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryid !== undefined) {
                localVarQueryParameter['categoryid'] = categoryid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductUserFields: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web/getproductuserfields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web/getproducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleResources: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web/getscheduleresources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web/gettaskcategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks: async (categoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getTasks', 'categoryId', categoryId)
            const localVarPath = `/web/gettasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} imageid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThumbnail: async (imageid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageid' is not null or undefined
            assertParamExists('getThumbnail', 'imageid', imageid)
            const localVarPath = `/web/getthumbnail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (imageid !== undefined) {
                localVarQueryParameter['imageid'] = imageid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {number} sourceId 
         * @param {number} targetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveCreditCustomer: async (categoryId: number, sourceId: number, targetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('moveCreditCustomer', 'categoryId', categoryId)
            // verify required parameter 'sourceId' is not null or undefined
            assertParamExists('moveCreditCustomer', 'sourceId', sourceId)
            // verify required parameter 'targetId' is not null or undefined
            assertParamExists('moveCreditCustomer', 'targetId', targetId)
            const localVarPath = `/web/movecreditcustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (sourceId !== undefined) {
                localVarQueryParameter['sourceId'] = sourceId;
            }

            if (targetId !== undefined) {
                localVarQueryParameter['targetId'] = targetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Category} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategory: async (body: Category, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postCategory', 'body', body)
            const localVarPath = `/web/postcategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategoryHtml: async (categoryId: number, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('postCategoryHtml', 'categoryId', categoryId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postCategoryHtml', 'body', body)
            const localVarPath = `/web/postcategoryhtml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {Array<CategoryProduct>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategoryProducts: async (categoryId: number, body: Array<CategoryProduct>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('postCategoryProducts', 'categoryId', categoryId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postCategoryProducts', 'body', body)
            const localVarPath = `/web/postcategoryproducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {Array<CategoryTableField>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategoryTable: async (categoryId: number, body: Array<CategoryTableField>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('postCategoryTable', 'categoryId', categoryId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postCategoryTable', 'body', body)
            const localVarPath = `/web/postcategorytable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategoryWebPage: async (categoryId: number, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('postCategoryWebPage', 'categoryId', categoryId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postCategoryWebPage', 'body', body)
            const localVarPath = `/web/postcategorywebpage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Contact} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContact: async (body: Contact, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postContact', 'body', body)
            const localVarPath = `/post/postcontact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DepartmentCategoryGroup} body 
         * @param {number} [storeid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDepartmentCategoryGroupWeb: async (body: DepartmentCategoryGroup, storeid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postDepartmentCategoryGroupWeb', 'body', body)
            const localVarPath = `/web/postdepartmentcategorygroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeid !== undefined) {
                localVarQueryParameter['storeid'] = storeid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DepartmentCategory} body 
         * @param {number} [storeid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDepartmentCategoryWeb: async (body: DepartmentCategory, storeid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postDepartmentCategoryWeb', 'body', body)
            const localVarPath = `/web/postdepartmentcategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeid !== undefined) {
                localVarQueryParameter['storeid'] = storeid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Department} body 
         * @param {number} [storeid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDepartmentWeb: async (body: Department, storeid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postDepartmentWeb', 'body', body)
            const localVarPath = `/web/postdepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeid !== undefined) {
                localVarQueryParameter['storeid'] = storeid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} [fileUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImage: async (fileUpload?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/web/postimage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (fileUpload !== undefined) { 
                localVarFormParams.append('fileUpload', fileUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostPdfRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPdf: async (body: PostPdfRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postPdf', 'body', body)
            const localVarPath = `/web/postpdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Product} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProduct: async (body: Product, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postProduct', 'body', body)
            const localVarPath = `/web/postproduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<ProductUserField>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductUserFields: async (body: Array<ProductUserField>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postProductUserFields', 'body', body)
            const localVarPath = `/web/postproductuserfields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<Product>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProducts: async (body: Array<Product>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postProducts', 'body', body)
            const localVarPath = `/web/postproducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productid 
         * @param {File} [fileUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processImage: async (productid: number, fileUpload?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productid' is not null or undefined
            assertParamExists('processImage', 'productid', productid)
            const localVarPath = `/web/processimage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (productid !== undefined) {
                localVarQueryParameter['productid'] = productid;
            }


            if (fileUpload !== undefined) { 
                localVarFormParams.append('fileUpload', fileUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryid 
         * @param {Array<Creditcustomer>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCreditCustomers: async (categoryid: number, body: Array<Creditcustomer>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryid' is not null or undefined
            assertParamExists('updateCreditCustomers', 'categoryid', categoryid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCreditCustomers', 'body', body)
            const localVarPath = `/web/updatecreditcustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryid !== undefined) {
                localVarQueryParameter['categoryid'] = categoryid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryid 
         * @param {Array<Task>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTasks: async (categoryid: number, body: Array<Task>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryid' is not null or undefined
            assertParamExists('updateTasks', 'categoryid', categoryid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateTasks', 'body', body)
            const localVarPath = `/web/updatetasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryid !== undefined) {
                localVarQueryParameter['categoryid'] = categoryid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreApi - functional programming interface
 * @export
 */
export const StoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCategory(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} contactid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContact(contactid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContact(contactid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} categoryid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCreditCustomer(id: number, categoryid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCreditCustomer(id, categoryid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDepartmentCategoryWeb(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartmentCategoryWeb(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDepartmentGroupWeb(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartmentGroupWeb(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDepartmentWeb(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartmentWeb(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} imageid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImage(imageid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImage(imageid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeletePdfRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePdf(body: DeletePdfRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePdf(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} categoryid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTask(id: number, categoryid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTask(id, categoryid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategory(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryHtml(categoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryHtml(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryProducts(categoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryProducts(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryTable(categoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryTableField>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryTable(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryWebPage(categoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryWebPage(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [storeid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContacts(storeid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contact>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContacts(storeid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreditCustomers(categoryid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Creditcustomer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreditCustomers(categoryid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentDataWeb(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentDataWeb(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [storeid] The store to fetch stations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentsWeb(storeid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Department>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentsWeb(storeid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} imageid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImage(imageid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImage(imageid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPdfPreview(categoryid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPdfPreViewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPdfPreview(categoryid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductUserFields(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductUserField>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductUserFields(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduleResources(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Resource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduleResources(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Taskcategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTasks(categoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Task>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTasks(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} imageid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThumbnail(imageid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThumbnail(imageid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {number} sourceId 
         * @param {number} targetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveCreditCustomer(categoryId: number, sourceId: number, targetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveCreditCustomer(categoryId, sourceId, targetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Category} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCategory(body: Category, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCategory(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCategoryHtml(categoryId: number, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCategoryHtml(categoryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {Array<CategoryProduct>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCategoryProducts(categoryId: number, body: Array<CategoryProduct>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCategoryProducts(categoryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {Array<CategoryTableField>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCategoryTable(categoryId: number, body: Array<CategoryTableField>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCategoryTable(categoryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCategoryWebPage(categoryId: number, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCategoryWebPage(categoryId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Contact} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postContact(body: Contact, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postContact(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DepartmentCategoryGroup} body 
         * @param {number} [storeid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDepartmentCategoryGroupWeb(body: DepartmentCategoryGroup, storeid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentCategoryGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDepartmentCategoryGroupWeb(body, storeid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DepartmentCategory} body 
         * @param {number} [storeid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDepartmentCategoryWeb(body: DepartmentCategory, storeid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDepartmentCategoryWeb(body, storeid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Department} body 
         * @param {number} [storeid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDepartmentWeb(body: Department, storeid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Department>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDepartmentWeb(body, storeid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} [fileUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postImage(fileUpload?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postImage(fileUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostPdfRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPdf(body: PostPdfRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPdf(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Product} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProduct(body: Product, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProduct(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<ProductUserField>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProductUserFields(body: Array<ProductUserField>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductUserField>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProductUserFields(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<Product>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProducts(body: Array<Product>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProducts(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productid 
         * @param {File} [fileUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processImage(productid: number, fileUpload?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processImage(productid, fileUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryid 
         * @param {Array<Creditcustomer>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCreditCustomers(categoryid: number, body: Array<Creditcustomer>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Creditcustomer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCreditCustomers(categoryid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryid 
         * @param {Array<Task>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTasks(categoryid: number, body: Array<Task>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Task>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTasks(categoryid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreApi - factory interface
 * @export
 */
export const StoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} contactid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact(contactid: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContact(contactid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} categoryid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreditCustomer(id: number, categoryid: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCreditCustomer(id, categoryid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentCategoryWeb(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDepartmentCategoryWeb(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentGroupWeb(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDepartmentGroupWeb(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentWeb(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDepartmentWeb(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} imageid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(imageid: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteImage(imageid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePdfRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePdf(body: DeletePdfRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.deletePdf(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} categoryid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTask(id: number, categoryid: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTask(id, categoryid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(options?: any): AxiosPromise<Array<Category>> {
            return localVarFp.getCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory(id?: number, options?: any): AxiosPromise<Category> {
            return localVarFp.getCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryHtml(categoryId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getCategoryHtml(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryProducts(categoryId: number, options?: any): AxiosPromise<Array<CategoryProduct>> {
            return localVarFp.getCategoryProducts(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryTable(categoryId: number, options?: any): AxiosPromise<Array<CategoryTableField>> {
            return localVarFp.getCategoryTable(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryWebPage(categoryId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getCategoryWebPage(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [storeid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts(storeid?: number, options?: any): AxiosPromise<Array<Contact>> {
            return localVarFp.getContacts(storeid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditCustomers(categoryid: number, options?: any): AxiosPromise<Array<Creditcustomer>> {
            return localVarFp.getCreditCustomers(categoryid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentDataWeb(id: number, options?: any): AxiosPromise<DepartmentData> {
            return localVarFp.getDepartmentDataWeb(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [storeid] The store to fetch stations for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentsWeb(storeid?: number, options?: any): AxiosPromise<Array<Department>> {
            return localVarFp.getDepartmentsWeb(storeid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} imageid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(imageid: number, options?: any): AxiosPromise<File> {
            return localVarFp.getImage(imageid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfPreview(categoryid: number, options?: any): AxiosPromise<GetPdfPreViewResponse> {
            return localVarFp.getPdfPreview(categoryid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductUserFields(options?: any): AxiosPromise<Array<ProductUserField>> {
            return localVarFp.getProductUserFields(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.getProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleResources(options?: any): AxiosPromise<Array<Resource>> {
            return localVarFp.getScheduleResources(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskCategories(options?: any): AxiosPromise<Array<Taskcategory>> {
            return localVarFp.getTaskCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks(categoryId: number, options?: any): AxiosPromise<Array<Task>> {
            return localVarFp.getTasks(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} imageid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThumbnail(imageid: number, options?: any): AxiosPromise<File> {
            return localVarFp.getThumbnail(imageid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {number} sourceId 
         * @param {number} targetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveCreditCustomer(categoryId: number, sourceId: number, targetId: number, options?: any): AxiosPromise<void> {
            return localVarFp.moveCreditCustomer(categoryId, sourceId, targetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Category} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategory(body: Category, options?: any): AxiosPromise<Category> {
            return localVarFp.postCategory(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategoryHtml(categoryId: number, body: string, options?: any): AxiosPromise<string> {
            return localVarFp.postCategoryHtml(categoryId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {Array<CategoryProduct>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategoryProducts(categoryId: number, body: Array<CategoryProduct>, options?: any): AxiosPromise<Array<CategoryProduct>> {
            return localVarFp.postCategoryProducts(categoryId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {Array<CategoryTableField>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategoryTable(categoryId: number, body: Array<CategoryTableField>, options?: any): AxiosPromise<string> {
            return localVarFp.postCategoryTable(categoryId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategoryWebPage(categoryId: number, body: string, options?: any): AxiosPromise<string> {
            return localVarFp.postCategoryWebPage(categoryId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Contact} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContact(body: Contact, options?: any): AxiosPromise<Contact> {
            return localVarFp.postContact(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentCategoryGroup} body 
         * @param {number} [storeid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDepartmentCategoryGroupWeb(body: DepartmentCategoryGroup, storeid?: number, options?: any): AxiosPromise<DepartmentCategoryGroup> {
            return localVarFp.postDepartmentCategoryGroupWeb(body, storeid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentCategory} body 
         * @param {number} [storeid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDepartmentCategoryWeb(body: DepartmentCategory, storeid?: number, options?: any): AxiosPromise<DepartmentCategory> {
            return localVarFp.postDepartmentCategoryWeb(body, storeid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Department} body 
         * @param {number} [storeid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDepartmentWeb(body: Department, storeid?: number, options?: any): AxiosPromise<Department> {
            return localVarFp.postDepartmentWeb(body, storeid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} [fileUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImage(fileUpload?: File, options?: any): AxiosPromise<PostImageResponse> {
            return localVarFp.postImage(fileUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostPdfRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPdf(body: PostPdfRequest, options?: any): AxiosPromise<Category> {
            return localVarFp.postPdf(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Product} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProduct(body: Product, options?: any): AxiosPromise<Product> {
            return localVarFp.postProduct(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<ProductUserField>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductUserFields(body: Array<ProductUserField>, options?: any): AxiosPromise<Array<ProductUserField>> {
            return localVarFp.postProductUserFields(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<Product>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProducts(body: Array<Product>, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.postProducts(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productid 
         * @param {File} [fileUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processImage(productid: number, fileUpload?: File, options?: any): AxiosPromise<ProcessImageResponse> {
            return localVarFp.processImage(productid, fileUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryid 
         * @param {Array<Creditcustomer>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCreditCustomers(categoryid: number, body: Array<Creditcustomer>, options?: any): AxiosPromise<Array<Creditcustomer>> {
            return localVarFp.updateCreditCustomers(categoryid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryid 
         * @param {Array<Task>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTasks(categoryid: number, body: Array<Task>, options?: any): AxiosPromise<Array<Task>> {
            return localVarFp.updateTasks(categoryid, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreApi - object-oriented interface
 * @export
 * @class StoreApi
 * @extends {BaseAPI}
 */
export class StoreApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public deleteCategory(id: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).deleteCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} contactid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public deleteContact(contactid: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).deleteContact(contactid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} categoryid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public deleteCreditCustomer(id: number, categoryid: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).deleteCreditCustomer(id, categoryid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public deleteDepartmentCategoryWeb(id: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).deleteDepartmentCategoryWeb(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public deleteDepartmentGroupWeb(id: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).deleteDepartmentGroupWeb(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public deleteDepartmentWeb(id: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).deleteDepartmentWeb(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} imageid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public deleteImage(imageid: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).deleteImage(imageid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePdfRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public deletePdf(body: DeletePdfRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).deletePdf(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public deleteProduct(id: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).deleteProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} categoryid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public deleteTask(id: number, categoryid: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).deleteTask(id, categoryid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getCategories(options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getCategory(id?: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getCategoryHtml(categoryId: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getCategoryHtml(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getCategoryProducts(categoryId: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getCategoryProducts(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getCategoryTable(categoryId: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getCategoryTable(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getCategoryWebPage(categoryId: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getCategoryWebPage(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [storeid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getContacts(storeid?: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getContacts(storeid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getCreditCustomers(categoryid: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getCreditCustomers(categoryid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getDepartmentDataWeb(id: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getDepartmentDataWeb(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [storeid] The store to fetch stations for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getDepartmentsWeb(storeid?: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getDepartmentsWeb(storeid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} imageid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getImage(imageid: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getImage(imageid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getPdfPreview(categoryid: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getPdfPreview(categoryid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getProductUserFields(options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getProductUserFields(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getProducts(options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getScheduleResources(options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getScheduleResources(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getTaskCategories(options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getTaskCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getTasks(categoryId: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getTasks(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} imageid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getThumbnail(imageid: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getThumbnail(imageid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {number} sourceId 
     * @param {number} targetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public moveCreditCustomer(categoryId: number, sourceId: number, targetId: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).moveCreditCustomer(categoryId, sourceId, targetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Category} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public postCategory(body: Category, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).postCategory(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public postCategoryHtml(categoryId: number, body: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).postCategoryHtml(categoryId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {Array<CategoryProduct>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public postCategoryProducts(categoryId: number, body: Array<CategoryProduct>, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).postCategoryProducts(categoryId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {Array<CategoryTableField>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public postCategoryTable(categoryId: number, body: Array<CategoryTableField>, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).postCategoryTable(categoryId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public postCategoryWebPage(categoryId: number, body: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).postCategoryWebPage(categoryId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Contact} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public postContact(body: Contact, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).postContact(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentCategoryGroup} body 
     * @param {number} [storeid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public postDepartmentCategoryGroupWeb(body: DepartmentCategoryGroup, storeid?: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).postDepartmentCategoryGroupWeb(body, storeid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentCategory} body 
     * @param {number} [storeid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public postDepartmentCategoryWeb(body: DepartmentCategory, storeid?: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).postDepartmentCategoryWeb(body, storeid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Department} body 
     * @param {number} [storeid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public postDepartmentWeb(body: Department, storeid?: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).postDepartmentWeb(body, storeid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} [fileUpload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public postImage(fileUpload?: File, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).postImage(fileUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostPdfRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public postPdf(body: PostPdfRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).postPdf(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Product} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public postProduct(body: Product, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).postProduct(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<ProductUserField>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public postProductUserFields(body: Array<ProductUserField>, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).postProductUserFields(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<Product>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public postProducts(body: Array<Product>, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).postProducts(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productid 
     * @param {File} [fileUpload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public processImage(productid: number, fileUpload?: File, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).processImage(productid, fileUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryid 
     * @param {Array<Creditcustomer>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public updateCreditCustomers(categoryid: number, body: Array<Creditcustomer>, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).updateCreditCustomers(categoryid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryid 
     * @param {Array<Task>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public updateTasks(categoryid: number, body: Array<Task>, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).updateTasks(categoryid, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SysadminApi - axios parameter creator
 * @export
 */
export const SysadminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStore: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteStore', 'id', id)
            const localVarPath = `/sysadmin/deletestore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevConfigurations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sysadmin/getDevConfigurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevTasks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sysadmin/getDevTasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMdmStores: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sysadmin/getMdmStores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStore: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getStore', 'id', id)
            const localVarPath = `/sysadmin/getStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStores: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sysadmin/getstores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} tabletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTabletQR: async (storeId: number, tabletId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getTabletQR', 'storeId', storeId)
            // verify required parameter 'tabletId' is not null or undefined
            assertParamExists('getTabletQR', 'tabletId', tabletId)
            const localVarPath = `/sysadmin/getTabletQR`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (tabletId !== undefined) {
                localVarQueryParameter['tabletId'] = tabletId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTablets: async (storeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getTablets', 'storeId', storeId)
            const localVarPath = `/sysadmin/getTablets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostDevConfigurationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDevConfiguration: async (body: PostDevConfigurationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postDevConfiguration', 'body', body)
            const localVarPath = `/sysadmin/postDevConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DevTask} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDevTask: async (body: DevTask, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postDevTask', 'body', body)
            const localVarPath = `/sysadmin/postDevTask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostStoreRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStore: async (body: PostStoreRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postStore', 'body', body)
            const localVarPath = `/sysadmin/poststore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} storeId 
         * @param {PostTabletRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTablet: async (storeId: number, body: PostTabletRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('postTablet', 'storeId', storeId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postTablet', 'body', body)
            const localVarPath = `/sysadmin/posttablet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SysadminApi - functional programming interface
 * @export
 */
export const SysadminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SysadminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStore(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStore(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevConfigurations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDevConfigurationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevConfigurations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevTasks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DevTask>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevTasks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMdmStores(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMdmStoresResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMdmStores(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStore(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStore(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStores(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStoresResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStores(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} tabletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTabletQR(storeId: number, tabletId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTabletQR(storeId, tabletId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTablets(storeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTabletsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTablets(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostDevConfigurationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDevConfiguration(body: PostDevConfigurationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MdmTablet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDevConfiguration(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DevTask} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDevTask(body: DevTask, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDevTask(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostStoreRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStore(body: PostStoreRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostStoreResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStore(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} storeId 
         * @param {PostTabletRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTablet(storeId: number, body: PostTabletRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MdmTablet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTablet(storeId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SysadminApi - factory interface
 * @export
 */
export const SysadminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SysadminApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStore(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteStore(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevConfigurations(options?: any): AxiosPromise<GetDevConfigurationsResponse> {
            return localVarFp.getDevConfigurations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevTasks(options?: any): AxiosPromise<Array<DevTask>> {
            return localVarFp.getDevTasks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMdmStores(options?: any): AxiosPromise<GetMdmStoresResponse> {
            return localVarFp.getMdmStores(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStore(id: number, options?: any): AxiosPromise<ResponseStore> {
            return localVarFp.getStore(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStores(options?: any): AxiosPromise<GetStoresResponse> {
            return localVarFp.getStores(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId 
         * @param {number} tabletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTabletQR(storeId: number, tabletId: number, options?: any): AxiosPromise<object> {
            return localVarFp.getTabletQR(storeId, tabletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTablets(storeId: number, options?: any): AxiosPromise<GetTabletsResponse> {
            return localVarFp.getTablets(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostDevConfigurationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDevConfiguration(body: PostDevConfigurationRequest, options?: any): AxiosPromise<MdmTablet> {
            return localVarFp.postDevConfiguration(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DevTask} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDevTask(body: DevTask, options?: any): AxiosPromise<DevTask> {
            return localVarFp.postDevTask(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostStoreRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStore(body: PostStoreRequest, options?: any): AxiosPromise<PostStoreResponse> {
            return localVarFp.postStore(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} storeId 
         * @param {PostTabletRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTablet(storeId: number, body: PostTabletRequest, options?: any): AxiosPromise<MdmTablet> {
            return localVarFp.postTablet(storeId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SysadminApi - object-oriented interface
 * @export
 * @class SysadminApi
 * @extends {BaseAPI}
 */
export class SysadminApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SysadminApi
     */
    public deleteStore(id: number, options?: AxiosRequestConfig) {
        return SysadminApiFp(this.configuration).deleteStore(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SysadminApi
     */
    public getDevConfigurations(options?: AxiosRequestConfig) {
        return SysadminApiFp(this.configuration).getDevConfigurations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SysadminApi
     */
    public getDevTasks(options?: AxiosRequestConfig) {
        return SysadminApiFp(this.configuration).getDevTasks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SysadminApi
     */
    public getMdmStores(options?: AxiosRequestConfig) {
        return SysadminApiFp(this.configuration).getMdmStores(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SysadminApi
     */
    public getStore(id: number, options?: AxiosRequestConfig) {
        return SysadminApiFp(this.configuration).getStore(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SysadminApi
     */
    public getStores(options?: AxiosRequestConfig) {
        return SysadminApiFp(this.configuration).getStores(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId 
     * @param {number} tabletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SysadminApi
     */
    public getTabletQR(storeId: number, tabletId: number, options?: AxiosRequestConfig) {
        return SysadminApiFp(this.configuration).getTabletQR(storeId, tabletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SysadminApi
     */
    public getTablets(storeId: number, options?: AxiosRequestConfig) {
        return SysadminApiFp(this.configuration).getTablets(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostDevConfigurationRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SysadminApi
     */
    public postDevConfiguration(body: PostDevConfigurationRequest, options?: AxiosRequestConfig) {
        return SysadminApiFp(this.configuration).postDevConfiguration(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DevTask} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SysadminApi
     */
    public postDevTask(body: DevTask, options?: AxiosRequestConfig) {
        return SysadminApiFp(this.configuration).postDevTask(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostStoreRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SysadminApi
     */
    public postStore(body: PostStoreRequest, options?: AxiosRequestConfig) {
        return SysadminApiFp(this.configuration).postStore(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} storeId 
     * @param {PostTabletRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SysadminApi
     */
    public postTablet(storeId: number, body: PostTabletRequest, options?: AxiosRequestConfig) {
        return SysadminApiFp(this.configuration).postTablet(storeId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


