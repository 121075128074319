
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import * as ST from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import { storeApi } from "@/services/ApiService";
import { Category, Creditcustomer } from "@/api/api";
import TextEdit from "@/components/controls/text-edit.vue";
import CreditCustomer from "@/views/store/creditCustomer.vue";
import itemPickerV2 from "@/components/controls/itemPickerV2.vue";
import { DisplayMode, IsNewRow } from "@/components/controls/s-table/exports";
import ItemPickerDialog from "@/components/controls/dialogs/ItemPicker.vue";
import CreditCustomerAdapter, {
  AdapterCreditCustomerPropertyType,
} from "@/entities/CreditCustomerPropertyAdapter";

@Component({ components: { ItemPickerDialog, STable } })
export default class CreditCustomerContent extends Vue {
  @Prop()
  category!: Category;
  @Prop()
  moveItemDialogVisible = false;
  moveItemDialogData = new Array<any>();
  moveItemDialogDisplayProperty = "text";
  moveItemDialogTitle = "Flytta rad till";
  moveItemDialogColumnTitle = "Rubrik";
  moveItemDialogCurrentRecord?: Creditcustomer;
  dataSource: Creditcustomer[] = [];

  tableSettings: ST.Settings<Creditcustomer> = {
    dataSource: this.dataSource,
    pagination: true,
    showNewRow: true,
    sort: {
      sortProperty: "sort",
      enableSort: true,
    },
    filter: {
      filterString: "",
      filterProperties: ["text", "number"],
    },
    newRowText: "Lägg till en ny rad",
    recordsUpdated: async (records) => {
      var result = await storeApi.updateCreditCustomers(
        this.category.id,
        records
      );
      if (records.length == 1 && records[0].id == -1) {
        records[0].id = result.data[0].id;
      }

      this.notify("Rad sparad.", records[0].text ?? "");
    },
    newRowInit: () => this.initNewRow(),
    loading: true,
    actions: {
      remove: {
        icon: "delete",
        confirmation: (record) =>
          `Är du säker på att du vill ta bort ${record.text}`,
        action: async (record) => {
          var recordIndex = this.dataSource.indexOf(record, 0);
          if (recordIndex > -1) {
            this.dataSource.splice(recordIndex, 1);
          }
          await storeApi.deleteCreditCustomer(record.id, this.category.id);
          this.notify("Rad borttagen.", record.text ?? "");
        },
      },
      move: {
        icon: "column-height",
        action: (record) => {
          this.setCurrentRecord(record);
          this.moveItemDialogVisible = true;
        },
        tooltip: (record) => `Flytta ${record.text}`,
        visible: (record) =>
          !IsNewRow(record) &&
          record.properties < 2 &&
          !this.category.defaultAlphabeticalOrder &&
          !this.category.defaultAlphabeticalGrouping &&
          this.moveItemDialogData.length > 0,
      },
    },
    columns: [
      {
        title: "Namn",
        edit: TextEdit,
        width: 500,
        editData: (record) => ({
          editable: true,
          dataBinding: {
            propertyName: "text",
          },
          updateEmitter: "valueChanged",
          props: {
            defaultValue: record.text,
          },
        }),
      },
      {
        title: "Nummer",
        edit: TextEdit,
        width: 500,
        editData: (record) => ({
          editable: true,
          dataBinding: {
            propertyName: "number",
          },
          updateEmitter: "valueChanged",
          props: {
            defaultValue: record.number,
          },
        }),
      },
      {
        title: "Egenskaper",
        edit: itemPickerV2,
        width: 400,
        editData: (record) => ({
          editable: true,
          dataBinding: {
            propertyName: "properties",
            formatDisplayValue: (value) =>
              CreditCustomerAdapter.enumToBits()
                .filter((v) => (value & v) === v)
                .map((k) =>
                  CreditCustomerAdapter.fromCreditCustomerPropertyType(k)
                ),
            parseEditValue: (value: number[]) =>
              value.reduce((sum, current) => sum + current, 0),
          },
          updateEmitter: "valueChanged",
          displayMode: DisplayMode.ATag,
          props: {
            dataSource: [
              { id: 1, text: "Fetstil" },
              { id: 2, text: "Rubrik" },
            ],
            defaultValue: CreditCustomerAdapter.enumToBits().filter(
              (v) => (record.properties & v) === v
            ),
            customKey: "id",
            customDisplay: "text",
            placeHolder: "Välj typ",
            selectionMode: "multiple",
          },
        }),
      },
    ],
  };

  async mounted() {
    await this.fillDataSource();
    this.dataSource
      .sort((c, d) => c.sort - d.sort)
      .forEach((element) => {
        if (element.properties > 1) {
          this.moveItemDialogData.push(element);
        }
      });
    this.tableSettings.loading = false;
  }
  async fillDataSource() {
    var result = await storeApi.getCreditCustomers(this.category.id);
    this.dataSource.push(...result.data);
  }
  setCurrentRecord(record: Creditcustomer) {
    this.moveItemDialogCurrentRecord = record;
  }
  onSearch(e: any) {
    if (!this.tableSettings.filter) return;

    if (e.srcElement.value) {
      this.tableSettings.filter.filterString = e.srcElement.value;
      this.tableSettings.sort!.enableSort = false;
    } else {
      this.tableSettings.filter.filterString = "";
      this.tableSettings.sort!.enableSort = true;
    }
  }

  public initNewRow(): Creditcustomer {
    return {
      text: "",
      id: -1,
      sort: this.getMaxSort(),
      number: "",
      properties: 0,
    };
  }
  public async moveOk(targetRecord: Creditcustomer) {
    if (!this.moveItemDialogCurrentRecord) return;
    var moveTask = storeApi.moveCreditCustomer(
      this.category.id,
      this.moveItemDialogCurrentRecord.id,
      targetRecord.id
    );
    this.tableSettings.loading = true;
    this.moveItemDialogVisible = false;
    var x = await moveTask;
    if (x.status == 200) {
      this.dataSource.length = 0;
      await this.fillDataSource();
      this.tableSettings.loading = false;
    }
  }
  public async moveCancel() {
    this.moveItemDialogVisible = false;
  }
  public chkChangedSort(e: any) {
    if (this.category) {
      this.category.defaultAlphabeticalOrder = e.target.checked;
    }
  }
  public getMaxSort(): number {
    let maxSort = Math.max.apply(
      Math,
      this.dataSource.map(function (o) {
        return o.sort;
      })
    );
    if (!maxSort || maxSort < 0) return 0;
    return maxSort + 1;
  }

  public editRecord(item: Creditcustomer) {}
  public IsNewRow(item: Creditcustomer): boolean {
    return true;
  }
  public notify(title: string, message: string) {
    this.$notification.open({
      message: message,
      description: title,
      placement: "bottomRight",
      duration: 4,
    });
  }
  get sorteraBokstavsordning() {
    if (this.category) {
      return this.category.defaultAlphabeticalOrder;
    }
    return false;
  }
}
