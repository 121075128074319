import { CategoryTypeEnum } from "@/api";

class CategoryTypeAdapter {
  constructor() { }
  public fromCategoryType(catType: CategoryTypeEnum): AdapterCategoryType {
    if (catType == CategoryTypeEnum.NUMBER_0) {
      return AdapterCategoryType.Products;
    } else if (catType == CategoryTypeEnum.NUMBER_1) {
      return AdapterCategoryType.Html;
    } else if (catType == CategoryTypeEnum.NUMBER_2) {
      return AdapterCategoryType.Pdf;
    } else if (catType == CategoryTypeEnum.NUMBER_3) {
      return AdapterCategoryType.Creditcustomer;
    } else if (catType == CategoryTypeEnum.NUMBER_4) {
      return AdapterCategoryType.Tasklist;
    } else if (catType == CategoryTypeEnum.NUMBER_5) {
      return AdapterCategoryType.Schedule;
    } else if (catType == CategoryTypeEnum.NUMBER_7) {
      return AdapterCategoryType.AppLink;
    } else if (catType == CategoryTypeEnum.NUMBER_8) {
      return AdapterCategoryType.WebPage;
    } else if (catType == CategoryTypeEnum.NUMBER_9) {
      return AdapterCategoryType.Table;
    }
    return AdapterCategoryType.Pdf;
  }
  public toCategoryType(catType: AdapterCategoryType): CategoryTypeEnum {
    if (catType == AdapterCategoryType.Products) {
      return CategoryTypeEnum.NUMBER_0;
    } else if (catType == AdapterCategoryType.Html) {
      return CategoryTypeEnum.NUMBER_1;
    } else if (catType == AdapterCategoryType.Pdf) {
      return CategoryTypeEnum.NUMBER_2;
    } else if (catType == AdapterCategoryType.Creditcustomer) {
      return CategoryTypeEnum.NUMBER_3;
    } else if (catType == AdapterCategoryType.Tasklist) {
      return CategoryTypeEnum.NUMBER_4;
    } else if (catType == AdapterCategoryType.Schedule) {
      return CategoryTypeEnum.NUMBER_5;
    } else if (catType == AdapterCategoryType.AppLink) {
      return CategoryTypeEnum.NUMBER_7;
    } else if (catType == AdapterCategoryType.WebPage) {
      return CategoryTypeEnum.NUMBER_8;
    } else if (catType == AdapterCategoryType.Table) {
      return CategoryTypeEnum.NUMBER_9;
    }

    return CategoryTypeEnum.NUMBER_0;
  }
  public getOptions() {
    return Object.values(AdapterCategoryType).filter((key) => isNaN(Number(key)));
  }
}

export enum AdapterCategoryType {
  Products = "Varor",
  Html = "Fritext",
  Pdf = "PDF",
  Tasklist = "Checklista",
  Creditcustomer = "Register",
  Schedule = "ipool",
  AppLink = "App",
  WebPage = "Hemsida",
  Table = "Tabell"
}
export default new CategoryTypeAdapter();