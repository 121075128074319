
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class CheckEdit extends Vue {
  @Prop()
  defaultValue?: any;

  getDefault() {
    if (this.defaultValue) return this.defaultValue;
    let result = new Array();
    return result;
  }
  handleChange(value: any) {
    if (value) this.$emit("valueChanged", value.target.checked);
  }
}
