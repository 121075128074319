
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class DateEdit extends Vue {
  @Prop()
  defaultValue?: any;

  getDefault() {
    if (this.defaultValue) return this.defaultValue;
    let result = new Array();
    return result;
  }
  handleChange(date:Date, dateString:string) {
    this.$emit("valueChanged", dateString);
  }
}
