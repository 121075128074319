
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Department } from "@/api/api";
import { storeApi } from "@/services/ApiService";

@Component
export default class EditMenu extends Vue {
  public handleClick(e: any) {
    var storeId = this.$router.currentRoute.params["id"];
    switch (e.key) {
      case "contacts": {
        let path = `/admin/stores/${storeId}/contacts`;
        if (this.$router.currentRoute.path != path) {
          this.$router.push(path);
        }
        break;
      }
      case "departments": {
        let path = `/admin/stores/${storeId}/departments`;
        if (this.$router.currentRoute.path != path) {
          this.$router.push(path);
        }
        break;
      }
      case "tablets": {
        let path = `/admin/stores/${storeId}/tablets`;
        if (this.$router.currentRoute.path != path) {
          this.$router.push(path);
        }
        break;
      }
    }
  }
}
