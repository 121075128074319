
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { storeApi } from "@/services/ApiService";
import Axios, { CancelTokenSource } from "axios";

@Component({
  components: {
    Cropper,
  },
})
export default class ImageEdit extends Vue {
  $refs!: {
    cropper: any;
    fileinput: any;
  };

  @Prop()
  imageId: number | undefined;
  @Prop()
  title: string | undefined;
  image: any;
  previewVisible: boolean = false;
  previewImage: any = null;
  originalImage!: any;
  loadmodalvisible: boolean = false;

  loading: boolean = true;
  fileList: any = [];
  base64!: any;
  constructor() {
    super();
  }

  async mounted() {
    if (this.imageId) {
      let result = await storeApi.getThumbnail(this.imageId);
      this.base64 = result.data;
      this.fileList.push({
        uid: -1,
        name: "image.png",
        status: "done",
        url: this.dataUrl(),
      });
    }
    this.loading = false;
  }

  dataUrl() {
    if (!this.base64) return;
    return "data:image/png;base64," + this.base64;
  }

  async updatePreviewImage() {
    if (this.previewImage) {
      return;
    }

    if (this.imageId) {
      let response = await storeApi.getImage(this.imageId);
      this.previewImage = "data:image/png;base64," + response.data;
      this.originalImage = "data:image/png;base64," + response.data;
    }
  }

  async handleCropOk() {
    const { coordinates, canvas } = this.$refs.cropper.getResult();

    let resizeCanvas = document.createElement('canvas');
    let resizeContext = resizeCanvas.getContext('2d');
    var scale = Math.min((800 / canvas.width), (800 /canvas.height));
    var wscaled = canvas.width * scale;
    var hscaled = canvas.height * scale;

    resizeCanvas.width = wscaled;
    resizeCanvas.height = hscaled;
    resizeContext?.drawImage(canvas, 0, 0, wscaled, hscaled);

    let result = resizeCanvas.toDataURL();
    this.fileList = [
      {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: result,
      },
    ];
    this.previewImage = result;
    this.originalImage = this.previewImage;
    this.previewVisible = false;
    await this.upload();
  }

  async upload() {
    this.loadmodalvisible = true;
    let result = await storeApi.postImage(this.previewImage);
    this.$emit("valueChanged", result.data.imageid);
    this.loadmodalvisible = false;
  }

  handleBeforeUpload(file: File) {
    var img = new Image();
    img.onload = (c) => {
      let sideLength = Math.max(img.width, img.height);
      let resizeCanvas = document.createElement('canvas');
      let resizeContext = resizeCanvas.getContext('2d');
      
      resizeCanvas.width = sideLength;
      resizeCanvas.height = sideLength;
      //centering the image in the new canvas
      resizeContext?.drawImage(img, (sideLength-img.width)/2 , (sideLength-img.height)/2, img.width, img.height);
      
      this.previewImage = resizeCanvas.toDataURL();
      this.previewVisible = true;
    };
    img.src = URL.createObjectURL(file);
    return false;
  }

  async handleRemove() {
    if (this.imageId) {
      await storeApi.deleteImage(this.imageId);
      this.fileList = [];
      this.$emit("update", null);
      this.previewVisible = false;
    }
  }

  handleZoomIn() {
    this.$refs.cropper.zoom(1.2);
  }

  handleZoomOut() {
    this.$refs.cropper.zoom(0.8);
  }

  openFileDialog() {
    if (!this.$refs.fileinput.onchange) {
      this.$refs.fileinput.onchange = (e: any) => {
        this.previewVisible = false;
        this.handleBeforeUpload(e.target.files[0]);
      };
    }
    this.$refs.fileinput.click();
  }

  filechange(e: any) {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = (readerEvent: any) => {
      this.previewImage = readerEvent.target.result;
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  async handlePreview() {
    await this.updatePreviewImage();
    this.previewVisible = true;
  }

  handleCropCancel() {
    this.previewImage = this.originalImage;
    this.previewVisible = false;
  }
}
