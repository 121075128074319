import DepartmentRecord from "./DepartmentRecord";

export default class DepartmentRecordFilter {
  private filterText: string;
  public constructor(text: string) {
    this.filterText = text;
  }

  public Filter(c: DepartmentRecord): boolean {
    if (c.newRowRecord || c.newlyAdded) {
      return true;
    }

    if (!this.filterText) {
      return true;
    }

    if (c.department.title.toLowerCase().includes(this.filterText.toLowerCase())) {
      return true;
    }
    return false;
  }
}
