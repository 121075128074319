
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import product from "@/entities/product";
import ProductRecord from "./ProductRecord";

@Component
export default class EditableCellTags extends Vue {
  @Prop()
  record!: ProductRecord;

  editmode: boolean = false;
  valueChanged: number = 0;
  newValue!: string[];

  get NewRow() {
    if (this.record) {
      return this.record.NewRowRecord;
    }
    return false;
  }

  get Eco() {
    if (this.record) {
      return this.record.Product.eco;
    }
    return false;
  }

  get Inactive() {
    if (this.record) {
      return this.record.Product.inactive;
    }
    return false;
  }

  getDefault() {
    this.valueChanged;
    if (this.newValue) {
      return this.newValue;
    }
    let result = new Array();
    if (this.record.Product.inactive) {
      result.push("Inaktiv");
    }
    if (this.record.Product.eco) {
      result.push("Ekologisk");
    }
    return result;
  }

  editToggle() {
    this.editmode = !this.editmode;
  }
  handleChange(value: string[]) {
    this.newValue = value;
    if (this.record.NewRowRecord) {
      this.$emit("update", this.newValue);
    }
  }
  acceptEdit() {
    this.editmode = !this.editmode;
    this.$emit("update", this.newValue);
    this.valueChanged++;
  }
  cancelEdit() {
    this.editmode = !this.editmode;
    this.newValue = [];
    this.valueChanged++;
  }
}
