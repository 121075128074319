import { Column } from "@/components/controls/s-table/column";
import { ITableAction } from "@/components/controls/s-table/table-actions.vue";

export interface ISettings {
  dataSource: any;
  pagination: boolean;
  showNewRow?: boolean;
  recordsUpdated?: (records: Array<any>, type?: UpdateType) => any;
  acceptNewRow?: any;
  newRowText?: string;
  newRowInit?: () => any;
  columns: Array<Column<any>>;
  loading: boolean;
  // rowKey: any;
  actions?: { [key: string]: ITableAction<any> };
  sort?: SortSettings;
  filter?: FilterSettings;
  expandable?: (record: any) => boolean;
  addRowClasses?: (record: any) => string[];
}
export interface INestedSettings extends ISettings {
  getParentDataSource(record: any): any;
}
export class SettingsBase<RecordType> {
  pagination: boolean = false;
  recordsUpdated?: (records: Array<RecordType>, type?: UpdateType) => any;
  columns!: Array<Column<RecordType>>;
  loading: boolean = false;
  // rowKey!: ((record: RecordType) => any);
  actions?: { [key: string]: ITableAction<RecordType> };
  sort?: SortSettings;
  addRowClasses?: (record: RecordType) => string[];
}

export class Settings<RecordType> extends SettingsBase<RecordType> implements ISettings {
  dataSource: RecordType[] | undefined;
  expandable?: (record: RecordType) => boolean;
  filter?: FilterSettings;
  showNewRow: boolean = true;
  acceptNewRow?: (record: RecordType) => any;
  newRowText?: string;
  newRowInit?: () => RecordType;
}
export class NestedSettings<RecordType, BaseType> extends SettingsBase<RecordType> implements INestedSettings {
  dataSource!: (record: BaseType) => RecordType[] | undefined;
  getParentDataSource!: (record: BaseType) => RecordType[] | undefined;
}

export class SortSettings {
  sortProperty!: string;
  enableSort?: boolean;
}
export class FilterSettings {
  filterString!: string;
  filterProperties!: Array<string>;
}
export enum UpdateType {
  Edited,
  Sorted
}