import { axiosInstance, loginApi } from "./ApiService";
import Vue from "vue";

class AuthService extends Vue {
  admin: boolean;
  store: boolean;
  storeid: number;
  storetext!: string;

  constructor() {
    super();
    this.admin = false;
    this.store = false;
    this.storeid = -1;

    if (localStorage.getItem("token")) {
      this.setClaims(<string>localStorage.getItem("token"));
    }
    axiosInstance.interceptors.response.use(
      response=>{
        return response;
      },
      error => {
        if(error.response.status == 401){
          this.Logout();
        }
      }
    );
  }

  async Login(username: string, password: string) {
    let response = await loginApi.login({ username, password });

    if (response.data.token) {
      this.setToken(response.data.token);
      this.$emit("changed");
    }
  }
  public setExternalToken(token: string) {
    //TODO check valid token?
    this.setToken(token);
    this.$emit("changed");
  }
  private setToken(token: string) {
    localStorage.setItem("token", token);
    this.setClaims(token);
  }

  private setClaims(token: string) {
    if (token) {
      let claims = this.parseJwt(token);
      if (claims.admin) {
        this.admin = true;
        this.store = false;
        this.storeid = -1;
        this.storetext = claims.storetext;
      } else if (claims.storeid) {
        this.admin = false;
        this.store = true;
        this.storeid = claims.storeid;
        this.storetext = claims.storetext;
      }
    }
  }

  Logout() {
    this.admin = false;
    this.store = false;
    this.storeid = -1;
    this.storetext = "";
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser"); // Also remove V1 token for compability with portal
    this.$emit("changed");
    setTimeout( () => { window.location.href = 'https://infotab.seadragon.se?logout=true'; }, 500 );
    // window.location.href = 'https://infotab.seadragon.se?logout=true';
  }

  private parseJwt(token: string) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
}

export let authService = new AuthService();
