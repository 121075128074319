
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { storeApi } from "@/services/ApiService";
import * as ST from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import ItemPickerV2 from "@/components/controls/itemPickerV2.vue";
import TextEdit from "@/components/controls/text-edit.vue";
import ColorEdit from "@/components/controls/color-edit.vue";
import SpecialEdit from "@/components/controls/special-edit.vue";
import { DisplayMode, IsNewRow } from "@/components/controls/s-table/exports";
import ExistingCategoriesDialog from "@/components/store/departmentCategories/dialogs/ExistingCategories.vue";
import CreateGroupDialog from "@/components/store/departmentCategories/dialogs/CreateGroup.vue";
import {
  CategoryTypeEnum,
  DepartmentCategory,
  Category,
  DepartmentCategoryGroup,
} from "@/api/api";
import CategoryTypeAdapter, {
  AdapterCategoryType,
} from "@/entities/CategoryTypeAdapter";
import DisplayTypeAdapter from "@/entities/DisplayTypeAdapters";
import DisplayTypeAdapters from "@/entities/DisplayTypeAdapters";
import DepartmentCategoryRecord from "@/components/store/departmentCategories/DepartmentCategoryRecord";

@Component({
  components: { ExistingCategoriesDialog, CreateGroupDialog, STable },
})
export default class StoreDepartmentCategories extends Vue {
  departmentId: number = -1;
  dataSource: DepartmentCategoryRecord[] = [];
  groupDataSources: Record<number, DepartmentCategoryRecord[]> = {};
  existingDialogVisible = false;
  addGroupsDialogVisible = false;
  pickedCategoryForGroup?: DepartmentCategoryRecord = undefined;
  showAddExistingButton = false;

  public async mounted() {
    this.departmentId = parseInt(this.$route.params.id);
    await this.loadDepartmentData();
  }
  public async beforeRouteUpdate(to: any, from: any, next: any) {
    this.departmentId = parseInt(to.params.id);
    await this.loadDepartmentData();
    next();
  }

  public async beforeRouteLeave(to: any, from: any, next: any) {
    //TODO if something is in edit
    next();
  }
  public initNewRow(): DepartmentCategoryRecord {
    return new DepartmentCategoryRecord(
      {
        alias: "",
        id: -1,
        categoryId: -1,
        color: "#ffffff",
        name: "",
        type: CategoryTypeEnum.NUMBER_0,
        displayType: 0,
        existsOnMultipleDepartments: false,
        departmentId: this.departmentId,
        sortOrder: this.getMaxSort(),
        properties: 0,
      },
      undefined
    );
  }
  tableSettings: ST.Settings<DepartmentCategoryRecord> = {
    dataSource: this.dataSource,
    pagination: false,
    showNewRow: true,
    sort: {
      sortProperty: "sortOrder",
      enableSort: true,
    },
    addRowClasses: (record) => (record.isGroup ? ["group-row"] : []),
    recordsUpdated: async (records, type) =>
      await this.save(records, false, type == ST.UpdateType.Sorted),
    newRowText: "Skapa ny kategori",
    newRowInit: () => this.initNewRow(),
    loading: true,
    expandable: (record) => record.isGroup,
    actions: {
      addToGroup: {
        icon: "folder-add",
        multiAction: {
          title: ["Skapa ny grupp", (record) => this.createNewGroup(record)],
          actionsFunc: () => this.getGroupActions(),
        },
        visible: (record) => !record.isGroup && !IsNewRow(record),
        tooltip: (_) => "Flytta kategorin till en grupp",
      },
      edit: {
        icon: "edit",
        action: (record) => {
          this.editCategory(record);
        },
        visible: (record) => !IsNewRow(record) && record.category != undefined,
      },
      remove: {
        icon: "delete",
        confirmation: (record) =>
          record.isGroup
            ? `Är du säker på att du vill ta bort ${record.name} och dess underkategorier`
            : `Är du säker på att du vill ta bort ${record.name}`,
        action: async (record) => {
          await this.removeRecord(record);
          var recordIndex = this.dataSource.indexOf(record, 0);
          if (recordIndex > -1) {
            this.dataSource.splice(recordIndex, 1);
          }
        },
      },
    },
    columns: this.createColumns(),
  };
  nestedTableSettings: ST.NestedSettings<
    DepartmentCategoryRecord,
    DepartmentCategoryRecord
  > = {
    dataSource: (record) => this.getGroupDataSource(record.id),
    getParentDataSource: (record) =>
      this.getGroupDataSource(record.category?.parentDepartmentCategoryGroupId),
    pagination: false,
    sort: {
      sortProperty: "sortOrder",
      enableSort: true,
    },
    addRowClasses: (record) => ["nested-row"],
    recordsUpdated: async (records, type) =>
      await this.save(records, true, type == ST.UpdateType.Sorted),
    loading: false,
    actions: {
      removeFromGroup: {
        icon: "folder-open",
        action: (record) => this.onRemoveFromGroup(record),
        visible: (record) => !IsNewRow(record) && record.category != undefined,
        tooltip: (_) => "Ta bort kategorin från gruppen",
      },
      edit: {
        icon: "edit",
        action: (record) => {
          this.editCategory(record);
        },
        visible: (record) => !IsNewRow(record) && record.category != undefined,
      },
      remove: {
        icon: "delete",
        confirmation: (record) =>
          `Är du säker på att du vill ta bort ${record.name}`,
        action: async (record) => {
          if (!record.category) return;
          await this.removeRecord(record);
          this.removeFromGroupDataSource(record);
        },
      },
    },
    columns: this.createColumns(),
  };

  private getGroupDataSource(
    groupId: number | undefined
  ): DepartmentCategoryRecord[] | undefined {
    if (!groupId) return undefined;
    if (!this.groupDataSources[groupId]) this.groupDataSources[groupId] = [];
    return this.groupDataSources[groupId];
  }
  private createNewGroup(record: DepartmentCategoryRecord) {
    this.pickedCategoryForGroup = record;
    this.addGroupsDialogVisible = true;
  }

  private getGroupActions(): Array<
    [string, (record: DepartmentCategoryRecord) => any]
  > {
    let result = new Array<
      [string, (record: DepartmentCategoryRecord) => any]
    >();

    this.dataSource.forEach((element) => {
      if (element.isGroup)
        result.push([
          element.name,
          (record) => this.onAddToGroup(record, element.group!),
        ]);
    });
    return result;
  }
  private async onAddToGroup(
    record: DepartmentCategoryRecord,
    group: DepartmentCategoryGroup
  ) {
    if (!record.category || !group) return;
    this.nestedTableSettings.loading = true;
    record.category.parentDepartmentCategoryGroupId = group.id;
    record.sortOrder = this.getMaxSortInGroup(group.id);
    await storeApi.postDepartmentCategoryWeb(record.category);
    let recordIndex = this.dataSource.indexOf(record);
    this.dataSource.splice(recordIndex, 1);
    this.addToGroupDataSource(record);
    this.nestedTableSettings.loading = false;
    this.notify("Tillagd i " + group.name, record.category.name);
  }
  private async onRemoveFromGroup(record: DepartmentCategoryRecord) {
    if (!record.category || !record.category.parentDepartmentCategoryGroupId)
      return;
    this.nestedTableSettings.loading = true;
    this.removeFromGroupDataSource(record);
    record.category.parentDepartmentCategoryGroupId = undefined;
    record.sortOrder = this.getMaxSort();
    await storeApi.postDepartmentCategoryWeb(record.category);
    this.nestedTableSettings.loading = false;
    this.dataSource.push(record);
    this.notify("Borttagen från gruppen", record.category.name);
  }
  private removeFromGroupDataSource(record: DepartmentCategoryRecord) {
    if (!record.category || !record.category.parentDepartmentCategoryGroupId)
      return;
    let nestedDataSource =
      this.groupDataSources[record.category.parentDepartmentCategoryGroupId];
    var recordIndex = nestedDataSource.indexOf(record, 0);
    if (recordIndex > -1) {
      nestedDataSource.splice(recordIndex, 1);
    }
  }

  private addToGroupDataSource(record: DepartmentCategoryRecord) {
    if (!record.category || !record.category.parentDepartmentCategoryGroupId)
      return;
    let nestedDataSource =
      this.groupDataSources[record.category.parentDepartmentCategoryGroupId];
    nestedDataSource.push(record);
  }

  private createColumns(): ST.Column<DepartmentCategoryRecord>[] {
    return [
      {
        title: "Färg",
        edit: ColorEdit,
        width: 30,
        editData: (record) => ({
          editable: true,
          immediate: true,
          info: record.existsOnMultipleDepartments
            ? "Denna kategori finns på flera avdelningar, byte av färg kommer påverka samtliga"
            : undefined,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "color",
          },
          props: {
            defaultValue: record.color,
          },
        }),
      },

      {
        title: "Namn",
        edit: TextEdit,
        editData: (record) => ({
          editable: true,
          info: record.existsOnMultipleDepartments
            ? "Denna kategori finns på flera avdelningar, byte av namn kommer påverka samtliga"
            : undefined,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "alias",
          },
          props: {
            defaultValue: record.alias,
          },
        }),
      },
      {
        title: "Typ",
        width: 200,
        edit: ItemPickerV2,
        editData: (record) => ({
          editable: record.category != undefined,
          displayMode: DisplayMode.ATag,
          updateEmitter: "valueChanged",
          info: record.existsOnMultipleDepartments
            ? "Denna kategori finns på flera avdelningar, byte av typ kommer påverka samtliga"
            : undefined,
          dataBinding: {
            propertyName: "type",
            formatDisplayValue: (value) =>
              record.category == undefined
                ? "Grupp"
                : CategoryTypeAdapter.fromCategoryType(value),
            parseEditValue: (value) =>
              CategoryTypeAdapter.toCategoryType(value),
          },
          props: {
            dataSource: CategoryTypeAdapter.getOptions(),
            defaultOpen: !IsNewRow(record),
            defaultValue: CategoryTypeAdapter.fromCategoryType(record.type),
            placeHolder: "Välj kategorityp",
            selectionMode: "single",
          },
        }),
      },
      {
        title: "Visningsläge",
        edit: ItemPickerV2,
        width: 200,
        editData: (record) => ({
          visible: record.category != undefined,
          editable:
            (record.type == CategoryTypeEnum.NUMBER_0 ||
              record.type == CategoryTypeEnum.NUMBER_7 ||
              record.type == CategoryTypeEnum.NUMBER_3) &&
            record.category != undefined,
          displayMode: DisplayMode.ATag,
          dataBinding: {
            propertyName: "displayType",
            formatDisplayValue: (value) =>
              DisplayTypeAdapter.fromAny(record.type, value),
            parseEditValue: (value) =>
              DisplayTypeAdapters.toAny(record.type, value),
          },
          updateEmitter: "valueChanged",
          props: {
            dataSource: DisplayTypeAdapters.getOptions(record.type),
            defaultOpen: !IsNewRow(record),
            defaultValue: DisplayTypeAdapters.fromAny(
              record.type,
              record.displayType
            ),
            placeHolder: "Välj visningsläge",
            selectionMode: "single",
          },
        }),
      },
      {
        title: "Special",
        edit: SpecialEdit,
        width: 150,
        editData: (record) => ({
          dataBinding: {
            propertyName: "properties",
          },
          updateEmitter: "valueChanged",
          props: {
            departmentCategoryProperties: record.properties,
            categoryType: record.type,
            displayType: record.displayType,
          },
          editable: true,
          immediate: true,
        }),
      },
      {
        title: "Innehåll",
        edit: TextEdit,
        width: 150,
        editData: (record) => ({
          dataBinding: {
            propertyName: "contentCount",
            formatDisplayValue: (value) =>
              record.isGroup || !value
                ? undefined
                : `${this.getContentUnit(
                    value,
                    CategoryTypeAdapter.fromCategoryType(record.type)
                  )}`,
          },
          editable: false,
          immediate: false,
        }),
      },
    ];
  }
  private getContentUnit(
    value: number,
    categoryType: AdapterCategoryType
  ): string {
    if (value == 1) {
      switch (categoryType) {
        case AdapterCategoryType.Products:
          return `${value} vara`;
        case AdapterCategoryType.Html:
        case AdapterCategoryType.WebPage:
          return `Finns`;
        case AdapterCategoryType.Pdf:
          return `${value} sida`;
        case AdapterCategoryType.Tasklist:
          return `${value} uppgift`;
        case AdapterCategoryType.Creditcustomer:
          return `${value} kund`;
        case AdapterCategoryType.Schedule:
          return `Ipool`;
      }
    } else {
      switch (categoryType) {
        case AdapterCategoryType.Products:
          return `${value} varor`;
        case AdapterCategoryType.Html:
          return `Finns`;
        case AdapterCategoryType.WebPage:
          return `Saknas`;
        case AdapterCategoryType.Pdf:
          return `${value} sidor`;
        case AdapterCategoryType.Tasklist:
          return `${value} uppgifter`;
        case AdapterCategoryType.Creditcustomer:
          return `${value} kunder`;
        case AdapterCategoryType.Schedule:
          return `Ipool`;
      }
    }
    return "Okänd";
  }
  public editCategory(record: DepartmentCategoryRecord) {
    if (record.category)
      this.$router.push({
        name: "storeEditCategory",
        params: { id: record.category.categoryId.toString() },
      });
  }
  private async save(
    records: Array<DepartmentCategoryRecord>,
    nested: boolean,
    disableNotify: boolean
  ) {
    if (nested) this.nestedTableSettings.loading = true;
    for (const record of records) {
      if (record.category) {
        {
          var result = await storeApi.postDepartmentCategoryWeb(
            record.category
          );
          if (result.data) {
            record.category.id = result.data.id;
            record.category.categoryId = result.data.categoryId;
            record.category.excludeInactive = result.data.excludeInactive;
            record.category.existsOnMultipleDepartments =
              result.data.existsOnMultipleDepartments;
            record.category.departmentId = result.data.departmentId;
            record.category.displayType = result.data.displayType;
            record.category.color = result.data.color;
            record.category.categoryId = result.data.categoryId;
            record.category.alias = result.data.alias;
            record.category.type = result.data.type;
            record.category.name = result.data.name;
            record.category.sortOrder = result.data.sortOrder;
          }
        }
      } else if (record.group) {
        var grpResult = await storeApi.postDepartmentCategoryGroupWeb(
          record.group
        );
        if (grpResult.data) {
          record.group.id = grpResult.data.id;
          record.group.name = grpResult.data.name;
        }
      }
    }
    if (nested) this.nestedTableSettings.loading = false;
    if (disableNotify) return;
    this.notify("Sparat", "Sparningen har gått igenom");
  }

  public async existingHandleOk(data: Category[]) {
    this.existingDialogVisible = false;
    if (!data) return;
    this.tableSettings.loading = true;
    let saved = new Array<DepartmentCategory>();
    let maxSort = this.getMaxSort();
    for (const c of data) {
      var result = await storeApi.postDepartmentCategoryWeb({
        id: -1,
        categoryId: c.id,
        alias: c.name,
        name: c.name,
        type: c.type,
        color: c.defaultColor,
        displayType: c.defaultDisplayType ?? 0,
        departmentId: this.departmentId,
        existsOnMultipleDepartments: true,
        sortOrder: maxSort++,
        properties: 0,
      });
      saved.push(result.data);
    }
    this.notify("Kategorierna är importerade", "Importerade");
    this.dataSource.push(
      ...saved.map((d) => new DepartmentCategoryRecord(d, undefined))
    );
    this.tableSettings.loading = false;
  }
  public handleDialogCancel() {
    this.existingDialogVisible = false;
    this.addGroupsDialogVisible = false;
  }
  public handleAddExistingCategory() {
    this.existingDialogVisible = true;
  }
  public handleAddNewGroup() {
    this.addGroupsDialogVisible = true;
  }
  public async addGroupsHandleOk(data: string) {
    this.addGroupsDialogVisible = false;
    if (!data) return;
    this.tableSettings.loading = true;
    let saved = await storeApi.postDepartmentCategoryGroupWeb({
      id: -1,
      name: data,
      departmentId: this.departmentId,
      sortOrder: this.getMaxSort(),
    });
    this.dataSource.push(new DepartmentCategoryRecord(undefined, saved.data));

    this.notify("Skapat " + saved.data.name, "Grupp");
    if (this.pickedCategoryForGroup)
      this.onAddToGroup(this.pickedCategoryForGroup, saved.data);

    this.tableSettings.loading = false;
  }
  public handleAddOtherStore() {}

  public async saveDepartmentCategory(category: DepartmentCategory) {
    var result = await storeApi.postDepartmentCategoryWeb(category);
    if (result.data) {
      category.categoryId = result.data.categoryId;
      category.alias = result.data.alias;
      category.type = result.data.type;
      category.name = result.data.name;
    }
    this.notify(category.name, "Kategori sparad");
  }
  public async removeRecord(record: DepartmentCategoryRecord) {
    if (record.category) {
      var result = await storeApi.deleteDepartmentCategoryWeb(
        record.category.id
      );
      this.notify(record.category.name, "Kategori borttagen");
    }
    if (record.group) {
      var grpResult = await storeApi.deleteDepartmentGroupWeb(record.group.id);
      this.notify(record.group.name, "Grupp borttagen");
      this.removeFromGroupDataSource;
    }
  }

  public async loadDepartmentData() {
    this.tableSettings.loading = true;
    this.dataSource.length = 0;
    this.groupDataSources = {};
    var fetchResult = await storeApi.getDepartmentDataWeb(this.departmentId);
    this.showAddExistingButton = fetchResult.data.hasMultipleDepartments;
    let records = new Array<DepartmentCategoryRecord>();
    
    for (const item of fetchResult.data.categories) {
      if (item.parentDepartmentCategoryGroupId) {
        if (!this.groupDataSources[item.parentDepartmentCategoryGroupId])
          this.groupDataSources[item.parentDepartmentCategoryGroupId] = [];
        this.groupDataSources[item.parentDepartmentCategoryGroupId].push(
          new DepartmentCategoryRecord(item, undefined)
        );
      } else records.push(new DepartmentCategoryRecord(item, undefined));
    }
    for (const item of fetchResult.data.categoryGroups) {
      records.push(new DepartmentCategoryRecord(undefined, item));
    }
    this.dataSource.push(...records);
    this.tableSettings.loading = false;
  }
  public getMaxSort(): number {
    let maxSort = Math.max.apply(
      Math,
      this.dataSource.map(function (o) {
        return o.sortOrder;
      })
    );
    if (maxSort == undefined || maxSort < 0) return 0;
    return maxSort + 1;
  }
  public getMaxSortInGroup(groupId: number): number {
    let groupDataSource = this.getGroupDataSource(groupId);
    if (!groupDataSource) return 0;
    let maxSort = Math.max.apply(
      Math,
      groupDataSource.map(function (o) {
        return o.sortOrder;
      })
    );
    if (maxSort == undefined || maxSort < 0) return 0;
    return maxSort + 1;
  }
  public notify(title: string, message: string) {
    this.$notification.open({
      message: message,
      description: title,
      placement: "bottomRight",
      duration: 4,
    });
  }
}
