
import Vue from "vue";
import { Component } from "vue-property-decorator";
import * as ST from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import ItemPickerV2 from "@/components/controls/itemPickerV2.vue";
import TestRecord from "@/components/admin/test/testRecord";
import { DisplayMode, IsNewRow } from "@/components/controls/s-table/exports";
// import Record from "@/components/admin/test/Record";

@Component({
  components: {
    STable,
  },
})
export default class Test extends Vue {
  dataSource: Array<TestRecord> = this.createTestDataSource();

  tableSettings: ST.Settings<TestRecord> = {
    dataSource: this.dataSource,
    pagination: false,
    showNewRow: true,
    acceptNewRow: (record) => {
      //TODO SAVE
      this.dataSource.splice(0, 0, record);
    },
    newRowText: "Klicka för att lägga till en ny testrad",
    newRowInit: () => ({ id: -1, text: "Bruno", text2: "Citrus" }),
    loading: false,
    actions: {
      shop: {
        icon: "shopping-cart",
        action: (record) => {
          console.log(record);
        },
      },
      remove: {
        icon: "delete",
        confirmation: (record) =>
          `Är du säker på att du vill ta bort ${record.text}`,
        action: (record) => {
          //TODO DELETE
          var recordIndex = this.dataSource.indexOf(record, 0);
          if (recordIndex > -1) {
            this.dataSource.splice(recordIndex, 1);
          }
        },
      },
    },
    columns: [
      {
        title: "Typ",
        edit: ItemPickerV2,
        width: 400,
        editData: (record) => ({
          editable: true,
          editValue: record.text,
          dataBinding: {
            propertyName: "text",
          },
          updateEmitter: "valueChanged",
          displayMode: DisplayMode.ATag,
          props: {
            dataSource: ["Bruno", "Bingo", "Brutus", "Britt-Marie"],
            defaultOpen: !IsNewRow(record),
            defaultValue: record.text,
            placeHolder: "Välj typ",
            selectionMode: "single",
          },
        }),
      },
      {
        title: "Visningsläge",
        edit: ItemPickerV2,
        width: 400,
        editData: (record) => ({
          editable: true,
          dataBinding: {
            propertyName: "text2",
          },
          updateEmitter: "valueChanged",
          props: {
            dataSource: ["Citrus", "Citron", "Cricket", "Cirkus"],
            defaultOpen: !IsNewRow(record),
            defaultValue: record.text2,
            placeHolder: "Välj visningsläge",
            selectionMode: "single",
            customDisplay: (value: string) => value,
          },
        }),
      },
    ],
  };

  createTestDataSource(): TestRecord[] {
    return [
      {
        id: 5,
        text: "Bingo",
        text2: "Citron",
      },
      {
        id: 6,
        text: "Brutus",
        text2: "Cricket",
      },
      {
        id: 7,
        text: "Britt-Marie",
        text2: "Cirkus",
      },
    ];
  }
}
