
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { authService } from "@/services/AuthService";

@Component
export default class Header extends Vue {
  store: string = "";
  loggedIn: boolean = false;
  updatenumber: number = 0;
  popoverVisible: boolean = false;
  popoverTrigger: string = "none";

  constructor() {
    super();
    this.store = authService.storetext ?? "GetVisible";
    this.loggedIn = authService.storeid != -1 || authService.admin;
    authService.$on("changed", () => {
      this.Update();
    });

    let intervalId = setTimeout(() => {
      this.togglePopoverVisibility();
    }, 3000);
  }

  //Once hidden stay hidden
  popoverVisibilityChanged(e: any) {
    if (!e) {
      this.popoverTrigger = "none";
    }
  }

  togglePopoverVisibility() {
    this.popoverTrigger = "click";
    this.popoverVisible = !this.popoverVisible;
  }

  private Update() {
    this.store = authService.storetext ?? "GetVisible";
    this.loggedIn = authService.storeid != -1 || authService.admin;
  }

  public handleMenuClick(context: any) {
    if (context.key == "1" && this.$route.path != "/Store/Settings") {
      this.$router.push("/Store/Settings");
    } else if (context.key == "2") {
      authService.Logout();
      this.$router.replace({ name: "login" });
    }
  }

  get LoggedIn() {
    this.updatenumber;
    return this.loggedIn;
  }

  get Text() {
    this.updatenumber;
    return this.store;
  }
}
