
import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class NavBreadcrumbs extends Vue {
  public handleClick(e: any) {
    var key = e.key.split(":");
    switch (key[0]) {
      case "home": {
        if (this.$router.currentRoute.path != "/Butik/Kategorier") {
          this.$router.push("/Butik/Kategorier");
        }
        break;
      }
      case "products": {
        if (this.$router.currentRoute.path != "/Butik/Varor") {
          this.$router.push("/Butik/Varor");
        }
        break;
      }
      case "department": {
        var departmentId = key[1];
        if (
          !this.$router.currentRoute.path.startsWith(
            "/Butik/DepartmentCategory"
          ) ||
          this.$router.currentRoute.params.departmentId !== departmentId
        ) {
          var page = "DepartmentCategory";
          this.$router.push({
            name: "departmentCategory",
            params: { page, departmentId },
          });
        }
        break;
      }
    }
  }
}
