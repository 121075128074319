
import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class NewRecord extends Vue {
  @Prop()
  RecordText!: string;

  click(e: any) {
    this.$emit("click", e);
  }
}
