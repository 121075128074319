
import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class NewRow extends Vue {
  @Prop()
  newRowText?: string;
  click(e: any) {
    this.$emit("click", e);
  }
}
export interface ISTableRow {
  sTableKey: number;
}

export interface INewRow {
  sTableNewRow: boolean;
  sTableKey: number;
  inEdit: boolean;
}
class Factory {
  private static currentRowId: number = 0;
  public static get NewRowId(): number {
    return this.currentRowId++;
  }
}
export function RowTableKeyFactory(): number {
  return Factory.NewRowId;
}
export function IsNewRow(record: any): record is INewRow {
  var nRow = record as INewRow;
  return nRow.sTableNewRow !== undefined && nRow.sTableNewRow;
}
