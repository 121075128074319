
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import ProductRecord from "./ProductRecord";

@Component
export default class EditableCellPlu extends Vue {
  $refs!: {
    theinput: any;
  };

  @Prop()
  record!: ProductRecord;

  valueChanged: number = 0;
  newValue!: string;
  editmode: boolean = false;

  get NewRowRecord() {
    if (this.record) {
      return this.record.NewRowRecord;
    }
    return false;
  }

  get editvalue() {
    this.valueChanged;
    if (this.newValue) {
      return this.newValue;
    } else if (this.record) {
      return this.record.Product.plu;
    } else return "";
  }

  change(e: any) {
    this.newValue = e.srcElement.value;
    if (this.record.NewRowRecord) {
      this.$emit("update", this.newValue);
    }
  }

  editToggle() {
    this.editmode = !this.editmode;
    if (this.editmode) {
      this.$nextTick(() => this.$refs.theinput.focus());
    }
  }

  cancelEdit() {
    delete this.newValue;
    this.editmode = !this.editmode;
    this.valueChanged++;
  }

  acceptEdit() {
    this.editmode = !this.editmode;
    this.valueChanged++;
    if (this.newValue) {
      this.$emit("update", this.newValue);
    }
  }
}
