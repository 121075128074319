
import Vue from "vue";

import { Component, Prop } from "vue-property-decorator";
import * as ST from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import { storeApi } from "@/services/ApiService";
import { Category, Task } from "@/api/api";
import TextEdit from "@/components/controls/text-edit.vue";
import TaskLastDone from "@/components/store/taskList/task-last-done.vue";
import itemPickerV2 from "@/components/controls/itemPickerV2.vue";
import {
  DisplayMode,
  IsNewRow,
  SortSettings
} from "@/components/controls/s-table/exports";
import DaysTypeAdapter, { AdapterDayType } from "@/entities/DaysTypeAdapter";
import TaskPropertyAdapter, {
  AdapterTaskPropertyType
} from "@/entities/TaskPropertyAdapter";

@Component({ components: { STable } })
export default class TasksContent extends Vue {
  @Prop()
  category!: Category;

  dataSource: Task[] = [];
  tableSettings: ST.Settings<Task> = {
    dataSource: this.dataSource,
    pagination: false,
    showNewRow: true,
    sort: {
      sortProperty: "sort",
      enableSort: true
    },
    newRowText: "Lägg till en ny rad",
    recordsUpdated: async records => {
      var result = await storeApi.updateTasks(this.category.id, records);
      if (records.length == 1 && records[0].id == -1) {
        records[0].id = result.data[0].id;
      }
      this.notify("Checklista sparad", records[0].text ?? "");
    },
    newRowInit: () => this.initNewRow(),
    loading: true,
    actions: {
      edit: {
        icon: "edit",
        action: record => {
          this.editRecord(record);
        },
        visible: record => !this.IsNewRow(record)
      },
      remove: {
        icon: "delete",
        confirmation: record =>
          `Är du säker på att du vill ta bort ${record.text}`,
        action: async record => {
          var recordIndex = this.dataSource.indexOf(record, 0);
          if (recordIndex > -1) {
            this.dataSource.splice(recordIndex, 1);
          }
          await storeApi.deleteTask(record.id, this.category.id);

          this.notify("Uppgift borttagen", record.text ?? "");
        }
      }
    },
    columns: [
      {
        title: "Text",
        edit: TextEdit,
        width: 400,
        editData: record => ({
          editable: true,
          dataBinding: {
            propertyName: "text"
          },

          updateEmitter: "valueChanged",
          props: {
            defaultValue: record.text
          }
        })
      },
      {
        title: "Visas för",
        edit: itemPickerV2,
        width: 200,
        editData: record => ({
          editable: true,
          dataBinding: {
            propertyName: "visibledays",
            parseEditValue: (value: number[]) =>
              value.reduce((sum, current) => sum + current, 0),
            formatDisplayValue: value =>
              DaysTypeAdapter.enumToBits()
                .filter(v => (value & v) === v)
                .map(k => DaysTypeAdapter.fromDaysType(k))
          },
          updateEmitter: "valueChanged",
          displayMode: DisplayMode.ATag,
          props: {
            dataSource: [
              { id: 1, text: "Måndag" },
              { id: 2, text: "Tisdag" },
              { id: 4, text: "Onsdag" },
              { id: 8, text: "Torsdag" },
              { id: 16, text: "Fredag" },
              { id: 32, text: "Lördag" },
              { id: 64, text: "Söndag" }
            ],
            defaultValue: DaysTypeAdapter.enumToBits().filter(
              v => (record.visibledays & v) === v
            ),
            customKey: "id",
            customDisplay: "text",
            placeHolder: "Välj dagar",
            selectionMode: "multiple"
          }
        })
      },
      {
        title: "Återställs (tomt = alla dagar)",
        edit: itemPickerV2,
        width: 200,
        editData: record => ({
          editable: true,
          dataBinding: {
            propertyName: "resetdays",
            parseEditValue: (value: number[]) =>
              value.reduce((sum, current) => sum + current, 0),
            formatDisplayValue: value =>
              DaysTypeAdapter.enumToBits()
                .filter(v => (value & v) === v)
                .map(k => DaysTypeAdapter.fromDaysType(k))
          },
          updateEmitter: "valueChanged",
          displayMode: DisplayMode.ATag,
          props: {
            dataSource: [
              { id: 1, text: "Måndag" },
              { id: 2, text: "Tisdag" },
              { id: 4, text: "Onsdag" },
              { id: 8, text: "Torsdag" },
              { id: 16, text: "Fredag" },
              { id: 32, text: "Lördag" },
              { id: 64, text: "Söndag" }
            ],
            defaultValue: DaysTypeAdapter.enumToBits().filter(
              v => (record.resetdays & v) === v
            ),
            customKey: "id",
            customDisplay: "text",
            placeHolder: "Välj dagar",
            selectionMode: "multiple"
          }
        })
      },
      {
        title: "Egenskaper",
        edit: itemPickerV2,
        width: 150,
        editData: record => ({
          editable: true,
          dataBinding: {
            propertyName: "properties",
            formatDisplayValue: value =>
              TaskPropertyAdapter.enumToBits()
                .filter(v => (value & v) === v)
                .map(k => TaskPropertyAdapter.fromTaskPropertyType(k)),
            parseEditValue: (value: number[]) =>
              value.reduce((sum, current) => sum + current, 0)
          },
          updateEmitter: "valueChanged",
          displayMode: DisplayMode.ATag,
          props: {
            dataSource: [
              { id: 1, text: "Fetstil" },
              { id: 2, text: "Rubrik" }
            ],
            defaultValue: TaskPropertyAdapter.enumToBits().filter(
              v => (record.properties & v) === v
            ),
            customKey: "id",
            customDisplay: "text",
            placeHolder: "Välj egenskaper",
            selectionMode: "multiple"
          }
        })
      },
      {
        title: "Senast utförd",
        width: 80,
        edit: TaskLastDone,
        editData: record => ({
          editable: false,
          immediate: true,
          dataBinding: {
            propertyName: "N/A"
          },
          props: {
            record: record
          }
        })
      }
    ]
  };

  async mounted() {
    var result = await storeApi.getTasks(this.category.id);
    this.dataSource.push(...result.data);
    this.tableSettings.loading = false;
  }

  public initNewRow(): Task {
    return {
      text: "",
      id: -1,
      sort: this.dataSource.length,
      visibledays: 0,
      resetdays: 0,
      properties: 0
    };
  }

  public async saveChecklistItem(item: Task) {
    return storeApi.updateTasks(this.category.id, new Array<Task>(item));
  }

  public async saveSort(items: Task[]) {
    await storeApi.updateTasks(this.category.id, items);
  }

  public editRecord(item: Task) {}

  public IsNewRow(item: Task): boolean {
    return true;
  }

  public notify(title: string, message: string) {
    this.$notification.open({
      message: message,
      description: title,
      placement: "bottomRight",
      duration: 4
    });
  }
}
