
import Vue from "vue";
import {Component} from "vue-property-decorator";
import product, {idText, pcategory} from "@/entities/product";
import * as ST from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import ItemPickerV2 from "@/components/controls/itemPickerV2.vue";
import UserFieldEdit from "@/components/controls/user-field-edit.vue";
import TextEdit from "@/components/controls/text-edit.vue";
import ImageEdit from "@/components/controls/image-edit.vue";
import {
    BindingMode,
    DisplayMode,
    IsNewRow,
} from "@/components/controls/s-table/exports";
import {storeApi} from "@/services/ApiService";
import {Product, IdText, ProductUserField} from "@/api/api";

@Component({components: {STable}})
export default class ProductsV2 extends Vue {
    categories: IdText[] = [];
    categoryFilterDataSource: any[] = [];
    categoryFilterValue?: number = undefined;
    dataSource: Product[] = [];
    completeDataSource: Product[] = [];
    tags: IdText[] = [];
    filterString: string = "";
    userFields: ProductUserField[] = [];
    tableSettings: ST.Settings<Product> = {
        dataSource: this.dataSource,
        pagination: true,
        showNewRow: true,
        newRowText: "Skapa ny vara",
        sort: {
            sortProperty: "title",
        },
        filter: {
            filterString: "",
            filterProperties: ["title", "plu"],
        },
        recordsUpdated: async (records) => this.postProducts(records),
        newRowInit: () => this.initNewRow(),
        loading: true,
        actions: {
            remove: {
                icon: "delete",
                confirmation: (record) =>
                    `Är du säker på att du vill ta bort ${record.title}`,
                action: async (record) => this.removeProduct(record),
            },
        },
        columns: [
            {
                title: "Bild",
                edit: ImageEdit,
                width: 64,
                editData: (record) => ({
                    editable: true,
                    immediate: true,
                    updateEmitter: "valueChanged",
                    dataBinding: {
                        propertyName: "imageid",
                    },
                    props: {
                        imageId: record.imageid,
                        title: record.title,
                    },
                }),
            },
            {
                title: "Vara",
                edit: TextEdit,
                editData: (record) => ({
                    editable: true,
                    updateEmitter: "valueChanged",
                    dataBinding: {
                        propertyName: "title",
                    },
                    props: {
                        defaultValue: record.title,
                    },
                }),
            },
            {
                title: "PLU",
                width: 180,
                edit: TextEdit,
                editData: (record) => ({
                    editable: true,
                    updateEmitter: "valueChanged",
                    dataBinding: {
                        propertyName: "plu",

                    },
                    props: {
                        defaultValue: record.plu,
                    },
                }),
            },
            {
                title: "Streckkod",
                width: 210,
                edit: TextEdit,
                editData: (record) => ({
                    editable: true,
                    updateEmitter: "valueChanged",
                    dataBinding: {
                        propertyName: "barcode",
                        parseEditValue: (value: string) => {
                            return value.replace(/[^a-zA-Z0-9]/g, "");
                        }
                    },
                    props: {
                        defaultValue: record.barcode,
                    },
                }),
            },
            {
                title: "Kategorier",
                edit: ItemPickerV2,
                editData: (record) => ({
                    displayMode: DisplayMode.ATag,
                    updateEmitter: "valueChanged",
                    dataBinding: {
                        propertyName: "categories",
                        formatDisplayValue: (value: IdText[]) => {
                            if (value) {
                                let result = value.map((category: IdText) => category.text);
                                return result;
                            }
                        },
                        parseEditValue: (value: number[]) => {
                            let resultMap = value.map((id: number) => {
                                return this.categories.find((c) => c.id == id);
                            });
                            return resultMap;
                        },
                    },
                    props: {
                        dataSource: this.categories,
                        defaultValue: record.categories?.map((category) => category.id),
                        placeHolder: "Välj kategorier",
                        selectionMode: "multiple",
                        defaultOpen: !IsNewRow(record),
                        customKey: "id",
                        customDisplay: "text",
                    },
                }),
            },
            {
                title: "Egna värden",
                edit: UserFieldEdit,
                visible: false,
                id: "userFields",
                editData: (record) => ({
                    editable: true,
                    immediate: true,
                    updateEmitter: "valueChanged",
                    dataBinding: {
                        mode: BindingMode.Unbound,
                        propertyName: "unbound_userfields",
                        customUnboundDataSet: () => {
                        }
                    },
                    props: {
                        textContent: this.getUserFieldValue(record),
                        dataSource: this.userFields,
                        record: record
                    },
                }),
            },
            {
                title: "Inaktiv/Eko",
                edit: ItemPickerV2,
                editData: (record) => ({
                    displayMode: DisplayMode.ATag,
                    updateEmitter: "valueChanged",
                    dataBinding: {
                        propertyName: "unbound_tags",
                        mode: BindingMode.Unbound,
                        customUnboundDataGet: () => this.getTagValues(record),
                        customUnboundDataSet: (value: number[]) =>
                            this.setTagValues(record, value),
                        formatDisplayValue: (value: IdText[]) => {
                            if (value) {
                                let result = value.map((tag: IdText) => tag.text);
                                return result;
                            }
                        },
                    },
                    props: {
                        dataSource: this.tags,
                        defaultValue: this.getTagValues(record).map((c) => c.id),
                        placeHolder: "Välj egenskaper",
                        selectionMode: "multiple",
                        defaultOpen: !IsNewRow(record),
                        customKey: "id",
                        customDisplay: "text",
                    },
                }),
            },
        ],
    };

    public async mounted() {
        this.fillTags();
        await this.loadData();
    }

    getUserFieldValue(record: Product) {
        if (record.userFields == null || record.userFields.length == 0)
            return "";
        return `Ifyllda värden : ${record.userFields?.length}`
    }

    getTagValues(record: Product) {
        let result: IdText[] = [];
        let eco = this.tags.find((c) => c.id === 0);
        let inactive = this.tags.find((c) => c.id === 1);
        if (record.eco && eco) result.push(eco);
        if (record.inactive && inactive) result.push(inactive);
        return result;
    }

    setTagValues(record: Product, value: number[]) {
        record.eco = value.includes(0);
        record.inactive = value.includes(1);
    }

    fillTags() {
        this.tags.push({
            id: 0,
            text: "Eko",
        });
        this.tags.push({
            id: 1,
            text: "Inaktiv",
        });
    }

    get searchString() {
        return this.filterString;
    }

    set searchString(value) {
        this.filterString = value;
        this.onSearch(value);
    }

    onSearch(e: any) {
        if (!this.tableSettings.filter) return;

        if (e.srcElement.value) {
            this.tableSettings.filter.filterString = e.srcElement.value;
        } else this.tableSettings.filter.filterString = "";
    }

    categoryFilterChange(value: string) {
        this.dataSource.length = 0;
        if (value == undefined) {
            this.dataSource.push(...this.completeDataSource)
        } else if (!isNaN(parseInt(value))) {
            this.dataSource.push(...this.completeDataSource.filter(product => product.categories?.some(category => category.id === parseInt(value))));
        }
    }

    initNewRow(): Product {
        return {
            id: -1,
            eco: false,
            inactive: false,
        };
    }

    async removeProduct(record: Product) {
        await storeApi.deleteProduct(record.id);
        var recordIndex = this.dataSource.indexOf(record, 0);
        if (recordIndex > -1) this.dataSource.splice(recordIndex, 1);
        this.notify("Varan har blivit borttagen", record.title ?? "");
    }

    async loadData() {
        this.tableSettings.loading = true;
        var categoriesTask = storeApi.getCategories();
        var productsTask = storeApi.getProducts();
        var userFieldsTask = storeApi.getProductUserFields();
        var fetchedCategories = (await categoriesTask).data;
        fetchedCategories.sort((c1, c2) =>
            c1.name.toLowerCase() > c2.name.toLowerCase()
                ? 1
                : c1.name.toLowerCase() < c2.name.toLowerCase()
                    ? -1
                    : 0
        );
        fetchedCategories.map((c) =>
            this.categories.push({id: c.id, text: c.name})
        );
        fetchedCategories.forEach((c) => {
            if(c.type === 0 || c.type === 9){
                this.categoryFilterDataSource.push({value: c.id, label: c.name});
            }
        });
        var allProducts = (await productsTask).data;
        this.dataSource.push(...allProducts);
        this.completeDataSource.push(...allProducts);
        this.userFields.push(...(await userFieldsTask).data);

        if (this.userFields.length > 0) {
            let userFieldsColumn = this.tableSettings.columns.find(col => col.id === "userFields");
            if (userFieldsColumn) {
                userFieldsColumn.visible = true;
            }
        }
        this.tableSettings.loading = false;
    }

    async postProducts(records: Array<Product>) {
        for (const record of records) {
            var res = await storeApi.postProduct(record);
            record.id = res.data.id;
        }
        this.notify("Ändringarna har blivit sparade", "Sparat");
    }

    public notify(description: string, message: string) {
        this.$notification.open({
            message: message,
            description: description,
            placement: "bottomRight",
            duration: 4,
        });
    }
}
