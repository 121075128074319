
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { adminApi } from "@/services/ApiService";
// import Contacts from "@/components/admin/editStore/contacts.vue";
// import Departments from "@/components/admin/editStore/departments.vue";
import EditMenu from "@/components/admin/editStore/editMenu.vue";

@Component({ components: { EditMenu } })
export default class AdminEditStore extends Vue {
  currentStore: string = "";
  public async mounted() {
    var result = await adminApi.getStore(this.CurrentStore());
    this.currentStore = result.data.text ?? "";
  }
  public CurrentStore(): number {
    return Number(this.$router.currentRoute.params["id"]);
  }
}
