class DaysTypeAdapter {
  constructor() { }
  public fromDaysType(dayType: number): AdapterDayType {
    // console.log(dayType);
    if (dayType == 1) {
      return AdapterDayType.Monday;
    } else if (dayType == 2) {
      return AdapterDayType.Tuesday;
    }else if (dayType == 4) {
      return AdapterDayType.Wednesday;
    }else if (dayType == 8) {
      return AdapterDayType.Thursday;
    }else if (dayType == 16) {
      return AdapterDayType.Friday;
    } else if (dayType == 32) {
      return AdapterDayType.Saturday;
    } else if (dayType == 64) {
      return AdapterDayType.Sunday;
    }
    return AdapterDayType.Tuesday;
  }
  public toDaysType(dayType: AdapterDayType): number {
    if (dayType == AdapterDayType.Monday) {
      return 1;
    }    if (dayType == AdapterDayType.Tuesday) {
      return 2;
    }    if (dayType == AdapterDayType.Wednesday) {
      return 4;
    }    if (dayType == AdapterDayType.Thursday) {
      return 8;
    }    if (dayType == AdapterDayType.Friday) {
      return 16;
    }    if (dayType == AdapterDayType.Saturday) {
      return 32;
    }    if (dayType == AdapterDayType.Sunday) {
      return 64;
    }
    return 64;
  }
  public getOptions() {
    return Object.values(AdapterDayType).filter((key) => isNaN(Number(key)));
  }
  public enumToBits()
  {
    return Object.keys(Days).map(Number).filter(Boolean);
  }
}

export enum Days 
{
  monday = 1,
  tuesday = 2,
  wednesday = 4,
  thursday = 8,
  friday = 16,
  saturday = 32,
  sunday = 64
}

export enum AdapterDayType {
  Monday = "Måndag", //1
  Tuesday = "Tisdag", //2
  Wednesday = "Onsdag", //4
  Thursday = "Torsdag", //8
  Friday ="Fredag", //16
  Saturday = "Lördag", //32
  Sunday = "Söndag" //64
}

export default new DaysTypeAdapter();