
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { adminApi } from "@/services/ApiService";
import * as ST from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import TextEdit from "@/components/controls/text-edit.vue";
import CheckEdit from "@/components/controls/check-edit.vue";
import { DevTask } from "@/api";
import DateEdit from "@/components/controls/date-edit.vue";

@Component({ components: { STable } })
export default class DevTasks extends Vue {
  dataSource: DevTask[] = [];

  public initNewRow(): DevTask {
    return {
      id: 0,
      inProgress: false,
      sortOrder: this.getMaxSort(),
      description: "",
      completed: undefined,
      actualHours: 0,
      estimatedHours: 0,
    };
  }
  public getMaxSort(): number {
    let maxSort = Math.max.apply(
      Math,
      this.dataSource.map(function (o) {
        return o.sortOrder ?? 0;
      })
    );
    if (maxSort == undefined || maxSort < 0) return 0;
    return maxSort + 1;
  }

  tableSettings: ST.Settings<DevTask> = {
    dataSource: this.dataSource,
    pagination: false,
    showNewRow: true,
    loading: true,
    newRowText: "Skapa ny utvecklingspunkt",
    newRowInit: () => this.initNewRow(),
    sort: {
      sortProperty: "SortOrder",
      enableSort: true,
    },
    filter: {
      filterString: "",
      filterProperties: ["text", "password"],
    },
    recordsUpdated: async (records) => {
      await records.map(async (c) => await this.postDevTask(c));
    },
    actions: {
      remove: {
        icon: "delete",
        confirmation: (record) =>
          `Är du säker på att du vill ta bort ${record.description}`,
        action: async (record) => {},
      },
    },
    columns: [
      {
        title: "Description",
        edit: TextEdit,
        width: 400,
        editData: (record) => ({
          editable: true,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "description",
          },
          props: {
            defaultValue: record.description,
          },
        }),
      },
      {
        title: "Påbörjad",
        edit: CheckEdit,
        width: 100,
        editData: (record) => ({
          immediate: true,
          editable: true,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "inProgress",
          },
          props: {
            defaultValue: record.inProgress,
          },
        }),
      },
      {
        title: "Färdig",
        edit: DateEdit,
        width: 300,
        editData: (record) => ({
          editable: true,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "completed",
            formatDisplayValue: (value: string) => {
              return value?.slice(0, 10);
            },
          },
          props: {
            defaultValue: record.completed,
          },
        }),
      },
      {
        title: "Estimerad tid (timmar)",
        edit: TextEdit,
        width: 150,
        editData: (record) => ({
          editable: true,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "estimatedHours",
            
          },
          props: {
            defaultValue: record.estimatedHours,
          },
        }),
      },
      {
        title: "Nedlagd tid (timmar)",
        edit: TextEdit,
        width: 150,
        editData: (record) => ({
          editable: true,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "actualHours",
          },
          props: {
            defaultValue: record.actualHours,
          },
        }),
      },
    ],
  };

  public async mounted() {
    var getDevTasks = await adminApi.getDevTasks();
    this.dataSource.push(...getDevTasks.data);
    this.tableSettings.loading = false;
  }
  public async postDevTask(record: DevTask) {
    console.log(record);
    await adminApi.postDevTask(record);
  }
}
