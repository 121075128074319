
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { storeApi } from "@/services/ApiService";
import { Category } from "@/api/api";

@Component({ components: {} })
export default class WebpageContent extends Vue {
  url = "";
  @Prop()
  category!: Category;

  async mounted() {
    var res = await storeApi.getCategoryWebPage(this.category!.id);
    this.url = res.data;
  }
  get text() {
    return this.url;
  }
  set text(value: string) {
    this.url = value;
  }

  save() {
    return storeApi.postCategoryWebPage(this.category!.id, this.url);
  }
}
