
import Vue, {VNodeData} from "vue";
import {Component, Watch, Prop} from "vue-property-decorator";
import {
    FilterSettings,
    ISettings,
    INestedSettings,
    UpdateType,
} from "@/components/controls/s-table/settings";

import EditWrapper from "./edit-wrapper.vue";
import NewRow, {
    INewRow,
    ISTableRow,
    RowTableKeyFactory,
    IsNewRow,
} from "./new-row.vue";

import TableActions, {ITableActionInternal} from "./table-actions.vue";
import {DisplayMode, IEditData} from "./edit-data";

@Component({
    components: {
        NewRow,
    },
})
export default class STable extends Vue {
    dataSource: any[] = [];
    @Prop()
    settings!: ISettings;
    @Prop()
    nestedSettings?: INestedSettings;
    loading!: boolean;

    componentKey: number = 0;
    nestedComponentKey: number = 5;

    constructor() {
        super();
    }

    @Watch("settings.dataSource", {deep: false, immediate: true})
    dataSourceChanged() {
        this.dataSource = this.getWrappedDataSource();
    }

    @Watch("settings.filter", {deep: true, immediate: false})
    filterChanged() {
        this.dataSource = this.getWrappedDataSource();
    }

    @Watch("settings.columns", {deep: true, immediate: true})
    columnsChanged() {
        let visibleColumnChanged = this.settings.columns.some(
            (col, index, columns) => {
                let oldColumn = columns.find((oldCol) => oldCol.visible === col.visible);
                return oldColumn && oldColumn.visible != col.visible;
            }
        );

        if (visibleColumnChanged) {
            this.componentKey += 1;
            this.nestedComponentKey += 1;
        }
    }

    onTableChange(
        pagination: any,
        filters: any,
        sorter: any,
        {currentDataSource}: any
    ) {
    }

    getRowClassName = (record: any) => {
        let result = "";
        if (!this.settings.expandable || !this.settings.expandable(record))
            result += " hide-expand";
        if (this.settings.addRowClasses)
            this.settings.addRowClasses(record).forEach((element) => {
                result += " " + element;
            });
        return result;
    };
    getNestedRowClassName = (record: any) => {
        let result = "";
        if (!this.nestedSettings) return result;
        if (this.nestedSettings.addRowClasses)
            this.nestedSettings.addRowClasses(record).forEach((element) => {
                result += " " + element;
            });
        return result;
    };
    getNestedDataSource = (record: any) => {
        var result: any[] = [];
        if (this.nestedSettings?.dataSource)
            result = this.nestedSettings.dataSource(record);
        if (this.nestedSettings?.sort) {
            let sProp = this.nestedSettings.sort.sortProperty;
            result.sort((a: any, b: any) =>
                a[sProp] < b[sProp] ? -1 : a[sProp] === b[sProp] ? 0 : 1
            );
        }
        result.forEach((element) => {
            (element as ISTableRow).sTableKey = RowTableKeyFactory();
        });
        return result;
    };

    getWrappedDataSource() {
        let settings = this.settings;
        var result: any[] = [];
        if (!settings) return result;

        settings.dataSource.forEach((element: any) => {
            if (
                !settings.filter?.filterString ||
                this.filterElement(element, settings.filter)
            ) {
                (element as ISTableRow).sTableKey = RowTableKeyFactory();
                result.push(element);
            }
        });
        if (settings.sort) {
            let sProp = settings.sort.sortProperty;
            result.sort((a: any, b: any) =>
                a[sProp] < b[sProp] ? -1 : a[sProp] === b[sProp] ? 0 : 1
            );
        }

        if (settings.showNewRow) {
            result.splice(0, 0, this.createNewRow(false));
        }

        return result;
    }

    filterElement(element: any, filter: FilterSettings): boolean {
        if (
            !filter.filterString ||
            !filter.filterProperties ||
            filter.filterProperties.length == 0
        )
            return true;
        let filterLower = filter.filterString.toLowerCase();
        let found = false;
        filter.filterProperties.forEach((c) => {
            if (element[c] && element[c].toLowerCase().includes(filterLower))
                found = true;
        });
        return found;
    }

    createNewRow(nested: boolean): INewRow {
        let settings = nested ? this.nestedSettings! : this.settings;
        var newRow: INewRow = settings.newRowInit ? settings.newRowInit() : {};
        newRow.sTableNewRow = true;
        newRow.inEdit = false;
        newRow.sTableKey = RowTableKeyFactory();
        return newRow;
    }

    getWrappedColumns(nested: boolean): any[] {
        let settings = nested ? this.nestedSettings! : this.settings;
        var result: any[] = [];
        let shouldCreateNewRow = settings.showNewRow;
        let newRowCreated = false;
        let foundActions = 0;
        let colIndex = nested ? "n:" : "p:";
        if (settings.actions)
            foundActions = Object.entries(settings.actions).length;

        settings.columns
            .filter((col) => col.visible !== false)
            .forEach((col) => {
                result.push({
                    title: col.title,
                    width: col.width,
                    key: colIndex + col.title,
                    customRender: (record: any) => {
                        if (IsNewRow(record) && !record.inEdit) {
                            if (shouldCreateNewRow && !newRowCreated) {
                                newRowCreated = true;
                                return {
                                    children: this.$createElement(NewRow, {
                                        props: {
                                            newRowText: settings.newRowText,
                                        },
                                        on: {
                                            click: (e: any) => {
                                                record.inEdit = true;
                                            },
                                        },
                                    }),
                                    attrs: {
                                        colSpan:
                                            settings.columns.length +
                                            (foundActions > 0 || settings.showNewRow ? 1 : 0),
                                        style: "padding: 0px;",
                                    },
                                };
                            }
                            return {
                                attrs: {
                                    colSpan: 0,
                                },
                            };
                        }
                        var colData = col.editData(record);
                        return {
                            children: this.$createElement(EditWrapper, {
                                props: {
                                    displayMode: colData.displayMode ?? DisplayMode.Default,
                                    newRow: IsNewRow(record),
                                    rowInEdit: record.inEdit,
                                    visible: colData.visible ?? true,
                                    editable: colData.editable ?? col.edit,
                                    immediate: colData.immediate ?? false,
                                    edit: col.edit,
                                    editProps: colData.props,
                                    editOn: colData.on,
                                    updateEmitter: colData.updateEmitter,
                                    binding: colData.dataBinding,
                                    info: colData.info ?? undefined,
                                    record: record,
                                },
                                on: {
                                    accept: (e: any) => {
                                        this.onRecordsUpdated(e, nested, UpdateType.Edited);
                                    },
                                },
                            }),
                        };
                    },
                });
            });

        if (foundActions > 0 || settings.sort?.enableSort || settings.showNewRow) {
            result.push({
                width: this.calcActionsWidth(foundActions) + "px",
                customRender: (record: any) => {
                    if (!IsNewRow(record) || record.inEdit) {
                        return {
                            children: this.$createElement(TableActions, {
                                props: {
                                    actions: this.getInternalActions(record, nested),
                                    record: record,
                                },
                            }),
                        };
                    }
                    return {
                        attrs: {
                            colSpan: 0,
                        },
                    };
                },
            });
        }

        return result;
    }

    calcActionsWidth(actions: number): number {
        let actionWidth = 20;
        if (actions < 2) return 2 * actionWidth;
        return actions * actionWidth;
    }

    getColumnData(data: IEditData): VNodeData | undefined {
        return {
            props: data.props,
            on: data.on,
        };
    }

    resetNewRow(record: any, nested: boolean) {
        if (nested) return;
        let dataSource = this.dataSource;
        var recordIndex = dataSource.indexOf(record, 0);
        if (recordIndex > -1) {
            dataSource.splice(recordIndex, 1, this.createNewRow(nested));
        }
    }

    acceptNew(record: INewRow, nested: boolean) {
        if (nested) return;
        let settings = nested ? this.nestedSettings! : this.settings;
        let dataSource = this.dataSource;
        record.sTableNewRow = false;
        record.inEdit = false;
        this.onRecordsUpdated(record, nested, UpdateType.Edited);
        dataSource.splice(0, 1);
        settings.dataSource.splice(0, 0, record);
    }

    onRecordsUpdated(data: any, nested: boolean, type: UpdateType) {
        let settings = nested ? this.nestedSettings! : this.settings;
        if (!settings.recordsUpdated) return;
        if (Array.isArray(data)) settings.recordsUpdated(data, type);
        else {
            let records = new Array<any>();
            records.push(data);
            settings.recordsUpdated(records, type);
        }
    }

    swapSort(
        index1: number,
        index2: number,
        sortProperty: string,
        dataSource: any[]
    ): any[] {
        let record1 = dataSource[index1];
        let record2 = dataSource[index2];
        let old1Value = record1[sortProperty];
        record1[sortProperty] = record2[sortProperty];
        record2[sortProperty] = old1Value;
        dataSource.splice(index1, 1);
        dataSource.splice(index2, 0, record1);
        return [record1, record2];
    }

    sortUp(record: any, nested: boolean) {
        let settings = nested ? this.nestedSettings! : this.settings;
        let dataSource = nested
            ? this.nestedSettings?.getParentDataSource(record)
            : this.dataSource;
        var recordIndex = dataSource.indexOf(record, 0);
        let offset = settings.showNewRow ? 1 : 0;
        if (!settings.sort?.sortProperty || recordIndex - offset <= 0) return;
        settings.loading = true;
        let result = this.swapSort(
            recordIndex,
            recordIndex - 1,
            settings.sort.sortProperty,
            dataSource
        );
        settings.loading = false;
        this.onRecordsUpdated(result, nested, UpdateType.Sorted);
    }

    sortDown(record: any, nested: boolean) {
        let dataSource = nested
            ? this.nestedSettings?.getParentDataSource(record)
            : this.dataSource;
        let settings = nested ? this.nestedSettings! : this.settings;
        var recordIndex = dataSource.indexOf(record, 0);
        if (!settings.sort?.sortProperty || recordIndex >= dataSource.length - 1)
            return;
        settings.loading = true;
        let result = this.swapSort(
            recordIndex,
            recordIndex + 1,
            settings.sort.sortProperty,
            dataSource
        );
        settings.loading = false;
        this.onRecordsUpdated(result, nested, UpdateType.Sorted);
    }

    getInternalActions(
        record: any,
        nested: boolean
    ): Array<ITableActionInternal> {
        let settings = nested ? this.nestedSettings! : this.settings;
        var result = new Array<ITableActionInternal>();

        if (settings.sort?.sortProperty && settings.sort.enableSort) {
            result.push({
                icon: "up-square",
                visible: !IsNewRow(record) && !record.inEdit,
                action: () => this.sortUp(record, nested),
                confirm: undefined,
            });
        }
        if (settings.sort?.sortProperty && settings.sort.enableSort) {
            result.push({
                icon: "down-square",
                visible: !IsNewRow(record) && !record.inEdit,
                action: () => this.sortDown(record, nested),
                confirm: undefined,
            });
        }

        if (settings.actions) {
            Object.entries(settings.actions).forEach(([key, value]) => {
                result.push({
                    icon: value.icon,
                    action: () => (value.action ? value.action(record) : undefined),
                    visible: value.visible ? value.visible(record) : !IsNewRow(record),
                    confirm: value.confirmation ? value.confirmation(record) : undefined,
                    tooltip: value.tooltip ? value.tooltip(record) : undefined,
                    multipleActionsTitle: value.multiAction?.title,
                    multipleActions: value.multiAction?.actions
                        ? value.multiAction.actions?.map((c) => [c[0], () => c[1](record)])
                        : undefined,
                    multipleActionsFunc: value.multiAction?.actionsFunc
                        ? value.multiAction.actionsFunc
                        : undefined,
                });
            });
        }
        if (settings.showNewRow) {
            result.push({
                icon: "check",
                visible: IsNewRow(record) && record.inEdit,
                action: () => this.acceptNew(record, nested),
                confirm: undefined,
            });
            result.push({
                icon: "close",
                visible: IsNewRow(record) && record.inEdit,
                action: () => this.resetNewRow(record, nested),
                confirm: undefined,
            });
        }
        return result;
    }
}
