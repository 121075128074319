
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Department } from "@/api/api";
import { storeApi } from "@/services/ApiService";

@Component
export default class Menu extends Vue {
  public editMode(): boolean {
    return this.$route.name !== "adminDefault";
  }
  public handleClick(e: any) {
    switch (e.key) {
      case "stores": {
        if (this.$router.currentRoute.path != "/admin/stores") {
          this.$router.push("/admin/stores");
        }
        break;
      }
      case "tablets": {
          if (this.$router.currentRoute.path != "/admin/dev-tablets") {
          this.$router.push("/admin/dev-tablets");
        }
        break;
      }
       case "devTasks": {
          if (this.$router.currentRoute.path != "/admin/dev-tasks") {
          this.$router.push("/admin/dev-tasks");
        }
        break;
      }
    }
  }
}
