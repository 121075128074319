
import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";
import IRecord from "@/components/controls/grid/IRecord";

@Component
export default class CellOperations extends Vue {
  @Prop()
  record!: IRecord;

  get NewRow() {
    if (this.record) {
      return this.record.NewRow;
    }
    return false;
  }
  acceptEdit() {
    this.$emit("accept");
  }
  cancelEdit() {
    this.$emit("cancel");
  }

  deleteContact() {
    this.$emit("delete");
  }
}
