
import Vue from "vue";
import Swatches from "vue-swatches";
import { Component, Prop, Watch } from "vue-property-decorator";
import "vue-swatches/dist/vue-swatches.min.css";

@Component({ components: { Swatches } })
export default class ColorEdit extends Vue {
  @Prop()
  defaultValue?: any;
  color: any;

  /**
   *
   */
  constructor() {
    super();
    this.color = this.defaultValue;
  }
  handleClose(value: any) {
    if (this.color) this.$emit("valueChanged", this.color);
  }
}
