import axios from "axios";
import { Configuration } from "@/api/restaurant/";
import { DefaultApi } from "@/api/restaurant";

export let axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === "production"
    ? "https://seadragon-restaurant.azurewebsites.net/api/"
    : "http://localhost:7072/api/",
});
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.token = localStorage.getItem("token");
    return config;
  },
  (error) => Promise.reject(error)
);

 export let configuration = new Configuration({
   basePath: process.env.NODE_ENV === "production"
     ? "https://seadragon-restaurant.azurewebsites.net/api/"
     : "http://localhost:7072/api",
 });

export let restaurantApi = new DefaultApi(configuration, undefined, axiosInstance);
export let baseApiURL = process.env.NODE_ENV === "production"
? "https://seadragon-restaurant.azurewebsites.net/api/"
: "http://localhost:7072/api/";
