
import Vue from "vue";
import { Component } from "vue-property-decorator";
import * as ST from "@/components/controls/s-table/exports";
import TextEdit from "@/components/controls/text-edit.vue";
import ColorEdit from "@/components/controls/color-edit.vue";
import STable from "@/components/controls/s-table/sTable.vue";
import ItemPickerV2 from "@/components/controls/itemPickerV2.vue";
import { DisplayMode, IsNewRow } from "@/components/controls/s-table/exports";
import { restaurantApi } from "@/services/RApiService";
import { Product, ProductOptions, IdText, Station } from "@/api/restaurant";
import { idText } from "@/entities/product";

@Component({
  components: {
    STable,
  },
})
export default class RProducts extends Vue {
  categories: IdText[] = [];
  stations: IdText[] = [];
  groups: IdText[] = [];
  dataSource: Array<Product> = [];

  tableSettings: ST.Settings<Product> = {
    dataSource: this.dataSource,
    pagination: false,
    showNewRow: true,
    newRowText: "Ny produkt",
    newRowInit: () => ({id:-1}),
    recordsUpdated: async (records) => this.postRecord(records),
    loading: false,
    sort: {
      sortProperty: "text",
    },
    actions: {
      // edit: {
      //   icon: "edit",
      //   action: (record) => {
      //     this.editProduct(record);
      //   },
      //   visible: (record) => !IsNewRow(record),
      // },
      remove: {
        icon: "delete",
        confirmation: (record) =>
          `Är du säker på att du vill ta bort ${record.text}`,
        action: (record) => {
          this.deleteRecord(record);
        },
      },
    },
    columns: [
    {
        title: "Färg",
        edit: ColorEdit,
        width: 10,
        editData: (record) => ({
          editable: true,
          immediate: true,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "color",
          },
          props: {
            defaultValue: record.color,
          },
        }),
      },
      {
        title: "Produkt",
        edit: TextEdit,
        editData: (record) => ({
          editable: true,
          editValue: record.text,
          dataBinding: {
            propertyName: "text",
          },
          updateEmitter: "valueChanged",
          props: {
            defaultOpen: !IsNewRow(record),
            defaultValue: record.text,
            placeHolder: "Namn",
          },
        }),
      },
      {
        title: "Plu",
        width: 200,
        edit: TextEdit,
        editData: (record) => ({
          editable: true,
          editValue: record.plu,
          dataBinding: {
            propertyName: "plu",
          },
          updateEmitter: "valueChanged",
          props: {
            defaultOpen: !IsNewRow(record),
            defaultValue: record.plu,
          },
        }),
      },
      {
        title: "Pris",
        width: 200,
        edit: TextEdit,
        editData: (record) => ({
          editable: true,
          editValue: record.price,
          dataBinding: {
            propertyName: "price",
          },
          updateEmitter: "valueChanged",
          props: {
            defaultOpen: !IsNewRow(record),
            defaultValue: record.price,
            placeHolder: "Pris",
          },
        }),
      },
      // {
      //   title: "Val",
      //   editData: () => ({
      //     editable: false,
      //     dataBinding: {
      //       propertyName: "productoptions",
      //       formatDisplayValue: (val) =>
      //         val
      //           ?.map((c: ProductOptions) =>
      //             c.price == null ? c.text : `${c.text} (${c.price})`
      //           )
      //           .join(", "),
      //     },
      //   }),
      // },
      {
        title: "Kategorier",
        edit: ItemPickerV2,
        width: 250,
        editData: (record) => ({
          displayMode: DisplayMode.ATag,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "categories",
            formatDisplayValue: (value: IdText[]) => {
              if (value) {
                let result = value.map((category: IdText) => category.text);
                return result;
              }
            },
            parseEditValue: (value: number[]) => {
              let resultMap = value.map((id: number) => {
                return this.categories.find((c) => c.id == id)!;
              });
              return resultMap;
            },
          },
          props: {
            dataSource: this.categories,
            defaultValue: record.categories?.map((category) => category.id),
            placeHolder: "Välj kategorier",
            selectionMode: "multiple",
            defaultOpen: !IsNewRow(record),
            customKey: "id",
            customDisplay: "text",
          },
        }),
      },
      {
        title: "Station",
        width: 200,
        edit: ItemPickerV2,
        editData: (record) => ({
          displayMode: DisplayMode.ATag,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "station",
            formatDisplayValue: (value: idText) => value?.text,
            parseEditValue: (value) => this.stations.find((c) => c.id == value),
          },
          props: {
            dataSource: this.stations,
            defaultOpen: !IsNewRow(record),
            customKey: "id",
            customDisplay: "text",
            placeHolder: "Välj station",
            selectionMode: "single",
          },
        }),
      },
    ],
  };

  async mounted() {
    var categoriesTask = restaurantApi.getCategories();
    var stationsTask = restaurantApi.getStations();
    var groupTasks = restaurantApi.getProductGroups();
    var result = await restaurantApi.getProducts();

    this.dataSource.push(...result.data);

    var fetchedCategories = await categoriesTask;
    fetchedCategories.data.sort((c1, c2) =>
      (c1.text || "").toLowerCase() > (c2.text || "").toLowerCase()
        ? 1
        : (c1.text || "").toLowerCase() < (c2.text || "").toLowerCase()
        ? -1
        : 0
    );
    fetchedCategories.data.map((c) =>
      this.categories.push({ id: c.id!, text: c.text })
    );

    let stations = await stationsTask;
    stations.data.map((c) => {
      this.stations.push({ id: c.id!, text: c.text });
    });
    let groups = await groupTasks;
    groups.data.map((c) => {
      this.groups.push({ id: c.id!, text: c.text });
    });
  }

  editProduct(record: Product) {
    this.$router.push({
      name: "restaurantEditProduct",
      params: { id: record.id!.toString() },
    });
  }

  async deleteRecord(record: Product) {
    console.log(record);
    await restaurantApi.deleteProduct(record.id);
    var recordIndex = this.dataSource.indexOf(record, 0);
    if (recordIndex > -1) this.dataSource.splice(recordIndex, 1);
    this.notify("Varan har blivit borttagen", record.text ?? "");
  }

  async postRecord(records: Array<Product>) {
    for (const record of records) {
      console.log(record);
      var res = await restaurantApi.postProduct(record);
      record.id = res.data.id;
    }
    this.notify("Ändringarna har blivit sparade", "Sparat");
  }

  notify(description: string, message: string) {
    this.$notification.open({
      message: message,
      description: description,
      placement: "bottomRight",
      duration: 4,
    });
  }
}
