import ProductRecord from "./ProductRecord";

export default class ProductRecordComparer {
  private ascending: boolean;
  constructor(ascending: boolean) {
    this.ascending = ascending;
  }

  public Compare(a: ProductRecord, b: ProductRecord): number {
    if (a.NewRowRecord) return -1;
    if (b.NewRowRecord) return 1;

    if (a.NewlyAdded && !b.NewlyAdded) return -1;
    if (b.NewlyAdded && !a.NewlyAdded) return 1;

    let result = 1;
    if (b.Product.title && a.Product.title) {
      if (b.Product.title.toLowerCase() > a.Product.title.toLowerCase()) {
        result = -1;
      }
    }

    if (!this.ascending) {
      result *= -1;
    }

    return result;
  }
}
