
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class itemPickerV2 extends Vue {
  @Prop()
  dataSource!: any;
  @Prop()
  placeHolder: string | undefined;
  @Prop()
  defaultOpen?: boolean;
  @Prop()
  selectionMode!: string;
  @Prop()
  defaultValue?: any;
  @Prop()
  customKey?: string;
  @Prop()
  customDisplay?: string;

  get source() {
    return this.dataSource;
  }
  getDefault() {
    if (this.defaultValue) return this.defaultValue;
    let result = new Array();
    return result;
  }
  handleChange(value: any) {
    if (value) this.$emit("valueChanged", value);
  }
}
