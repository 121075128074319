import { Department } from "@/api/api";
export default class DepartmentRecord {
  readonly department!: Department;
  newRowRecord: boolean;
  rowEdit: boolean = false;
  newlyAdded: boolean = false;

  constructor(department: Department, newRow: boolean) {
    this.department = department;
    this.newRowRecord = newRow;
  }

  get Key() {
    return this.department.id;
  }
}
