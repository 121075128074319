class CreditCustomerPropertyAdapter {
    constructor() { }
    public fromCreditCustomerPropertyType(dayType: number): AdapterCreditCustomerPropertyType {
      if (dayType == 1) {
        return AdapterCreditCustomerPropertyType.Bold;
      } else if (dayType == 2) {
        return AdapterCreditCustomerPropertyType.Caption;
      }
      return AdapterCreditCustomerPropertyType.Caption;
    }
    public toCreditCustomerPropertyType(dayType: AdapterCreditCustomerPropertyType): number {
      if (dayType == AdapterCreditCustomerPropertyType.Bold) {
        return 1;
      }    if (dayType == AdapterCreditCustomerPropertyType.Caption) {
        return 2;
      }
      return 2;
    }
    public getOptions() {
      return Object.values(AdapterCreditCustomerPropertyType).filter((key) => isNaN(Number(key)));
    }
    public enumToBits()
    {
      return Object.keys(CreditCustomerProperty).map(Number).filter(Boolean);
    }
  }

export enum CreditCustomerProperty
{
    bold = 1,
    caption = 2
}

export enum AdapterCreditCustomerPropertyType {
    Bold = "Fetstil",
    Caption = "Rubrik",
  }

export default new CreditCustomerPropertyAdapter();