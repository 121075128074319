
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { baseApiURL, adminApi } from "@/services/ApiService";
import * as ST from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import TextEdit from "@/components/controls/text-edit.vue";
import CreateTabletDialog from "@/components/admin/stores/dialogs/CreateTabletDialog.vue";
import ShowQRDialog from "@/components/admin/stores/dialogs/ShowQRDialog.vue";
import { MdmTablet } from "@/api/api";

@Component({ components: { STable, CreateTabletDialog, ShowQRDialog } })
export default class Tablets extends Vue {
  dataSource: MdmTablet[] = [];
  createTabletDialogVisible: boolean = false;
  QRDialogVisible: boolean = false;
  QRImage: string = "";
  QRName: string = "";

  public async mounted() {
    var result = await adminApi.getTablets(this.CurrentStore());

    if (result.data?.tablets) {
      this.dataSource.push(...result.data.tablets);
      this.tableSettings.loading = false;
    }
  }
  private QRDialogCancel() {
    this.QRDialogVisible = false;
  }
  private showCreateTablet() {
    this.createTabletDialogVisible = true;
  }
  private async createTabletOk(name: string) {
    if (!name) return;
    this.postNewTablet(name);
    this.createTabletDialogVisible = false;
  }
  private async createTabletCancel(name: string) {
    this.createTabletDialogVisible = false;
  }

  public async beforeRouteLeave(to: any, from: any, next: any) {
    next();
  }
  public async postNewTablet(name: string) {
    let result = await adminApi.postTablet(this.CurrentStore(), {
      id: -1,
      text: name,
    });
    this.dataSource.push(result.data);
  }

  tableSettings: ST.Settings<MdmTablet> = {
    dataSource: this.dataSource,
    pagination: false,
    showNewRow: false,

    newRowText: "Lägg till en platta",
    recordsUpdated: async (records) => {
      await records.map(async (c) => await this.saveTablet(c));
    },
    newRowInit: () => this.initNewRow(),
    loading: true,
    sort: {
      sortProperty: "name",
      enableSort: false,
    },

    actions: {
      edit: {
        icon: "qrcode",
        action: (record) => {
          this.showQR(record);
        },
        visible: (record) => true,
      },
    },
    columns: [
      {
        title: "Online",
        edit: TextEdit,
        width: 10,
        editData: (record) => ({
          editable: false,
          dataBinding: {
            propertyName: "online",
            formatDisplayValue: (value: boolean) => (value ? "Ja" : "Nej"),
          },
          props: {
            defaultValue: false,
          },
        }),
      },
      {
        title: "Namn",
        edit: TextEdit,
        width: 500,
        editData: (record) => ({
          editable: true,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "name",
          },
          props: {
            defaultValue: record.name,
          },
        }),
      },
      {
        title: "Senast Online",
        edit: TextEdit,
        width: 250,
        editData: (record) => ({
          editable: false,
          dataBinding: {
            propertyName: "lastOnline",
          },
          props: {
            defaultValue: record.lastOnline,
          },
        }),
      },
      {
        title: "Batteri",
        edit: TextEdit,
        width: 120,
        editData: (record) => ({
          editable: false,
          dataBinding: {
            propertyName: "power",
          },
          props: {
            defaultValue: record.power,
          },
        }),
      },
    ],
  };

  public initNewRow(): MdmTablet {
    return {
      name: "",
      online: false,
      lastOnline: undefined,
      power: undefined,
    };
  }
  public CurrentStore(): number {
    return Number(this.$router.currentRoute.params["id"]);
  }

  public async saveTablet(tablet: MdmTablet) {
    // var result = await storeApi.postCategory(category);
    // if (result.data) {
    //   category.id = result.data.id;
    // }
    // this.notify(category.name, "Kategori sparad");
  }
  public async showQR(tablet: MdmTablet) {
    if (!tablet.name) return;
    this.QRImage = baseApiURL + "sysadmin/getTabletQR?storeId=" + this.CurrentStore() + "&tabletId=" + tablet.name ?? -1;
    this.QRName = tablet.name;
    this.QRDialogVisible = true;
  }

  public notify(title: string, message: string) {
    this.$notification.open({
      message: message,
      description: title,
      placement: "bottomRight",
      duration: 4,
    });
  }
}
