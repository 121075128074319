export interface IEditData {
    props?: { [key: string]: any };
    on?: { [key: string]: Function | Function[] };
    updateEmitter?: string;
    editable?: boolean;
    visible?: boolean;
    displayMode?: DisplayMode;
    immediate?: boolean;
    info?: string;
    dataBinding: DataBinding;
}
export enum DisplayMode {
    Default = 0,
    ATag = 1
}
export enum BindingMode {
    Bound = 0,
    Unbound = 1
}
export class DataBinding {
    propertyName!: string;
    formatDisplayValue?: (value: any) => any;
    parseEditValue?: (value: any) => any;
    mode?: BindingMode = BindingMode.Bound;
    customUnboundDataGet?: () => any;
    customUnboundDataSet?: (value: any) => any;
}