var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('swatches',{attrs:{"show-fallback":"","fallback-input-type":"color","popover-to":"left","wrapper-style":{ width: '225px' },"trigger-style":{
    height: '20px',
    width: '20px',
    float: 'left',
    marginLeft: '5px',
    marginRight: '10px',
  }},on:{"close":function($event){return _vm.handleClose()}},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})
}
var staticRenderFns = []

export { render, staticRenderFns }