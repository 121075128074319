
import Vue from "vue";
import { Component } from "vue-property-decorator";
import * as ST from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import TextEdit from "@/components/controls/text-edit.vue";
import ItemPickerV2 from "@/components/controls/itemPickerV2.vue";
import { idText } from "@/entities/product";
import { DisplayMode, IsNewRow } from "@/components/controls/s-table/exports";
import { restaurantApi } from "@/services/RApiService";
import { ProductionStation,IdText } from "@/api/restaurant";

@Component({
  components: {
    STable,
  },
})
export default class RProductionStations extends Vue {
  dataSource: ProductionStation[] = [];
  stations: IdText[] = [];

  tableSettings: ST.Settings<ProductionStation> = {
    dataSource: this.dataSource,
    pagination: false,
    showNewRow: true,
    newRowText: "Ny produktionsstation",
    newRowInit: () => ({}),
    recordsUpdated: async (records) => this.postRecord(records),
    loading: false,
    actions: {
      edit: {
        icon: "edit",
        action: (record) => {
          this.editProductionStation(record);
        },
        visible: (record) => !IsNewRow(record),
      },
      remove: {
        icon: "delete",
        confirmation: (record) =>
          `Är du säker på att du vill ta bort ${record.text}`,
        action: (record) => {
          this.deleteRecord(record);
        },
      },
    },
    columns: [
      {
        title: "Produktionsstation",
        width: 400,
        edit: TextEdit,
        editData: (record) => ({
          editable: true,
          editValue: record.text,
          dataBinding: {
            propertyName: "text",
          },
          updateEmitter: "valueChanged",
          props: {
            defaultOpen: !IsNewRow(record),
            defaultValue: record.text,
            placeHolder: "Namn",
          },
        }),
      },
      {
        title: "Kopplad station",
        width: 200,
        edit: ItemPickerV2,
        editData: (record) => ({
          displayMode: DisplayMode.ATag,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "station",
            formatDisplayValue: (value: idText) => value?.text,
            parseEditValue: (value) => this.stations.find((c) => c.id == value),
          },
          props: {
            dataSource: this.stations,
            defaultOpen: !IsNewRow(record),
            customKey: "id",
            customDisplay: "text",
            placeHolder: "Välj station",
            selectionMode: "single",
          },
        }),
      },
    ],
  };

  async mounted() {
    var result = await restaurantApi.getProductionStations();
    this.dataSource.push(...result.data);
    let stations = await restaurantApi.getStations();;
    stations.data.map((c) => {
      this.stations.push({ id: c.id!, text: c.text });
    });
  }
  public editProductionStation(record: ProductionStation) {
    if (record == null || record.id == null)
      return;
    this.$router.push({
      name: "restaurantEditProductionStation",
      params: { id: record.id.toString() },
    });
  }
  async deleteRecord(record: ProductionStation) {
    console.log(record);
    await restaurantApi.deleteProductionStation(record.id);
    var recordIndex = this.dataSource.indexOf(record, 0);
    if (recordIndex > -1) this.dataSource.splice(recordIndex, 1);
    this.notify("Produktionsstationen har blivit borttagen", record.text ?? "");
  }
  async postRecord(records: ProductionStation[]) {
    for (const record of records) {
      var res = await restaurantApi.postProductionStation(record);
      record.id = res.data.id;
    }
    this.notify("Ändringarna har blivit sparade", "Sparat");
  }
  public notify(description: string, message: string) {
    this.$notification.open({
      message: message,
      description: description,
      placement: "bottomRight",
      duration: 4,
    });
  }
}
