import { render, staticRenderFns } from "./stores.vue?vue&type=template&id=725632d0&scoped=true"
import script from "./stores.vue?vue&type=script&lang=ts"
export * from "./stores.vue?vue&type=script&lang=ts"
import style0 from "./stores.vue?vue&type=style&index=0&id=725632d0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "725632d0",
  null
  
)

export default component.exports