
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { authService } from "@/services/AuthService";

@Component({
  components: {},
})
export default class Login extends Vue {
  public username: string = "";
  public password: string = "";
  public error = false;
  public loading = false;
  public passwordFieldType = "password";

  public form: any = this.$form.createForm(this);

  public async mounted() {
    let externalToken = this.$route.query.token;
    if (externalToken) authService.setExternalToken(externalToken.toString());
    if (authService.storeid > 0) {
      this.$router.replace({ name: "store" });
    } else if (authService.admin) {
      var page = "Stores";
      this.$router.replace({
        name: "admin",
        params: { page },
      });
    }
  }

  public async login(e: any) {
    this.loading = true;
    e.preventDefault();

    await authService.Login(this.username, this.password);
    if (authService.admin) {
      this.$router.replace({ name: "admin" });
    } else if (authService.store) {
      this.$router.replace({ name: "store" });
    } else {
      this.error = true;
    }
    this.loading = false;
  }

  public togglePasswordFieldType() {
    this.passwordFieldType =
      this.passwordFieldType === "password" ? "text" : "password";
  }
}
