
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import * as ST from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import { restaurantApi } from "@/services/RApiService";
import TextEdit from "@/components/controls/text-edit.vue";
import { ProductionStation, ProductionProduct } from "@/api/restaurant";
import { IsNewRow } from "@/components/controls/s-table/exports";


@Component({
  components: {
    STable,
  },
})
export default class EditProductionStation extends Vue {
  

  productionStation: ProductionStation = {
    id: -1,
    text: "kategori",
    products: [],
  };
  tableSettings: ST.Settings<ProductionProduct> = {
    dataSource: this.productionStation.products,
    pagination: false,
    showNewRow: true,
    newRowText: "Ny produktionsvara",
    newRowInit: () => ({id: -1}),
    // recordsUpdated: async (records) => this.AddProductionProduct(records),
    loading: false,
    actions: {
      remove: {
        icon: "delete",
        action: (record) => {
          // this.deleteRecord(record);
        },
      },
    },
    columns: [
      {
        title: "Vara",
        width: 400,
        edit: TextEdit,
        editData: (record) => ({
          editable: true,
          editValue: record.text,
          dataBinding: {
            propertyName: "text",
          },
          updateEmitter: "valueChanged",
          props: {
            defaultOpen: !IsNewRow(record),
            defaultValue: record.text,
            placeHolder: "Namn",
          },
        }),
      },
    ],
  };
  confirmLoading = false;
  loading = true;

  routes: any[] = [
    {
      path: "index",
      breadcrumbName: "Produktionsstationer",
    },
  ];

  async mounted() {
    let id = this.$route.params.id;
    let result = await restaurantApi.getProductionStation(parseInt(id));
    this.productionStation = result.data;
    this.tableSettings.dataSource = this.productionStation.products
    // let result = await storeApi.getCategory(parseInt(id));

    // this.category = result.data;
    // this.departments.push(...(await (await storeApi.getDepartmentsWeb()).data));

    // if (this.category.departments)
    //   this.pickedDepartments.push(
    //     ...this.category.departments.map((c) => c.id)
    //   );
    this.loading = false;
  }

 
  get text() {
    if (!this.productionStation) {
      return "produktionsstation";
    }
    return this.productionStation.text!;
  }
  set text(value: string) {
    if (this.productionStation) {
      this.productionStation.text = value;
    }
  }
  // AddProductionProduct( products: ProductionProduct[]){
  //   console.log(products);
  //   console.log(this.productionStation)
  //   console.log(this.productionStation.products)
  // }

  async Save(e: any) {
    this.confirmLoading = true;
    if (this.productionStation) {
      await restaurantApi.postProductionStation(this.productionStation);
      this.confirmLoading = false;

      this.$notification.open({
        message: "Sparat",
        description: "Produktionsstation " + this.productionStation.text + " har sparats",
        placement: "bottomRight",
        duration: 4,
      });
    }
    this.$router.go(-1);
  }
}
