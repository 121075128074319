
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ItemPickerDialog extends Vue {
  $refs!: {
    mainComponent: any;
  };
  @Prop()
  visible: boolean = false;
  @Prop()
  data!: Array<any>;
  @Prop()
  displayProperty!: string;
  @Prop()
  title: string = "Välj";
  @Prop()
  columnTitle: string = "Valbara";
  rowclass = () => "rowclass";
  confirmLoading = false;
  loading = false;

  acceptedValue: any;
  columns = [
    {
      width: "200",
      title: this.columnTitle,
      dataIndex: this.displayProperty,
      key: this.displayProperty,
    },
  ];
  customRow = (record: any) => {
    return {
      props: {},
      on: {
        click: (event: any) => {
          this.EmitOK(record);
        }, // click row
      },
    };
  };
  public async mounted() {
    // let result = await storeApi.getCategories();
    // let data: Category[] = new Array();
    // data.push(...result.data);
    // this.data = data;
    // this.loading = false;
  }
  public EmitOK(record: any) {
    this.$emit("update", record);
  }

  public ModalCancel() {
    this.$emit("cancel");
  }
}
