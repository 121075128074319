
class TaskPropertyAdapter {
    constructor() { }
    public fromTaskPropertyType(dayType: number): AdapterTaskPropertyType {
      if (dayType == 1) {
        return AdapterTaskPropertyType.Bold;
      } else if (dayType == 2) {
        return AdapterTaskPropertyType.Caption;
      }
      return AdapterTaskPropertyType.Caption;
    }
    public toTasPropertyType(dayType: AdapterTaskPropertyType): number {
      if (dayType == AdapterTaskPropertyType.Bold) {
        return TaskProperty.bold;
      }    if (dayType == AdapterTaskPropertyType.Caption) {
        return TaskProperty.caption;
      }
      return TaskProperty.caption;
    }
    public getOptions() {
      return Object.values(AdapterTaskPropertyType).filter((key) => isNaN(Number(key)));
    }
    public enumToBits()
    {
      return Object.keys(TaskProperty).map(Number).filter(Boolean);
    }
  }

export enum TaskProperty{
    bold = 1,
    caption = 2
}

export enum AdapterTaskPropertyType {
    Bold = "Fetstil",
    Caption = "Rubrik",
  }

export default new TaskPropertyAdapter();