
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { storeApi } from "@/services/ApiService";
import * as ST from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import TextEdit from "@/components/controls/text-edit.vue";
import TaskLastDone from "@/components/store/taskList/task-last-done.vue";
import { Task } from "@/api/api";

@Component({
  components: { STable },
})
export default class StoreChecklist extends Vue {
  checklistId: number = -1;
  dataSource: Task[] = [];

  public async mounted() {
    this.checklistId = parseInt(this.$route.params.id);
    await this.loadChecklistData();
  }
  public async beforeRouteUpdate(to: any, from: any, next: any) {
    this.checklistId = parseInt(to.params.id);
    await this.loadChecklistData();
    next();
  }

  public async beforeRouteLeave(to: any, from: any, next: any) {
    next();
  }
  tableSettings: ST.Settings<Task> = {
    dataSource: this.dataSource,
    pagination: false,
    showNewRow: false,
    sort: {
      sortProperty: "sortOrder",
      enableSort: false,
    },
    loading: true,
    actions: {
      showHistory: {
        icon: "history",
        action: (record) => {},
        visible: (record) => false,
        tooltip: (record) => "Historik (Ej implementerad ännu)"
      },
    },
    columns: this.createColumns(),
  };

  private createColumns(): ST.Column<Task>[] {
    return [
      {
        title: "Namn",
        edit: TextEdit,
        editData: (record) => ({
          editable: false,
          dataBinding: {
            propertyName: "text",
          },
          props: {
            defaultValue: record.text,
          },
        }),
      },
      {
        title: "Senast utförd",
        edit: TaskLastDone,
        editData: (record) => ({
          editable: false,
          immediate: true,
          dataBinding: {
            propertyName: "N/A",
          },
          props: {
            record: record,
          },
        }),
      },
    ];
  }

  public async loadChecklistData() {
    if (!this.checklistId) return;
    this.tableSettings.loading = true;
    this.dataSource.length = 0;
    var result = await storeApi.getTasks(this.checklistId);
    this.dataSource.push(...result.data);
    this.tableSettings.loading = false;
  }
}
