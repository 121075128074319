
import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class NewRow extends Vue {
  click(e: any) {
    this.$emit("click", e);
  }
}
