
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { adminApi } from "@/services/ApiService";
import * as ST from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import { ResponseStore } from "@/api/api";
import TextEdit from "@/components/controls/text-edit.vue";

@Component({ components: { STable } })
export default class AdminStores extends Vue {
  dataSource: ResponseStore[] = [];
  tableSettings: ST.Settings<ResponseStore> = {
    dataSource: this.dataSource,
    pagination: false,
    showNewRow: false,
    loading: true,
    sort: {
      sortProperty: "text",
      enableSort: false,
    },
    filter: {
      filterString: "",
      filterProperties: ["text", "password"],
    },
    actions: {
      edit: {
        icon: "eye",
        action: (record) => {
          this.browse(record);
        },
        visible: (record) => true,
      },
    },
    columns: [
      {
        title: "Namn",
        edit: TextEdit,
        width: 350,
        editData: (record) => ({
          editable: true,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "text",
          },
          props: {
            defaultValue: record.text,
          },
        }),
      },
      {
        title: "Inloggningsnamn",
        edit: TextEdit,
        width: 350,
        editData: (record) => ({
          editable: true,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "username",
          },
          props: {
            defaultValue: record.username,
          },
        }),
      },
      {
        title: "Lösenord",
        edit: TextEdit,
        width: 350,
        editData: (record) => ({
          editable: true,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "password",
          },
          props: {
            defaultValue: record.password,
          },
        }),
      },
    ],
  };

  onSearch(e: any) {
    if (!this.tableSettings.filter) return;

    if (e.srcElement.value) {
      this.tableSettings.filter.filterString = e.srcElement.value;
    } else this.tableSettings.filter.filterString = "";
  }

  public async mounted() {
    let response = await adminApi.getStores();

    if (response.data.stores) {
      response.data.stores.forEach((element) => {
        this.dataSource.push(element);
      });
    }
    this.tableSettings.loading = false;
  }

  private async SaveStore(store: ResponseStore) {
    let result = await adminApi.postStore({
      id: store.id,
      text: store.text,
      username: store.username,
      password: store.password,
    });
    if (result.data.text) {
      this.notifySave(result.data.text);
    }
  }

  public browse(record: ResponseStore) {
    var storeId = record.id.toString();
    this.$router.push({ path: `/admin/stores/${storeId}/contacts` }); // -> /user/123
  }

  public notifySave(name: string) {
    this.$notification.open({
      message: "Sparade butik",
      description: name,
      placement: "bottomRight",
      duration: 4,
    });
  }
}
