
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { storeApi } from "@/services/ApiService";
import { Category, CategoryTypeEnum } from "@/api/api";
import ColorPicker from "@/components/store/category/color.vue";
import EditableCellCategoryType from "@/components/store/category/controls/EditableCellCategoryType.vue";

@Component({
  components: {},
})
export default class ExistingCategoriesDialog extends Vue {
  $refs!: {
    mainComponent: any;
  };
  @Prop()
  visible: boolean = false;
  confirmLoading = false;
  loading = true;
  data: Category[] = [];
  acceptedValue: Category[] = [];
  selectedRowsArray: string[] = [];
  rowSelection = {
    selectedRowKeys: this.selectedRowsArray,
    onChange: (selectedRowKeys: any, selectedRows: Category[]) => {
      this.selectedRowsArray.length = 0;
      this.selectedRowsArray.push(...selectedRowKeys);
      this.acceptedValue.length = 0;
      selectedRows.forEach((element: Category) => {
        this.acceptedValue.push(element);
      });
    },
    onSelect: (record: any, selected: any, selectedRows: any) => {},
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {},
  };
  columns = [
    {
      width: "200",
      title: "Kategori",
      customRender: (record: Category) => {
        return {
          children: this.$createElement(ColorPicker, {
            props: {
              color: record.defaultColor,
              text: record.name,
            },
            on: {},
          }),
        };
      },
    },
  ];
  public async mounted() {
    let result = await storeApi.getCategories();
    let data: Category[] = new Array();
    data.push(...result.data);
    this.data = data;
    this.loading = false;
  }
  public ModalOk() {
    this.selectedRowsArray.splice(0);
    this.$emit("update", this.acceptedValue);
  }

  public ModalCancel() {
    this.selectedRowsArray.splice(0);
    this.$emit("cancel");
  }
}
