
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import IRecord from "./IRecord";

@Component
export default class EditableCellText extends Vue {
  $refs!: {
    theinput: any;
  };

  @Prop()
  record!: IRecord;
  @Prop()
  value!: string;
  @Prop()
  focus!: boolean;

  valueChanged: number = 0;
  newValue!: string;
  editmode: boolean = false;

  mounted() {
    if (
      this.focus &&
      this.record &&
      this.record.RowEdit &&
      this.record.NewRow
    ) {
      this.$refs.theinput.focus();
    }
  }
  get NewRowRecord() {
    if (this.record) {
      return this.record.NewRow;
    }
    return false;
  }

  pressEnter(e: any, j: any) {
    if (!this.record.NewRow) {
      this.acceptEdit();
    }
  }

  get editvalue() {
    this.valueChanged;
    if (this.newValue) {
      return this.newValue;
    } else if (this.record) {
      return this.value;
    } else return "";
  }

  change(e: any) {
    this.newValue = e.srcElement.value;
    if (this.record.NewRow) {
      this.$emit("update", this.newValue);
    }
  }

  editToggle() {
    this.editmode = !this.editmode;
    if (this.editmode) {
      this.$nextTick(() => this.$refs.theinput.focus());
    }
  }

  cancelEdit() {
    this.newValue = "";
    this.editmode = !this.editmode;
    this.valueChanged++;
  }

  acceptEdit() {
    this.editmode = !this.editmode;
    this.valueChanged++;
    this.$emit("update", this.newValue);
  }
}
