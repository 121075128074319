
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { restaurantApi } from "@/services/RApiService";
import LoadingContent from "@/components/store/category/loading-content.vue";
import { Category, IdTextSort, Product } from "@/api/restaurant";
import { CategoryProduct } from "@/api";

@Component({
  components: {
    LoadingContent,
  },
})
export default class EditRestaurantCategory extends Vue {
  $refs!: {
    currentComponent: any;
  };
  pickedDepartments: number[] = [];
  category: Category = {
    id: -1,
    text: "kategori",
    alphabeticalSort: true,
    products: [],
  };
  completeUrval: Product[] = [];
  urval: Product[] = [];
  categoryProduts: IdTextSort[] = [];
  urvalUpdated: number = 0;
  valdaUpdated: number = 0;

  filterUrval: boolean = true;
  filterUrvalText: string = "";
  filterValdaText: string = "";

  currentComponent?: any = undefined;
  confirmLoading = false;
  loading = true;

  pager: any = { pageSize: 50 };

  routes: any[] = [
    {
      path: "index",
      breadcrumbName: "Kategorier",
    },
  ];
  columnsUrval = [
    {
      dataIndex: "text",
      colSpan: 2,
      slots: {
        title: "urvalTitle",
      },
    },
    {
      width: "40px",
      colSpan: 0,
      slots: {
        title: "operationsTitle",
      },
      scopedSlots: { customRender: "operations" },
    },
  ];
  columnsValda = [
    {
      dataIndex: "text",
      colSpan: 2,
      slots: {
        title: "varaTitle",
      },
    },
    {
      colSpan: 0,
      width: "72px",
      scopedSlots: { customRender: "operations" },
    },
  ];

  async mounted() {
    let id = this.$route.params.id;
    let result = await restaurantApi.getCategory(parseInt(id));
    let urval = await restaurantApi.getProducts();

    this.completeUrval = urval.data;

    let filtered = this.completeUrval.filter(
      (c: Product) => !c.categories || c.categories.length == 0
    );

    this.pager = { pageSize: 20, current: 1, total: filtered.length };
    this.urval = filtered.slice(0, 20);

    this.category = result.data;
    this.categoryProduts = this.category!.products!.sort(
      (a: any, b: any) => a.sortOrder - b.sortOrder
    );

    for (let i = 0; i < this.category!.products!.length; i++) {
      this.category!.products![i].sortOrder = i;
    }

    this.loading = false;
  }

  getRowKey(row:any){
    return row.id;
  }

  getRowClassName(_: any, index: any) {
    return index % 2 === 0 ? "table-row-light" : "table-row-dark";
  }

  get componentCategory() {
    return this.category;
  }

  get text() {
    if (!this.category) {
      return "kategori";
    }
    return this.category.text;
  }
  set text(value: string | undefined) {
    if (this.category) {
      this.category.text = value;
    }
  }

  get visningslage() {
    return "";
  }
  set visningslage(value: any) {}

  get valda() {
    this.valdaUpdated;
    if (this.categoryProduts) {
      if (this.filterValdaText) {
        return this.categoryProduts.filter((c: any) =>
          c.text.toLowerCase().includes(this.filterValdaText.toLowerCase())
        );
      }

      return this.categoryProduts;
    }
    return [];
  }

  handleSortChanged(value: any) {
    this.category.alphabeticalSort = value.target.checked;
  }

  public onSearchChanged(e: any) {
    if (!e.data && e.srcElement._value.length == 1) {
      this.filterUrvalText = e.data;
      this.handleChangeUrval(this.pager);
    }
  }

  public onSearchUrval(e: any) {
    this.filterUrvalText = e;
    this.handleChangeUrval(this.pager);
  }

  public onSearchValdaChanged(e: any) {
    if (!e.data && e.srcElement._value.length == 1) {
      this.filterValdaText = e.data;
      this.valdaUpdated++;
    }
  }
  public onSearchValda(e: any) {
    this.filterValdaText = e;
    this.valdaUpdated++;
  }

  public handleChangeUrval(pagination: any) {
    let filtered: Product[];
    if (this.filterUrval) {
      filtered = this.completeUrval.filter(
        (c: Product) => c.categories!.length == 0
      );
    } else {
      filtered = this.completeUrval;
    }
    if (this.filterUrvalText) {
      filtered = filtered.filter((c) =>
        c.text!.toLowerCase().includes(this.filterUrvalText.toLowerCase())
      );
    }
    filtered = filtered.filter(
      (c: Product) => !this.categoryProduts.some((d) => d.id == c.id)
    );

    let skip = pagination.pageSize * (pagination.current - 1);
    this.urval = filtered.slice(skip, skip + 20);
    pagination.total = filtered.length;
    this.pager = pagination;
  }

  public Add(record: any) {
    if (
      this.categoryProduts.filter((c: any) => c.id == record.id).length == 0
    ) {
      if (this.category) {
        this.categoryProduts.push({
          id: record.id,
          text: record.text,
          sortOrder: this.categoryProduts.length,
        });
      }
    }
    this.handleChangeUrval(this.pager);
    this.valdaUpdated++;
  }

  public Remove(record: IdTextSort) {
    let index = this.categoryProduts.indexOf(record);
    this.categoryProduts.splice(index, 1);
    var target = this.completeUrval.find(c=>c.id === record.id);
    if(target)
    {
      var categoryIndex = target.categories?.findIndex(c=>c.id == this.category.id);
      (categoryIndex !== null && categoryIndex !== undefined && categoryIndex >= 0)
      {
        target.categories?.splice(categoryIndex!, 1);
      }
    }
    this.handleChangeUrval(this.pager);
    this.valdaUpdated++;
    this.urvalUpdated++;
  }

  public Up(record: any) {
    var x = Array.from(document.getElementsByClassName("ant-tooltip"));
    x.forEach((c: any) => (c.style.display = "none"));
    var curIndex = this.categoryProduts.indexOf(record);
    if (curIndex == 0) return;
    var prev = this.categoryProduts[curIndex - 1];
    var prevSort = prev.sortOrder;
    prev.sortOrder = record.sortOrder;
    record.sortOrder = prevSort;
    this.categoryProduts = this.categoryProduts.sort(
      (a: any, b: any) => a.sortOrder - b.sortOrder
    );
    this.valdaUpdated++;
  }

  public Down(record: any) {
    var x = Array.from(document.getElementsByClassName("ant-tooltip"));
    x.forEach((c: any) => (c.style.display = "none"));
    var curIndex = this.categoryProduts.indexOf(record);
    if (curIndex >= this.categoryProduts.length - 1) return;
    var next = this.categoryProduts[curIndex + 1];
    var nextSort = next.sortOrder;
    next.sortOrder = record.sortOrder;
    record.sortOrder = nextSort;
    this.categoryProduts = this.categoryProduts.sort(
      (a: any, b: any) => a.sortOrder - b.sortOrder
    );
    this.valdaUpdated++;
  }

  async Save(e: any) {
    this.confirmLoading = true;
    if (this.category) {
      await restaurantApi.postCategory(this.category);
      this.confirmLoading = false;

      this.$notification.open({
        message: "Sparat",
        description: "Kategorin " + this.category.text + " har sparats",
        placement: "bottomRight",
        duration: 4,
      });
    }
    this.$router.go(-1);
  }
}
