
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { restaurantApi } from "@/services/RApiService";
import STable from "@/components/controls/s-table/sTable.vue";
import * as ST from "@/components/controls/s-table/exports";
import { Product, ProductOptions } from "@/api/restaurant/api";
import TextEdit from "@/components/controls/text-edit.vue";
import { IsNewRow } from "@/components/controls/s-table/exports";

@Component({
  components: {
    STable,
  },
})
export default class EditRestaurantProduct extends Vue {
  $refs!: {
    currentComponent: any;
  };
  pickedDepartments: number[] = [];
  product: Product = {
    id: -1,
    text: "produkt",
    productOptions: [],
  };
  currentComponent?: any = undefined;
  loading = true;

  routes: any[] = [
    {
      path: "index",
      breadcrumbName: "Produkter",
    },
  ];
  dataSource: ProductOptions[] = [];

  tableSettings: ST.Settings<ProductOptions> = {
    dataSource: this.dataSource,
    pagination: false,
    showNewRow: true,
    newRowText: "Nytt tillval",
    newRowInit: () => ({}),
    recordsUpdated: async (records) => this.postRecord(records[0]),
    loading: false,
    actions: {
      remove: {
        icon: "delete",
        confirmation: (record) =>
          `Är du säker på att du vill ta bort ${record.text}`,
        action: (record) => {
          this.deleteRecord(record);
        },
      },
    },
    columns: [
      {
        title: "Tillval",
        width: 400,
        edit: TextEdit,
        editData: (record) => ({
          editable: true,
          editValue: record.text,
          dataBinding: {
            propertyName: "text",
          },
          updateEmitter: "valueChanged",
          props: {
            defaultOpen: !IsNewRow(record),
            defaultValue: record.text,
            placeHolder: "Namn",
          },
        }),
      },
      {
        title: "Pris",
        width: 400,
        edit: TextEdit,
        editData: (record) => ({
          editable: true,
          editValue: record.price,
          dataBinding: {
            propertyName: "price",
          },
          updateEmitter: "valueChanged",
          props: {
            defaultOpen: !IsNewRow(record),
            defaultValue: record.price,
            placeHolder: "Pris",
          },
        }),
      },
    ],
  };

  async mounted() {
    let id = this.$route.params.id;
    let result = await restaurantApi.getProduct(parseInt(id));
    this.product = result.data;
    if (result.data.productOptions) {
      for (var v of result.data.productOptions) this.dataSource.push(v);
    }
    this.loading = false;
  }

  get text() {
    if (!this.product?.text) {
      return "product";
    }
    return this.product.text;
  }

  set text(value: string) {
    if (this.product) {
      this.product.text = value;
    }
  }

  get price(): number {
    if (!this.product?.price) {
      return 0;
    }
    return this.product.price;
  }
  set price(value: number) {
    this.product!.price = value;
  }

  postRecord(record: ProductOptions) {
    const index = this.product.productOptions!.indexOf(record, 0);
    if (index == -1) {
      this.product.productOptions?.push(record);
    }
  }

  deleteRecord(record: ProductOptions) {
    var index = this.product.productOptions!.indexOf(record, 0);
    if (index > -1) {
      this.product.productOptions!.splice(index, 1);
    }
    index = this.dataSource.indexOf(record, 0);
    if (index > -1) {
      this.dataSource.splice(index, 1);
    }
  }

  async Save(e: any) {
    if (this.product) {
      await restaurantApi.postProduct(this.product);

      this.$notification.open({
        message: "Sparat",
        description: "Produkten " + this.product.text + " har sparats",
        placement: "bottomRight",
        duration: 4,
      });
    }
    this.$router.go(-1);
  }
}
