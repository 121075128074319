import axios from "axios";
import { Configuration } from "@/api";
import { LoginApi, StoreApi } from "@/api/api";
import { SysadminApi } from "@/api/api";

export let axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === "production"
    // ? "https://varutesting.azurewebsites.net/api/"
    ? "https://varufront8-gwcuetdhf6e0eae8.z01.azurefd.net/api/"
    // ? "https://infotabv2-prod.azurewebsites.net/api/"
    : "http://localhost:7071/api/",
});
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.token = localStorage.getItem("token");
    return config;
  },
  (error) => Promise.reject(error)
);

export let configuration = new Configuration({
  basePath: process.env.NODE_ENV === "production"
    // ? "https://varutesting.azurewebsites.net"
    ? "https://varufront8-gwcuetdhf6e0eae8.z01.azurefd.net/api"
    // ? "https://infotabv2-prod.azurewebsites.net"
    : "http://localhost:7071/api",
});

export let loginApi = new LoginApi(configuration, undefined, axiosInstance);
export let adminApi = new SysadminApi(configuration, undefined, axiosInstance);
export let storeApi = new StoreApi(configuration, undefined, axiosInstance);
export let baseApiURL = process.env.NODE_ENV === "production"
  // ? "https://varutesting.azurewebsites.net/api/"
  ? "https://varufront8-gwcuetdhf6e0eae8.z01.azurefd.net/api/"
  // ? "https://infotabv2-prod.azurewebsites.net/api/"
  : "http://localhost:7071/api/";


//Old "https://infotabv2-prod.azurewebsites.net/api/"
//"https://infotabv2-prod.azurewebsites.net"
//"https://varutesting.azurewebsites.net"