
import Vue from "vue";
import Swatches from "vue-swatches";
import {Component, Prop} from "vue-property-decorator";
// Import the styles too, globally
import "vue-swatches/dist/vue-swatches.min.css";

@Component({components: {Swatches}})
export default class ColorPicker extends Vue {
    @Prop({default: "#000000"})
    color!: string;

    @Prop()
    text!: string;

    get thiscolor(): string {
        return this.color;
    }

    set thiscolor(color: string) {
        this.$emit("update", color);
    }
}
