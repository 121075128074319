import { Product, IdText } from "@/api/api";

export default class product implements Product {
  id!: number;
  title!: string;
  plucode!: string;
  imageid!: number;
  barcode!: string;
  inactive!: boolean;
  categories!: idText[];
  eco!: boolean;
  constructor() {
    this.id = -1;
    this.categories = [];
  }
}

export class idText implements IdText {
  id!: number;
  text!: string;
}

export class pcategory {
  id!: number;
  text!: string;
  number!: string;
}
