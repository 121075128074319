
import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";
import { storeApi } from "@/services/ApiService";
import { Cropper } from "vue-advanced-cropper";
import ProductRecord from "./ProductRecord";

@Component({
  components: {
    Cropper,
  },
})
export default class ImageCell extends Vue {
  $refs!: {
    cropper: any;
    fileinput: any;
  };
  @Prop()
  record!: ProductRecord;

  loading: boolean = true;
  base64!: any;

  update: number = 0;
  previewVisible: boolean = false;
  previewImage!: any;
  originalImage!: any;

  fileList: any = [];

  get Title() {
    if (this.record) {
      return this.record.Product.title;
    }
    return "";
  }
  public GetImageBytes() {
    return this.previewImage;
  }

  async mounted() {
    if (!this.record.Product.imageid) {
      this.loading = false;
      return;
    }
    if (this.record.Product.imageid) {
      let result = await storeApi.getThumbnail(this.record.Product.imageid);
      this.base64 = result.data;
      this.fileList.push({
        uid: -1,
        name: "image.png",
        status: "done",
        url: this.dataUrl(),
      });

      this.loading = false;
      this.update++;
    }
  }

  hasImage() {
    return this.record.Product.imageid;
  }

  dataUrl() {
    this.update;
    if (!this.base64) return;

    return "data:image/png;base64," + this.base64;
  }

  getPreviewImage() {
    this.update;
    if (this.previewImage) {
      return this.previewImage;
    }

    if (this.record.Product.imageid) {
      let response = storeApi.getImage(this.record.Product.imageid);

      response.then((c) => {
        this.previewImage = "data:image/png;base64," + c.data;
        this.originalImage = "data:image/png;base64," + c.data;
        this.update++;
      });
    }
  }

  handleCropOk() {
    const { coordinates, canvas } = this.$refs.cropper.getResult();
    this.fileList = [
      {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: canvas.toDataURL(),
      },
    ];
    this.previewImage = canvas.toDataURL();
    this.originalImage = this.previewImage;
    this.previewVisible = false;
    this.upload();
  }
  async upload() {
    console.log(this.record.Product.id);

    if (this.record.NewRowRecord) {
      return;
    }

    let result = await storeApi.processImage(
      this.record.Product.id,
      this.previewImage
    );

    this.$emit("update", result.data.imageid);
  }

  isBase64(str: string) {
    if (str === "" || str.trim() === "") {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }

  handleBeforeUpload(file: File) {
    let fileReader = new FileReader();
    fileReader.onload = (c) => {
      if (c.target) {
        this.previewImage = c.target.result;
        this.previewVisible = true;
      }
    };
    fileReader.readAsDataURL(file);
    return false;
  }

  async handleRemove() {
    if (this.record.Product.imageid) {
      await storeApi.deleteImage(this.record.Product.imageid);
      this.fileList = [];
      this.$emit("update", null);
      this.previewVisible = false;
    }
  }

  openFileDialog() {
    if (!this.$refs.fileinput.onchange) {
      this.$refs.fileinput.onchange = (e: any) => {
        this.previewVisible = false;
        this.handleBeforeUpload(e.target.files[0]);
      };
    }
    this.$refs.fileinput.click();
  }

  filechange(e: any) {
    var file = e.target.files[0];

    var reader = new FileReader();
    reader.onload = (readerEvent: any) => {
      this.previewImage = readerEvent.target.result;
    };

    reader.readAsDataURL(e.target.files[0]);
  }

  handlePreview() {
    this.previewVisible = true;
  }

  handleCropCancel() {
    this.previewImage = this.originalImage;
    this.previewVisible = false;
  }
}
