
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { authService } from "@/services/AuthService";
import Stores from "./admin/stores.vue";
import NavBreadcrumbs from "@/components/admin/navBreadcrumbs.vue";
import EditStore from "./admin/editStore.vue";
import Menu from "@/components/admin/menu.vue";

@Component({
  components: {
    Stores,
    NavBreadcrumbs,
    EditStore,
    Menu,
  },
})
export default class Admin extends Vue {
  private currentComponent: string = Stores.name;

  public logout() {
    authService.Logout();
    this.$router.replace({ name: "login" });
  }
}
