import { CategoryTypeEnum } from "@/api";

class DisplayTypeAdapter {
  constructor() { }
  public fromProductDisplayType(type: number): ProductDisplayTypeAdapter {
    if (type == 0) {
      return ProductDisplayTypeAdapter.Bild;
    } else if (type == 1) {
      return ProductDisplayTypeAdapter.Text;
    } else if (type == 2) {
      return ProductDisplayTypeAdapter.Streckkod;
    } else if (type == 3) {
      return ProductDisplayTypeAdapter.TextKat;
    }
    return ProductDisplayTypeAdapter.BildStreckkod;
  }
  public toProductDisplayType(type: ProductDisplayTypeAdapter): number {
    if (type == ProductDisplayTypeAdapter.Bild) {
      return 0;
    } else if (type == ProductDisplayTypeAdapter.Text) {
      return 1;
    } else if (type == ProductDisplayTypeAdapter.Streckkod) {
      return 2;
    } else if (type == ProductDisplayTypeAdapter.TextKat) {
      return 3;
    }
    return 4;
  }
  public fromPdfDisplayType(type: number): PdfDisplayTypeAdapter {
    return PdfDisplayTypeAdapter.Standard;
  }
  public toPdfDisplayType(type: PdfDisplayTypeAdapter): number {
    return 0;
  }
  public fromHtmlDisplayType(type: number): HtmlDisplayTypeAdapter {
    return HtmlDisplayTypeAdapter.Standard;
  }
  public toHtmlDisplayType(type: HtmlDisplayTypeAdapter): number {
    return 0;
  }
  public fromAppLinkDisplayType(type: number): AppLinkDisplayTypeAdapter {
    if (type == 0) {
      return AppLinkDisplayTypeAdapter.Qmanager;
    } else if (type == 1) {
      return AppLinkDisplayTypeAdapter.Ipool
    }
    else if (type == 2)
      return AppLinkDisplayTypeAdapter.SoftOne;
    else if (type == 3)
      return AppLinkDisplayTypeAdapter.IpoolEmp;
    else if (type == 4)
      return AppLinkDisplayTypeAdapter.FreeTrailer;
    return AppLinkDisplayTypeAdapter.Qmanager;
  }
  public toAppLinkDisplayType(type: AppLinkDisplayTypeAdapter): number {
    if (type == AppLinkDisplayTypeAdapter.Qmanager) {
      return 0;
    } else if (type == AppLinkDisplayTypeAdapter.Ipool) {
      return 1;
    } else if (type == AppLinkDisplayTypeAdapter.SoftOne) {
      return 2;
    } else if (type == AppLinkDisplayTypeAdapter.IpoolEmp) {
      return 3;
    } else if (type == AppLinkDisplayTypeAdapter.FreeTrailer) {
      return 4;
    }
    return 0;
  }
  public fromCreditCustomerDisplayType(type: number): CreditCustomerDisplayTypeAdapter {
    if (type == 0) {
      return CreditCustomerDisplayTypeAdapter.Text;
    }
    else if (type == 1) {
      return CreditCustomerDisplayTypeAdapter.TextKat;
    }
    return CreditCustomerDisplayTypeAdapter.Text;
  }
  public toCreditCustomerDisplayType(type: CreditCustomerDisplayTypeAdapter): number {
    if (type == CreditCustomerDisplayTypeAdapter.Text) {
      return 0;
    } else if (type == CreditCustomerDisplayTypeAdapter.TextKat) {
      return 1;
    }
    return 0;
  }
  public fromAny(categoryType: CategoryTypeEnum, displayType: number) {
    switch (categoryType) {
      case CategoryTypeEnum.NUMBER_0:
        return this.fromProductDisplayType(displayType);
      case CategoryTypeEnum.NUMBER_1:
        return this.fromHtmlDisplayType(displayType);
      case CategoryTypeEnum.NUMBER_2:
        return this.fromPdfDisplayType(displayType);
      case CategoryTypeEnum.NUMBER_3:
        return this.fromCreditCustomerDisplayType(displayType);
      case CategoryTypeEnum.NUMBER_7:
        return this.fromAppLinkDisplayType(displayType);
    }
  }
  public toAny(categoryType: CategoryTypeEnum, displayType: any): number {
    switch (categoryType) {
      case CategoryTypeEnum.NUMBER_0:
        return this.toProductDisplayType(displayType);
      case CategoryTypeEnum.NUMBER_1:
        return this.toHtmlDisplayType(displayType);
      case CategoryTypeEnum.NUMBER_2:
        return this.toPdfDisplayType(displayType);
      case CategoryTypeEnum.NUMBER_3:
        return this.toCreditCustomerDisplayType(displayType);
      case CategoryTypeEnum.NUMBER_7:
        return this.toAppLinkDisplayType(displayType);
    }
    return 0;
  }
  public getOptions(categoryType: CategoryTypeEnum) {
    switch (categoryType) {
      case CategoryTypeEnum.NUMBER_0:
        return Object.values(ProductDisplayTypeAdapter);
      case CategoryTypeEnum.NUMBER_1:
        return Object.values(PdfDisplayTypeAdapter);
      case CategoryTypeEnum.NUMBER_2:
        return Object.values(HtmlDisplayTypeAdapter);
      case CategoryTypeEnum.NUMBER_3:
        return Object.values(CreditCustomerDisplayTypeAdapter);
      case CategoryTypeEnum.NUMBER_7:
        return Object.values(AppLinkDisplayTypeAdapter);
    }
  }
}
export default new DisplayTypeAdapter();
export enum ProductDisplayTypeAdapter {
  Bild = "Bild",
  BildStreckkod = "Bild & Streckkod",
  Text = "Text",
  TextKat = "Text: Kategoriserad",
  Streckkod = "Streckkod"
}
export enum PdfDisplayTypeAdapter {
  Standard = "Standard",
}
export enum HtmlDisplayTypeAdapter {
  Standard = "Standard",
}
export enum CreditCustomerDisplayTypeAdapter {
  Text = "Text",
  TextKat = "Text: Kategoriserad"
}
export enum AppLinkDisplayTypeAdapter {
  Qmanager = "Q-manager",
  Ipool = "ipool",
  SoftOne = "SoftOne GO",
  IpoolEmp = "ipool employee",
  FreeTrailer = "Freetrailer"
}

