import "./router/componentHooks"; // <-- Needs to be first
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import {
  Row,
  Col,
  Layout,
  Alert,
  Input,
  Button,
  Icon,
  Form,
  Table,
  Popconfirm,
  Spin,
  Menu,
  Modal,
  Select,
  Tooltip,
  Checkbox,
  Tag,
  notification,
  Skeleton,
  Upload,
  DatePicker,
  Dropdown,
  Breadcrumb,
  Popover,
  PageHeader,
  Descriptions
} from "ant-design-vue";

import "ant-design-vue/dist/antd.css";

Vue.use(Alert);
Vue.use(Button);
Vue.use(Input);
Vue.use(Row);
Vue.use(Col);
Vue.use(Layout);
Vue.use(Icon);
Vue.use(Form);
Vue.use(Table);
Vue.use(Popconfirm);
Vue.use(Spin);
Vue.use(Menu);
Vue.use(Modal);
Vue.use(Select);
Vue.use(Tooltip);
Vue.use(Checkbox);
Vue.use(Tag);
Vue.use(Skeleton);
Vue.use(Upload);
Vue.use(DatePicker);
Vue.use(Dropdown);
Vue.use(Breadcrumb);
Vue.use(Popover);
Vue.use(PageHeader);
Vue.use(Descriptions);
Vue.prototype.$notification = notification;

Vue.config.productionTip = false;

export const Bus = new Vue();

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
