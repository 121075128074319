
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import colorpicker from "@/components/store/category/color.vue";
import product from "@/entities/product";

@Component({components: {colorpicker}})
export default class UserFieldEdit extends Vue {
    @Prop()
    textContent?: any;
    @Prop()
    dataSource!: any;
    @Prop()
    record: any;
    modalOpen: boolean = false;
    userFieldForm: any = {};
    backgroundColor: string = "#000000"
    topic: boolean = false;

//    getDefault() {
//        if (this.defaultValue) return this.defaultValue;
//        let result = new Array();
//        return result;
//    }

    mounted() {
    }

    handleChange(value: any) {
        if (value) this.$emit("valueChanged", value.srcElement.value);
    }

    handleColorUpdate(color: string) {
        this.backgroundColor = color;
    }

    removeSelectedColor() {
        this.backgroundColor = "#000000";
    }

    openModal() {
        this.userFieldForm.fields = this.dataSource.map((sourceItem: any) => {
            let userField = this.record.userFields.find((userField: any) => userField.id === sourceItem.id);
            return {
                id: sourceItem.id,
                name: sourceItem.name,
                value: userField ? userField.value : ''
            };
        });
        this.topic = this.record.bold;
        if (this.record.backgroundColor != undefined)
            this.backgroundColor = this.record.backgroundColor;
        this.modalOpen = true;
    }

    public ModalOk() {
        this.userFieldForm.fields.forEach((field: any) => {
            let index = this.record.userFields.findIndex((recordField: any) => recordField.id === field.id);
            if (index != -1) {
                if (field.value) {
                    this.record.userFields[index].value = field.value;
                } else {
                    this.record.userFields.splice(index, 1);
                }
            } else if (field.value) {
                this.record.userFields.push({
                    id: field.id,
                    value: field.value,
                    name: field.name
                });
            }
        });
        if (this.backgroundColor == "#000000")
            this.record.backgroundColor = undefined;
        else
            this.record.backgroundColor = this.backgroundColor;
        this.record.bold = this.topic;

        this.modalOpen = false;
        this.$emit("valueChanged");
    }

    public ModalCancel() {
        this.modalOpen = false;
    }
}
