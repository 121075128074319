
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { adminApi } from "@/services/ApiService";
import * as ST from "@/components/controls/s-table/exports";
import { DisplayMode } from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import TextEdit from "@/components/controls/text-edit.vue";
import ItemPickerV2 from "@/components/controls/itemPickerV2.vue";
import { IdText } from "@/api/api";
import { MdmDevConfiguration } from "@/api";

@Component({ components: { STable } })
export default class DevTablets extends Vue {
  dataSource: MdmDevConfiguration[] = [];
  mdmStores: IdText[] = [];
  tableSettings: ST.Settings<MdmDevConfiguration> = {
    dataSource: this.dataSource,
    pagination: false,
    showNewRow: false,
    loading: true,
    sort: {
      sortProperty: "text",
      enableSort: false,
    },
    filter: {
      filterString: "",
      filterProperties: ["text", "password"],
    },
    recordsUpdated: async (records) => this.postDevTablet(records),
    actions: {},
    columns: [
      {
        title: "Namn",
        edit: TextEdit,
        width: 400,
        editData: (record) => ({
          editable: false,
          dataBinding: {
            propertyName: "name",
          },
          props: {
            defaultValue: record.name,
          },
        }),
      },
      {
        title: "Kopplad butik",
        edit: ItemPickerV2,
        width: 400,
        editData: (record) => ({
          displayMode: DisplayMode.ATag,
          editable: true,
          updateEmitter: "valueChanged",
          dataBinding: {
            propertyName: "store",
            formatDisplayValue: (value: IdText) => {
              if (value) {
                return value.text;
              }
            },
            parseEditValue: (value: number) => {
              return this.mdmStores.find((c) => c.id == value);
            },
          },
          props: {
            dataSource: this.mdmStores,
            defaultOpen: true,
            defaultValue: record.store?.id,
            selectionMode: "single",
            customKey: "id",
            customDisplay: "text",
          },
        }),
      },
    ],
  };

  public async mounted() {
    var getMdmStores = await adminApi.getStores();
    if (getMdmStores.data.stores) {
      getMdmStores.data.stores.sort((a, b) =>
        (a.text ?? "").localeCompare(b.text ?? "")
      );
      getMdmStores.data.stores.forEach((c) => this.mdmStores.push(c));
    }
    var getDevTablets = await adminApi.getDevConfigurations();
    if (getDevTablets.data.configurations)
      this.dataSource.push(...getDevTablets.data.configurations);
    this.tableSettings.loading = false;
  }
  public async postDevTablet(records: MdmDevConfiguration[]) {
    var config = records[0];
    await adminApi.postDevConfiguration({
      configurationId: config.id,
      storeId: config.store?.id ?? -1,
      text: config.store?.text ?? "error",
    });
  }
}
