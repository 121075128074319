
import Vue from "vue";
import { Component } from "vue-property-decorator";
import product, { pcategory } from "@/entities/product";
import EditableCellTags from "@/components/store/product/EditableCellTags.vue";
import EditableCellCategory from "@/components/store/product/EditableCellCategory.vue";
import EditableCellTitle from "@/components/store/product/EditableCellTitle.vue";
import EditableCellPlu from "@/components/store/product/EditableCellPlu.vue";
import CellOperations from "@/components/store/product/CellOperations.vue";
import newrow from "@/components/store/product/NewRow.vue";
import ImageCell from "@/components/store/product/ImageCell.vue";
import productrecord from "@/components/store/product/ProductRecord";
import ProductRecordComparer from "@/components/store/product/ProductRecordComparer";
import ProductRecordFiltrer from "@/components/store/product/ProductRecordFilterer";
import ProductRecord from "@/components/store/product/ProductRecord";
import { storeApi } from "@/services/ApiService";
import { Product, CategoryTypeEnum } from "@/api/api";

@Component({
  components: {
    ImageCell,
    EditableCellCategory,
    EditableCellTitle,
    EditableCellPlu,
    EditableCellTags,
    newrow,
    CellOperations,
  },
})
export default class Products extends Vue {
  $refs!: {
    imagecell: ImageCell;
  };

  loading: boolean = true;
  data: productrecord[] = [];

  categories: any[] = [];
  categoriesfilter: kategoriFilter[] = [];
  filter: any;
  filterText!: string;
  filterUpdated: number = 0;
  pager: any = { pageSize: 50 };
  sorter: any;
  newRow: productrecord = new productrecord(new product(), true);

  columns = [
    {
      title: "Bild",
      scopedSlots: { customRender: "imageScope" },
      width: 64,
      customRender: (record: productrecord) => {
        if (record.NewRowRecord && !record.RowEdit) {
          return {
            attrs: {
              colSpan: 0,
            },
          };
        } else {
          return {
            children: this.$createElement(ImageCell, {
              ref: "imagecell",
              props: {
                record: record,
              },
              on: {
                update: (imageId: number) => this.updateImage(record, imageId),
              },
            }),
            attrs: {
              style: "padding: 8px;",
            },
          };
        }
      },
    },
    {
      title: "Vara",
      sorter: true,
      defaultSortOrder: "ascend",
      customRender: (record: productrecord) => {
        if (record.NewRowRecord && !record.RowEdit) {
          return {
            children: this.$createElement(newrow, {
              on: {
                click: (e: any) => {
                  this.InitNewRow(record);
                },
              },
            }),
            attrs: {
              colSpan: 6,
              style: "padding: 0px;",
            },
          };
        } else {
          return {
            children: this.$createElement(EditableCellTitle, {
              props: {
                record: record,
              },
              on: {
                update: (title: string) => this.updateTitle(record, title),
              },
            }),
          };
        }
      },
    },
    {
      title: "PLU",
      customRender: (record: productrecord) => {
        if (record.NewRowRecord && !record.RowEdit) {
          return {
            attrs: {
              colSpan: 0,
            },
          };
        } else {
          return {
            children: this.$createElement(EditableCellPlu, {
              props: {
                record: record,
              },
              on: {
                update: (plucode: string) => this.updatePlu(record, plucode),
              },
            }),
          };
        }
      },
    },
    {
      title: "Strekkod",
      customRender: (record: productrecord) => {
        if (record.NewRowRecord && !record.RowEdit) {
          return {
            attrs: {
              colSpan: 0,
            },
          };
        } else {
          return {
            children: this.$createElement(EditableCellPlu, {
              props: {
                record: record,
              },
              on: {
                update: (plucode: string) => this.updatePlu(record, plucode),
              },
            }),
          };
        }
      },
    },
    {
      title: "Kategorier",
      filters: this.categoriesfilter,
      dataIndex: "categories", //behövs för filtret läggs på vad som är här
      customRender: (categories: pcategory[], record: productrecord) => {
        if (record.NewRowRecord && !record.RowEdit) {
          return {
            attrs: {
              colSpan: 0,
            },
          };
        } else {
          return {
            children: this.$createElement(EditableCellCategory, {
              props: {
                record: record,
                categories: this.getCategories(),
              },
              on: {
                update: (e: any) => this.updateCategory(record, e),
              },
            }),
          };
        }
      },
    },
    {
      title: "Egenskaper",
      customRender: (record: any) => {
        if (record.NewRowRecord && !record.RowEdit) {
          return {
            attrs: {
              colSpan: 0,
            },
          };
        } else {
          return {
            children: this.$createElement(EditableCellTags, {
              props: {
                record: record,
              },
              on: {
                update: (e: any) => this.updateTags(record, e),
              },
            }),
          };
        }
      },
    },
    {
      width: "80px",
      customRender: (record: any) => {
        if (record.NewRowRecord && !record.RowEdit) {
          return {
            attrs: {
              colSpan: 0,
            },
          };
        } else {
          return {
            children: this.$createElement(CellOperations, {
              props: {
                record: record,
              },
              on: {
                accept: () => this.saveNewRow(record),
                cancel: () => this.cancelNewRow(record),
                delete: () => this.deleteProduct(record),
              },
            }),
          };
        }
      },
    },
  ];

  public async mounted() {
    let kategorierResult = await storeApi.getCategories();
    kategorierResult.data.forEach((c) => {
      if (c.type === CategoryTypeEnum.NUMBER_0 && c.name) {
        this.categoriesfilter.push(new kategoriFilter(c.name, c.id.toString()));
      }
    });
    this.categories = kategorierResult.data;

    let result = await storeApi.getProducts();
    let data: productrecord[] = new Array();
    result.data.forEach((c) => data.push(new productrecord(c, false)));
    this.data = data;
    this.loading = false;
    this.pager = { pageSize: 20, current: 1, total: this.data.length };
  }

  public async saveNewRow(record: productrecord) {
    let image = this.$refs.imagecell.GetImageBytes();

    let saveTask = storeApi.postProduct(record.Product);

    record.RowEdit = false;
    record.NewRowRecord = false;

    record.NewlyAdded = true;
    record.Product.id = 99999;

    this.data.push(record);
    this.newRow = new productrecord(new product(), true);

    let productResult = await saveTask;

    if (image) {
      let a = record;
      let imageResult = await storeApi.processImage(
        productResult.data.id,
        image
      );
      record.Product.imageid = imageResult.data.imageid;
    }

    record.Product.id = productResult.data.id;

    if (productResult.data.title) {
      this.notifySave(productResult.data.title);
    }
  }

  public cancelNewRow(record: productrecord) {
    this.newRow = new productrecord(new product(), true);
    this.filterUpdated++;
  }
  public InitNewRow(record: productrecord) {
    record.RowEdit = true;
    this.filterUpdated++;
  }

  public GetData() {
    this.filterUpdated;

    let skip = this.pager.pageSize * (this.pager.current - 1);

    let filtered: productrecord[];
    let categories: number[] = new Array();
    if (this.filter) {
      categories = this.filter.categories;
    }

    let filterer = new ProductRecordFiltrer(this.filterText, categories);
    filtered = [...this.data].filter((c) => filterer.Filter(c));

    let ascending = true;
    if (this.sorter && this.sorter.order == "descend") {
      ascending = false;
    }
    let comprarer = new ProductRecordComparer(ascending);
    filtered.sort((a: productrecord, b: productrecord) =>
      comprarer.Compare(a, b)
    );

    this.pager.total = filtered.length;
    var result = filtered.slice(skip, skip + this.pager.pageSize);

    result.splice(0, 0, this.newRow);
    if (result.length > this.pager.pageSize) {
      result.pop();
    }
    return result;
  }

  public getCategories() {
    return this.categories;
  }
  public handleAdd() {
    this.newRow.RowEdit = true;
    this.filterUpdated++;
  }
  public updateTags(record: productrecord, tags: string[]) {
    record.Product.inactive = tags.includes("Inaktiv");
    record.Product.eco = tags.includes("Ekologisk");
    if (record.Product.id > 0) {
      this.SaveProduct(record.Product);
    }
  }
  public updatePlu(record: productrecord, plu: string) {
    record.Product.plu = plu;
    if (record.Product.id > 0) {
      this.SaveProduct(record.Product);
    }
  }
  public updateTitle(record: productrecord, title: string) {
    record.Product.title = title;
    if (record.Product.id > 0) {
      this.SaveProduct(record.Product);
    }
  }
  public updateCategory(record: productrecord, categories: pcategory[]) {
    record.Product.categories = categories;
    if (record.Product.id > 0) {
      this.SaveProduct(record.Product);
    }
  }
  public updateImage(record: productrecord, imageId: number) {
    record.Product.imageid = imageId;
    if (record.Product.id > 0 && record.Product.title) {
      this.notifySave(record.Product.title);
    }
  }
  private async SaveProduct(product: Product) {
    let result = await storeApi.postProduct(product);
    if (result.data.title) {
      this.notifySave(result.data.title);
    }
  }

  public onSearchChanged(e: any) {
    if (!e.data && e.srcElement._value.length == 1) {
      this.filterText = e.data;
      this.filterUpdated++;
    }
  }
  public onSearch(e: any) {
    this.filterText = e;
    this.filterUpdated++;
  }

  public onChange(pagination: any, filter: any, sorter: any) {
    this.sorter = sorter;
    this.pager = pagination;
    this.filter = filter;
    this.filterUpdated++;
  }

  public GetKategoriText(product: product) {
    let result = "";
    product.categories.forEach((c) => (result += ", " + c.text));
    if (result.length > 0) {
      result = result.slice(1, result.length);
    }
    return result;
  }

  public notifySave(name: string) {
    this.$notification.open({
      message: "Sparade vara",
      description: name,
      placement: "bottomRight",
      duration: 4,
    });
  }

  public async deleteProduct(record: productrecord) {
    let index = this.data.findIndex((c) => c.Product.id == record.Product.id);
    this.data.splice(index, 1);
    this.filterUpdated++;
    let result = await storeApi.deleteProduct(record.Product.id);
    if (record.Product.title) {
      this.$notification.open({
        message: "Tog tog bort vara",
        description: record.Product.title,
        placement: "bottomRight",
        duration: 4,
      });
    }
  }
}

class kategoriFilter {
  text: string;
  value: string;
  constructor(text: string, value: string) {
    this.text = text;
    this.value = value;
  }
}
