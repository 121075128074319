import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login.vue";

import Admin from "@/views/Admin.vue";
import AdminStores from "@/views/admin/stores.vue"
import AdminEditStore from "@/views/admin/editStore.vue"
import AdminDepartments from "@/views/admin/departments.vue";
import AdminTablets from "@/views/admin/tablets.vue";
import Test from "@/views/admin/test.vue";
import Contacts from "@/views/admin/contacts.vue";
import DevTablets from "@/views/admin/devTablets.vue";
import DevTasks from "@/views/admin/devTasks.vue";

import Store from "@/views/Store.vue";
import StoreDepartmentCategories from "@/views/store/department-categories.vue";
import StoreChecklist from "@/views/store/tasklist.vue";

import Settings from "@/views/store/settings.vue";
import Products from "@/views/store/products.vue";
import ProductsV2 from "@/views/store/productsV2.vue";
import CategoriesV2 from "@/views/store/categoriesV2.vue";
import Videos from "@/views/store/videos.vue";
import EditCategory from "@/views/store/edit-category.vue";
import DevBlog from "@/views/store/dev-blog.vue";
import UserFields from "@/views/store/user-fields.vue";

//Restaurant
import RCategories from "@/views/restaurant/rcategories.vue";
import EditRestaurantCategory from "@/views/restaurant/edit-restaurant-category.vue";
import RProducts from "@/views/restaurant/rproducts.vue";
import RStations from "@/views/restaurant/rstations.vue";
import RProductionStations from "@/views/restaurant/rproduction-stations.vue";
import EditRestaurantProduct from "@/views/restaurant/edit-restaurant-product.vue"
import EditProductionStation from "@/views/restaurant/edit-production-station.vue";

import { authService } from "../services/AuthService";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/admin",
    name: "admin",
    redirect: { name: "adminStores" },
    component: Admin,
    meta: {
      requiresAdmin: true,
    },
    children: [
      {
        path: "dev-tablets",
        name: "adminDevTablets",
        component: DevTablets
      },
      {
        path: "dev-tasks",
        name: "adminDevTasks",
        component: DevTasks
      },
      {
        path: "stores",
        name: "adminStores",
        component: AdminStores
      },
      {
        path: "stores/:id",
        component: AdminEditStore,
        children: [
          {
            path: "contacts",
            name: "adminStoreContacts",
            component: Contacts
          },
          {
            path: "departments",
            name: "adminStoreDepartments",
            component: AdminDepartments
          },
          {
            path: "tablets",
            name: "adminStoreTablets",
            component: AdminTablets
          }
        ]
      },
      {
        path: "test",
        name: "test",
        component: Test
      }
    ]
  },
  {
    path: "/store",
    name: "store",
    redirect: { name: "storeCategories" },
    component: Store,
    meta: {
      requiresButik: true,
    },
    children: [
      {
        path: "categories",
        name: "storeCategories",
        component: CategoriesV2
      },
      {
        path: "devblog",
        name: "storeDevBlog",
        component: DevBlog
      },
      {
        path: "userfields",
        name: "storeUserFields",
        component: UserFields
      },
      {
        path: "departmentcategory/:id",
        name: "storeDepartmentCategory",
        component: StoreDepartmentCategories
      },
      {
        path: "tasklist/:id",
        name: "StoreChecklist",
        component: StoreChecklist

      },
      {
        path: "products",
        name: "storeProducts",
        component: Products
      },
      {
        path: "productsV2",
        name: "storeProductsV2",
        component: ProductsV2
      },
      {
        path: "categories/:id",
        name: "storeEditCategory",
        component: EditCategory
      },
      {
        path: "settings",
        name: "storeSettings",
        component: Settings
      },
      {
        path: "videos",
        name: "storeVideos",
        component: Videos
      },
    ],
  },
  {
    path: "/restaurant",
    name: "restaurant",
    redirect: { name: "restaurantCategories" },
    component: Store,
    meta: {
      requiresButik: true,
    },
    children: [
      {
        path: "categories",
        name: "restaurantCategories",
        component: RCategories
      },
      {
        path:"categories/:id",
        name: "restaurantEditCategory",
        component: EditRestaurantCategory
      },
      {
        path: "products",
        name: "restaurantProducts",
        component: RProducts
      },
      {
        path: "products/:id",
        name: "restaurantEditProduct",
        component: EditRestaurantProduct,
      },
      // {
      //   path: "productGroups",
      //   name: "restaurantProductGroups",
      //   component: RProductGroups
      // }, 
      {
        path: "stations",
        name: "restaurantStations",
        component: RStations
      },
      {
        path: "productionstations",
        name: "restaurantProductionStations",
        component: RProductionStations
      },
      {
        path: "productionstations/:id",
        name: "restaurantEditProductionStation",
        component: EditProductionStation
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (!authService.admin) {
      next({ name: "login" });
      return;
    }
  } else if (to.matched.some((record) => record.meta.requiresButik)) {
    if (!authService.store) {
      next({ name: "login" });
      return;
    }
  }
  next();
});

export default router;
