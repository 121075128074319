
import Vue from "vue";
import { Component } from "vue-property-decorator";
import * as ST from "@/components/controls/s-table/exports";
import STable from "@/components/controls/s-table/sTable.vue";
import TextEdit from "@/components/controls/text-edit.vue";
import { IsNewRow } from "@/components/controls/s-table/exports";
import { restaurantApi } from "@/services/RApiService";
import { Station } from "@/api/restaurant";

@Component({
  components: {
    STable,
  },
})
export default class RStations extends Vue {
  dataSource: Station[] = [];

  tableSettings: ST.Settings<Station> = {
    dataSource: this.dataSource,
    pagination: false,
    showNewRow: true,
    newRowText: "Ny station",
    newRowInit: () => ({}),
    recordsUpdated: async (records) => this.postRecord(records),
    loading: false,
    actions: {
      remove: {
        icon: "delete",
        confirmation: (record) =>
          `Är du säker på att du vill ta bort ${record.text}`,
        action: (record) => {
          this.deleteRecord(record);
        },
      },
    },
    columns: [
      {
        title: "Station",
        width: 400,
        edit: TextEdit,
        editData: (record) => ({
          editable: true,
          editValue: record.text,
          dataBinding: {
            propertyName: "text",
          },
          updateEmitter: "valueChanged",
          props: {
            defaultOpen: !IsNewRow(record),
            defaultValue: record.text,
            placeHolder: "Namn",
          },
        }),
      },
    ],
  };

  async mounted() {
    var result = await restaurantApi.getStations();
    this.dataSource.push(...result.data);
  }
  async deleteRecord(record: Station) {
    console.log(record);
    await restaurantApi.deleteStation(record.id);
    var recordIndex = this.dataSource.indexOf(record, 0);
    if (recordIndex > -1) this.dataSource.splice(recordIndex, 1);
    this.notify("Stationen har blivit borttagen", record.text ?? "");
  }
  async postRecord(records: Station[]) {
    for (const record of records) {
      var res = await restaurantApi.postStation(record);
      record.id = res.data.id;
    }
    this.notify("Ändringarna har blivit sparade", "Sparat");
  }
  public notify(description: string, message: string) {
    this.$notification.open({
      message: message,
      description: description,
      placement: "bottomRight",
      duration: 4,
    });
  }
}
