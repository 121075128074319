
import Vue from "vue";
import { Component, Prop, Ref } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ShowQRDialog extends Vue {
  @Ref("input") readonly input!: HTMLInputElement;
  @Prop()
  visible: boolean = false;
  @Prop()
  qr: string = "";
  @Prop()
  name: string = "";
  acceptedValue?: string;
  confirmLoading = false;
  loading = true;

  public async mounted() {}
  public ModalClose() {
    console.log(this.qr);
    this.$emit("cancel");
  }
}
