import { Product } from "@/api/api";
export default class ProductRecord {
  Product!: Product;
  RowEdit: boolean = false;
  NewRowRecord: boolean = false;
  NewlyAdded: boolean = false;

  constructor(product: Product, newRow: boolean) {
    this.Product = product;
    this.NewRowRecord = newRow;
  }

  get Key() {
    return this.Product.id;
  }

  get categories(): any {
    return this.Product.categories;
  }
}
